import { MaterialIcon } from '@dsny/dsny-component-library';
import React from 'react';
import { format } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { convertDateToUTC } from 'src/utils/Formatter';
import { IconWrapper, PeriodWrapper } from './TrackingCard.styles';

const TrackingCardDateRange: React.FC = () => {
  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  return (
    // Single date range -  Within 90 day fingerprint period
    <PeriodWrapper>
      <IconWrapper>
        <MaterialIcon name="Calendar" />
      </IconWrapper>
      {selectedSong &&
        `${format(
          convertDateToUTC(
            new Date(selectedSong?.tracking_periods[0].start_tracking_on)
          ),
          'MMM dd, yyyy'
        )} - ${format(
          convertDateToUTC(
            new Date(selectedSong?.tracking_periods[0].end_tracking_on)
          ),
          'MMM dd, yyyy'
        )}`}
    </PeriodWrapper>
  );
};

export default TrackingCardDateRange;
