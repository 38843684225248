/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import {
  signIn,
  forgotPassword,
  resetPassword,
  checkExistentUser,
  validateCode,
  sendSignupEmail,
  registerUser,
  signInFromToken,
} from 'src/features/Auth/Auth.thunks';

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

const AuthSlice = createSlice({
  name: 'Auth',
  initialState: {
    forgotPasswordResponse: '',
    resetPasswordResponse: '',
    isLoginSucessful: true,
    isUserEligibleForMtr: true,
    isFetching: false,
    signUp: {
      stepOne: {
        email: '',
        password: '',
        isValidUser: false,
        hasResponseError: 0,
      },
      stepTwo: { code: '', isCodeValid: false, hasResponseError: 0 },
      stepThree: { isUserRegistered: false, hasResponseError: '' },
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(signIn.pending, (state) => {
        state.isFetching = true;
        state.isLoginSucessful = true;
        state.isUserEligibleForMtr = true;
      })
      .addCase(signIn.fulfilled, (state) => {
        state.isFetching = false;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isFetching = false;
        const statusCode = action.payload;

        if (statusCode === UNAUTHORIZED) {
          state.isLoginSucessful = false;
        }
        if (statusCode === FORBIDDEN) {
          state.isUserEligibleForMtr = false;
        }
      })
      .addCase(signInFromToken.pending, (state) => {
        state.isFetching = true;
        state.isLoginSucessful = true;
        state.isUserEligibleForMtr = true;
      })
      .addCase(signInFromToken.fulfilled, (state) => {
        state.isFetching = false;
      })
      .addCase(signInFromToken.rejected, (state, action) => {
        state.isFetching = false;
        const statusCode = action.payload;
        if (statusCode === UNAUTHORIZED) {
          state.isLoginSucessful = false;
        }
        if (statusCode === FORBIDDEN) {
          state.isUserEligibleForMtr = false;
        }
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.forgotPasswordResponse = 'SUCCESS';
        state.isFetching = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.forgotPasswordResponse = 'ERROR';
        state.isFetching = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPasswordResponse = 'SUCCESS';
        state.isFetching = false;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPasswordResponse = 'ERROR';
        state.isFetching = false;
      })
      .addCase(checkExistentUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(checkExistentUser.fulfilled, (state, { payload }) => {
        if (!payload.code) {
          state.signUp.stepOne.isValidUser = true;
        }
        state.signUp.stepOne.hasResponseError = payload.code;
        state.isFetching = false;
      })
      .addCase(checkExistentUser.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(sendSignupEmail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(sendSignupEmail.fulfilled, (state, { payload }) => {
        const { email, password } = payload;
        state.signUp.stepOne = {
          email,
          password,
          isValidUser: false,
          hasResponseError: 0,
        };
        state.signUp.stepTwo.hasResponseError = 0;
        state.signUp.stepThree.hasResponseError = '';
        state.isFetching = false;
      })
      .addCase(sendSignupEmail.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(validateCode.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(validateCode.fulfilled, (state, { payload }) => {
        state.signUp.stepTwo = payload;
        state.isFetching = false;
      })
      .addCase(validateCode.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(registerUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.signUp.stepThree = {
          isUserRegistered: payload.isUserRegistered,
          hasResponseError: payload.code,
        };
        state.isFetching = false;
      })
      .addCase(registerUser.rejected, (state) => {
        state.isFetching = false;
      }),
  reducers: {
    resetForgotPasswordResponse: (state) => {
      state.forgotPasswordResponse = '';
    },
    clearSignupResponse: (state) => {
      state.signUp = {
        stepOne: {
          email: '',
          password: '',
          isValidUser: false,
          hasResponseError: 0,
        },
        stepTwo: { code: '', isCodeValid: false, hasResponseError: 0 },
        stepThree: { isUserRegistered: false, hasResponseError: '' },
      };
    },
    clearSignupHandle: (state) => {
      state.signUp.stepTwo.isCodeValid = false;
    },
  },
});

export const {
  resetForgotPasswordResponse,
  clearSignupResponse,
  clearSignupHandle,
} = AuthSlice.actions;

export default AuthSlice.reducer;
