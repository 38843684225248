/* eslint-disable no-underscore-dangle */
import { getUnixTime } from 'date-fns';

/*
      Decodes JWT token and extracts user info
      @return {User}
  */
const __base64_decode = (s: string) => {
  let _result = '';
  if (s) {
    let _s = s.replace(/-/g, '+').replace(/_/g, '/');
    switch (_s.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        _s += '==';
        break;
      }
      case 3: {
        _s += '=';
        break;
      }
      default: {
        _s = '';
      }
    }
    if (_s !== '') _result = decodeURIComponent(escape(window.atob(_s)));
  }
  return _result;
};

/*
    @return {jsonPayload} - returns json payload
*/
export const parseUserFromToken = (token: string) => {
  if (!token) {
    return null;
  }
  let jsonPayload = null;
  const segments = token.split('.');

  if (segments && segments.length === 3) {
    jsonPayload = JSON.parse(__base64_decode(segments[1])!);
  }

  return jsonPayload;
};

/*
    @return {token} - returns release id
*/
export const getReleaseIdFromToken = (token: string) => {
  return parseUserFromToken(token)?.data?.release_id;
};

export const isExpired = (exp: number) => {
  let _result = false;
  if (exp) {
    _result = getUnixTime(new Date()) > exp;
  }
  return _result;
};
