/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { device, mtrStyle } from 'src/styles/global';
import theme from 'src/styles/theme';
import { Button } from '@dsny/dsny-component-library';

export interface Props {
  $isInvalid?: boolean;
  $focus?: boolean;
  $inlineMessage?: boolean;
}

export const CheckoutBox = styled.div`
  display: flex;
  flex-direction: column;
  position: inherit;
  padding: 64px 64px;
  border-radius: ${mtrStyle.borderRadius};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: ${theme.colors.white0};

  @media ${device.mobile} {
    padding: 48px 24px;
  }
`;

export const Title = styled.h2`
  padding-bottom: 8px;
  color: ${theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
  }
`;

export const TextDescritionWrapper = styled.div`
  padding-bottom: 32px;

  @media ${device.mobile} {
    padding-bottom: 24px;
  }
`;

export const TeamSpaceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${theme.colors.neutralW40};
  padding-bottom: 32px;
  gap: 8px;

  @media ${device.mobile} {
    padding-bottom: 24px;
  }
`;

export const StripeElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
`;

export const CardExpirySection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 0px;
  }
`;

const statesStyles = css<Props>`
  &:hover {
    border: 1px solid
      ${({ $isInvalid }) =>
        $isInvalid ? theme.colors.error60 : theme.colors.mtr60};
  }

  &:focus {
    background-position: left bottom;
    background-size: 100% 1px;
    border: 1px solid
      ${({ $isInvalid }) =>
        $isInvalid ? theme.colors.error60 : theme.colors.mtr60};
  }

  &:focus + label {
    top: 0;
    transform: translateY(-50%) scale(0.9);
    background-color: #fff;
  }

  &:not(:focus) + label {
    top: 0;
    background-color: #fff;
    transform: translateY(-50%) scale(0.9);
  }

  &:focus-visible {
    outline: 2px solid;
  }
`;

const sharedStyles = css<Props>`
  position: relative;
  width: 100%;
  height: 44px;
  outline: none;
  border-radius: ${mtrStyle.borderRadius};
  padding: 13.5px 0.7rem;
  transition: 0.1s ease-out;
  border: 1px solid
    ${({ $isInvalid, $focus }) =>
      $isInvalid
        ? theme.colors.error60
        : $focus
        ? theme.colors.mtr60
        : theme.colors.neutralW70};
`;

export const StripeInputStyle = styled.div`
  ${sharedStyles}
  ${statesStyles}
`;

export const Label = styled.label<Props>`
  position: absolute;
  font-size: 12px;
  left: 0;
  top: 0%;
  z-index: 2;

  transform: translateY(-50%);
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  transition: 0.1s ease-out;
  background-color: white;
  color: ${({ $isInvalid }) =>
    $isInvalid ? theme.colors.error60 : theme.colors.neutralW40};

  &:before {
    content: '*';
    color: ${({ $isInvalid }) =>
      $isInvalid ? theme.colors.error60 : theme.colors.neutralW40};
  }
`;

export const InlineMessage = styled.p<Props>`
  color: ${theme.colors.error60};
  font-size: 12px;
  max-height: ${({ $inlineMessage }) => ($inlineMessage ? '20px' : '0')};
  transition: ${({ $inlineMessage }) =>
    $inlineMessage ? 'max-height 0.25s ease-in' : 'max-height 0.15s ease-out'};
  overflow: hidden;
  margin-top: 8px;
`;

export const CardDetailsWrapper = styled.div`
  padding-bottom: 32px;
`;

export const FinishReviewButton = styled(Button)`
  width: 136px;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const OneTimePaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 32px;
  font-size: 14px;
  color: ${theme.colors.neutralW40};

  p {
    font-size: 12px;
    font-weight: 500;
    padding-top: 16px;
    color: ${theme.colors.neutralW20};
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
