import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from '../i18n/en.json';
import esTranslation from '../i18n/es.json';
import frTranslation from '../i18n/fr.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'translations',
    backend: {
      loadPath: 'src/i18n/{{lng}}.json',
    },
    load: 'languageOnly',
    react: {
      useSuspense: false,
    },
  });
i18n.addResourceBundle('en', 'translations', enTranslation);
i18n.addResourceBundle('es', 'translations', esTranslation);
i18n.addResourceBundle('fr', 'translations', frTranslation);

export default i18n;

export const languages = [
  { description: 'English', code: 'en' },
  { description: 'Français', code: 'fr' },
  { description: 'Español', code: 'es' },
  // { description: '日本語', code: 'jp' },
  // { description: 'Deutsch', code: 'de' },
];
