import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { initializeEventWrapper, pageView } from '../../utils/segmentService';
import { getSessionUser } from '../../features/Auth';

const PageView: React.FC = () => {
  const location = useLocation();
  const [previousPage, setPreviousPage] = useState('initial');
  const [eventWrapperInitialized, setEventWrapperInitialized] = useState(false);

  const initEventWrapper = () => {
    const user = getSessionUser();
    initializeEventWrapper(user.company_id);
    setEventWrapperInitialized(true);
  };

  useEffect(() => {
    const pageTitle = document.title; // Get the page title
    if (previousPage !== location.pathname) {
      setPreviousPage(location.pathname);
      if (!eventWrapperInitialized) {
        initEventWrapper();
      }
      pageView(pageTitle, { path: location.pathname });
    }
  }, [location, previousPage, setPreviousPage]);

  useEffect(() => {
    initEventWrapper();
  }, []);

  return <></>;
};

export default PageView;
