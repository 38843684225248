/* eslint-disable prettier/prettier */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/services/network';
import { downloadCsvFile } from 'src/utils/DownloadFile';
import { SearchParams } from './RadioStationModal.slice';


interface FetchParams {
  searchParams: SearchParams;
  pageSize: number;
}


export const getCountries = createAsyncThunk('GET_COUNTRIES', async () => {
  return axios.get('/radio/coordinator/station-countries').then((response) => {
    return response.data;
  });
});

export const getGenres = createAsyncThunk('GET_GENRES', async () => {
  return axios.get('/radio/coordinator/station-genres').then((response) => {
    return response.data;
  });
});

export const getStationList = createAsyncThunk('GET_STATION_LIST', async (searchParams: SearchParams) => {
  return axios.get(`/radio/coordinator/stations?search=${searchParams.search}&country=${searchParams.country}&genres=${searchParams.genre}&page=${searchParams.page}&page_size=${searchParams.page_size}&sort=${searchParams.sort}`)
    .then((response) => {
      return response.data;
    });
});

export const getStationListFile = createAsyncThunk('GET_STATION_LIST_FILE', async ({searchParams, pageSize}: FetchParams) => {
  return axios.get(`/radio/coordinator/stations?search=${searchParams.search}&country=${searchParams.country}&genres=${searchParams.genre}&page=${searchParams.page}&page_size=${pageSize}&sort=${searchParams.sort}&format=csv`)
    .then((response) => {
      downloadCsvFile(response);
    });
});