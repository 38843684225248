import styled from 'styled-components';
import { device } from 'src/styles/global';
import { TextField } from '@dsny/dsny-component-library';

export const TextFieldWrapper = styled(TextField)`
  font-size: 14px;

  @media ${device.mobile} {
    width: 100%;
  }
`;
