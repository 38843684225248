import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import SpinTracking from '../SpinTracking/SpinTracking';

interface CitiesTrackingProps {
  hasComparisonOn: boolean;
}

const CitiesTracking: React.FC<CitiesTrackingProps> = ({ hasComparisonOn }) => {
  const { t } = useTranslation();

  const { songCities } = useSelector((state: RootState) => state.dashboard);

  return (
    <SpinTracking
      hasComparisonOn={hasComparisonOn}
      spinData={songCities.songCitiesData}
      spinDataCompare={songCities.songCitiesDataCompare}
      title={t('DASHBOARD_CITIES_TRACKING_TITLE')}
      description={t('DASHBOARD_CITIES_TRACKING_DESC')}
    />
  );
};

export default CitiesTracking;
