import React from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/app/store';
import { useSelector } from 'react-redux';

// Isolated component to access state without causing the parent component to refresh
const TotalStations: React.FC = () => {
  const { t } = useTranslation();
  const totalStations = useSelector(
    (state: RootState) =>
      state.radioStationModal.radioStationList.radioStationTable?.total_stations
  );

  return (
    <>
      {/* Total stations */}
      {t('STATIONS_TOTAL')}&nbsp;{totalStations || 0}
    </>
  );
};

export default TotalStations;
