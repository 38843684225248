/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxPopover,
} from '@dsny/dsny-component-library';
import React, { useState } from 'react';
import theme from 'src/styles/theme';
import { useTranslation } from 'react-i18next';
import { Province } from 'src/utils/ProvincesData';
import {
  ComboboxOptionWrapper,
  ComboboxStyles,
  ComboboxWrapper,
  InlineMessage,
  ItemWrapper,
} from './SignupSelection.styles';

interface Props {
  selectProvince: (item: Province) => void;
  provinces: Province[];
  ref?: null;
  error: boolean;
}

const SignupSelectionProvince: React.FC<Props> = ({
  provinces,
  error,
  selectProvince,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({ id: 0, description: '' } as any);

  const openCombobox = () => {
    setOpen(true);
  };

  const closeCombobox = () => {
    setOpen(false);
  };

  // Handling province / state selection
  const onChange = (option: Province) => {
    setValue({ ...option });
    selectProvince({ ...option });
  };

  return (
    <>
      {/* Signup selection - Province / State */}
      <ComboboxWrapper>
        <Combobox
          isOpen={open}
          onClick={openCombobox}
          onClose={closeCombobox}
          onChange={onChange}
          label={t('SIGNUP_PROVINCE_LABEL')}
          style={{ ...ComboboxStyles }}
          value={value as Province}
        >
          <ComboboxInput
            required={error}
            placeholder={t('SIGNUP_SELECT_PLACEHOLDER')}
            highlightcolor={theme.colors.mtr60}
            style={{
              borderColor: error && theme.colors.error50,
              color: theme.colors.neutralW40,
              position: 'inherit',
              height: '44px',
            }}
          />

          <ComboboxPopover
            style={{
              width: '100%',
              height: '216px',
              borderRadius: '5px',
              marginTop: '8px',
              borderColor: theme.colors.white,
              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
            }}
          >
            <ComboboxList style={{ paddingTop: '16px', paddingBottom: '16px' }}>
              {provinces?.map((p: Province) => (
                <ComboboxOptionWrapper
                  key={p.id}
                  selectedItemIndex={value.index}
                  style={{ paddingLeft: '16px' }}
                  value={p}
                >
                  <ItemWrapper>{p.description}</ItemWrapper>
                </ComboboxOptionWrapper>
              ))}
            </ComboboxList>
          </ComboboxPopover>

          {/* If not selected apply inline message  */}
          {error && (
            <InlineMessage $inlineMessage>
              {t('SIGNUP_PROVINCE_ERROR_MESSAGE')}
            </InlineMessage>
          )}
        </Combobox>
      </ComboboxWrapper>
    </>
  );
};

export default SignupSelectionProvince;
