import styled, { css } from 'styled-components';
import { Button } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { device, mtrStyle } from 'src/styles/global';

interface Props {
  $isMostPopularPackage?: boolean;
}

export const HeadingWrapper = styled.div`
  display: inline-block;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  color: ${theme.colors.neutralW20};
  padding: 48px 64px 8px 64px;

  @media ${device.mobile} {
    font-size: 24px;
    line-height: 32px;
    padding: 24px 24px 8px 24px;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 64px;

  @media ${device.mobile} {
    padding: 0px 24px;
  }
`;

export const Description = styled.div`
  padding-bottom: 32px;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    font-size: 14px;
    padding-bottom: 24px;
  }
`;

export const PackageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
  }
`;

export const PackageContainer = styled.div`
  position: relative;
  width: 250px;
  max-height: 230px;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const PackageBox = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: ${({ $isMostPopularPackage }) =>
    $isMostPopularPackage
      ? `2px solid${theme.colors.mtr60}`
      : `1px solid${theme.colors.neutralW80}`};
  border-radius: ${mtrStyle.borderRadius};
  align-items: center;
`;

export const MostPopularTag = styled.div`
  position: absolute;
  top: -5%;
  display: flex;
  align-self: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  padding: 1px 4px;
  color: ${theme.colors.white0};
  border-radius: ${mtrStyle.borderRadius};
  border: 1px solid ${theme.colors.mtr60};
  background-color: ${theme.colors.mtr60};
`;

export const PlanWrapper = styled.div`
  display: flex;
  align-self: center;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-self: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-top: 8px;
  color: ${theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
  }
`;

export const PerSongWrapper = styled.div`
  display: flex;
  align-self: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const DiscountWrapper = styled.div`
  display: flex;
  align-self: center;
  font-size: 14px;
  margin-top: 8px;
  gap: 8px;
`;

const sharedStylesMarkdownPrice = css`
  display: flex;
  align-self: flex-end;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW60};

  @media ${device.mobile} {
    font-size: 12px;
  }
`;

export const MarkdownPrice = styled.div`
  ${sharedStylesMarkdownPrice}
`;

export const PromoMarkdownPrice = styled.div`
  ${sharedStylesMarkdownPrice}
  text-decoration-line: line-through;
`;

export const SaveTag = styled.div`
  display: flex;
  align-self: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  padding: 1px 4px;
  color: ${theme.colors.error50};
  border-radius: ${mtrStyle.borderRadius};
  border: 1px solid ${theme.colors.error90};
  background-color: ${theme.colors.error90};
`;

export const ChargedNote = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.neutralW40};
  padding-bottom: 32px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 64px 48px 64px;
  gap: 16px;

  @media ${device.mobile} {
    padding: 0px 24px 24px 24px;
  }
`;

export const PricingStyleButton = styled(Button)`
  font-size: 14px;
  min-width: 67px;
  height: 36px;
  border-radius: 5px;
`;
