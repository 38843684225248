/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown } from '@dsny/dsny-component-library';
import React, { useEffect, useState } from 'react';
import theme from 'src/styles/theme';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Calendar } from 'src/assets/images/icons/calendar.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { format } from 'date-fns-tz';
import { convertDateToUTC } from 'src/utils/Formatter';
import {
  DesktopWrapper,
  HeaderStylesDesktop,
  HeaderStylesMobile,
  ItemStylesDesktop,
  ItemStylesMobile,
  MobileWrapper,
} from './DropdownDateRange.styles';
import { TrackingPeriod } from '../Dashboard.slice';
import { SHOW_ALL_DATES } from '../Dashboard.thunks';

type DropListItem = {
  description: string;
  code: string;
  index?: number;
};

type Props = {
  setFilter: any;
};

const DropdownDateRange: React.FC<Props> = ({ setFilter }) => {
  const { t } = useTranslation();
  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );
  const [dropdownList, setDropdownList] = useState([
    { description: '', code: '' },
  ]);

  // Formatting dropdown list
  const buildDropdownListData = (trackingPeriods: TrackingPeriod[]) => {
    const list = trackingPeriods.map((period, i) => {
      return {
        description: `${format(
          convertDateToUTC(new Date(period.start_tracking_on)),
          'MMM dd, yyyy'
        )} - ${format(
          convertDateToUTC(new Date(period.end_tracking_on)),
          'MMM dd, yyyy'
        )}`,
        code: period.tracking_period_id.toString(),
        index: i,
      } as DropListItem;
    });

    const tempTrackingPeriods = {
      description: t('SHOW_ALL_DATES'),
      code: SHOW_ALL_DATES,
      index: -1,
    };

    list.push(tempTrackingPeriods);
    return list;
  };

  // Calling buildDropdownListData
  const dropdownListData = buildDropdownListData(
    selectedSong?.tracking_periods || []
  );

  // Setting dropdown list
  const setDropdownListData = () => {
    const list = dropdownListData;
    setDropdownList(list);
  };

  // Setting default selected option
  const [dropOption, setDropOption] = useState(dropdownListData[0].description);

  // Handling dropdown selection
  const changeSelection = (item: DropListItem) => {
    setDropOption(item.description);
    setFilter(item.index);
  };

  useEffect(() => {
    if (selectedSong?.tracking_periods) {
      setDropdownListData();
    }
  }, [selectedSong]);

  return (
    <>
      {/* Dropdown date range list - By default, we will show the latest date range (descending order from BE) */}
      {/* Desktop view */}
      <DesktopWrapper>
        <Dropdown
          width="280"
          headerAlign="left"
          onSelect={changeSelection}
          listItems={dropdownList}
          item={dropOption}
          CustomIcon={Calendar}
          wrapperStyle={{
            backgroundColor: theme.colors.white0,
            marginLeft: '-6px',
          }}
          headerStyle={{ ...HeaderStylesDesktop }}
          itemStyle={{ ...ItemStylesDesktop }}
        />
      </DesktopWrapper>

      {/* Mobile view */}
      <MobileWrapper>
        <Dropdown
          width="280"
          headerAlign="left"
          onSelect={changeSelection}
          listItems={dropdownList}
          item={dropOption}
          CustomIcon={Calendar}
          wrapperStyle={{
            backgroundColor: theme.colors.white0,
            marginLeft: '-6px',
          }}
          headerStyle={{ ...HeaderStylesMobile }}
          itemStyle={{ ...ItemStylesMobile }}
        />
      </MobileWrapper>
    </>
  );
};

export default DropdownDateRange;
