import styled from 'styled-components';
import theme from 'src/styles/theme';
import { device } from 'src/styles/global';

export const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;

  @media ${device.mobile} {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.ipad} {
    display: none;
  }

  @media ${device.laptop} {
    display: none;
  }

  @media ${device.desktop} {
    display: none;
  }
`;

export const sharedStyles = {
  fontSize: '16px',
  color: theme.colors.neutralW40,
  paddingTop: '8px',
  paddingBottom: '8px',
};

export const HeaderStylesDesktop = {
  ...sharedStyles,
};

export const HeaderStylesMobile = {
  ...sharedStyles,
  fontSize: '14px',
};

export const ItemStylesDesktop = {
  fontSize: '16px',
  color: theme.colors.neutralW40,
};

export const ItemStylesMobile = {
  fontSize: '14px',
  color: theme.colors.neutralW40,
};
