/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { logout, refreshTokens, getTokens } from 'src/features/Auth';

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

/*
    Request interceptors
*/
axios.interceptors.request.use((config: any) => {
  const { token } = getTokens();

  if (!token) {
    return logout();
  }

  config.headers['x-AuthToken'] = token;
  return config;
});

/*
    Response interceptors
*/
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status !== 401) {
      return Promise.reject(error);
    }
    // Refresh token and retry failed request
    const { refreshToken } = getTokens();

    return refreshTokens(refreshToken).then((newToken: any) => {
      error.config.headers['x-AuthToken'] = newToken;

      return axios.request(error.config);
    });
  }
);

export const fetcher = (url: any) => axios.get(url).then((res) => res.data);

export default axios;
