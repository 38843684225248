import styled from 'styled-components';
import theme from 'src/styles/theme';

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PillStyles = {
  width: '72px',
  height: '24px',
  fontSize: '12px',
  marginBottom: '0px',
  backgroundColor: theme.colors.neutralW100,
};

export const IconContainerStyles = {
  width: '16px',
  height: '16px',
  alignItems: 'center',
};
