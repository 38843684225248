/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown, MaterialIcon, Text } from '@dsny/dsny-component-library';
import React, { useState } from 'react';
import theme from 'src/styles/theme';
import { useTranslation } from 'react-i18next';
import { IconNames } from '@dsny/dsny-component-library/dist/components/MaterialIcon/MaterialIcon';
import {
  DropdownFilterWrapper,
  FilterByWrapper,
  IconWrapper,
} from './DropdownFilter.styles';

type DropListItem = {
  description: string;
  code: string;
};

type Props = {
  setFilter: any;
};

// Default initial filter
export const filterInitialCode = 7; // last 7 days

const DropdownFilter: React.FC<Props> = ({ setFilter }) => {
  const { t } = useTranslation();

  // Filter description used by all dashboard widgets
  const dropdownList = [
    { description: t('DASHBOARD_FILTER_ALL_TIME'), code: '0' },
    { description: t('DASHBOARD_FILTER_RECENT'), code: '1' },
    { description: t('DASHBOARD_FILTER_LAST_SEVEN_DAYS'), code: '7' },
    { description: t('DASHBOARD_FILTER_LAST_THIRTY_DAYS'), code: '30' },
  ];

  const dropdownItem = dropdownList.find(
    (item: DropListItem) => item.code === '7'
  );
  const [dropOption, setDropOption] = useState(
    dropdownItem?.description
      ? dropdownItem.description
      : t('DASHBOARD_FILTER_LAST_SEVEN_DAYS')
  );

  const changeSelection = (item: DropListItem) => {
    setDropOption(item.description);
    setFilter(item.code);
  };
  return (
    <>
      {/* Dropdown Filter */}
      <DropdownFilterWrapper>
        <FilterByWrapper>
          <IconWrapper>
            <MaterialIcon name={'Filter' as keyof typeof IconNames} />
          </IconWrapper>
          <Text
            fontSize="14px"
            fontWeight={400}
            color={theme.colors.neutralW40}
          >
            {t('DASHBOARD_FILTER_BY')}
          </Text>
        </FilterByWrapper>

        <Dropdown
          width="115"
          headerAlign="center"
          onSelect={changeSelection}
          listItems={dropdownList}
          item={dropOption}
          wrapperStyle={{
            backgroundColor: theme.colors.neutralW100,
            borderRadius: '5px',
            height: '24px',
            alignSelf: 'center',
          }}
          headerStyle={{
            fontSize: '12px',
            paddingBottom: '4px',
            color: theme.colors.neutralW20,
          }}
          itemStyle={{ fontSize: '12px' }}
        />
      </DropdownFilterWrapper>
    </>
  );
};

export default DropdownFilter;
