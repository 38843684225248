import styled from 'styled-components';
import { device } from 'src/styles/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.background.grey};
`;

export const FooterWraper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px 64px 16px 64px;
  gap: 40px;

  @media ${device.mobile} {
    padding: 16px;
    gap: 24px;
  }
`;

export const SupportLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.neutralW40};

  &:focus-visible {
    outline: 2px solid;
  }
`;

export const StationList = styled.button`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutralW40};
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;

  &:focus-visible {
    outline: 2px solid;
  }
`;
