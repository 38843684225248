/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { device } from 'src/styles/global';
import theme from 'src/styles/theme';

interface Props {
  $isActive: boolean;
  $isSelected: boolean;
  $hasError: boolean;
  $alreadyTrackedSongs: boolean;
}

export const BatchSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.neutralW80};
  padding: 24px 24px 16px 24px;

  @media ${device.mobile} {
    padding: 24px 16px 16px 16px;
  }
`;

export const BatchDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SongItemWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 4px;
  cursor: pointer;

  background: ${({ $isActive, $isSelected, $hasError }) =>
    $hasError
      ? theme.colors.error100
      : $isActive || $isSelected
      ? theme.colors.mtr100
      : ''};

  &:hover {
    background: ${theme.colors.neutralW100};
  }

  &:active {
    background: ${theme.colors.mtr100};
  }

  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const SongSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconWrapper = styled.div`
  svg {
    cursor: pointer;
    width: 18px;
    height: 18px;
    z-index: 999;
  }
`;

export const SongInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SongTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.neutralW40};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;

  @media ${device.mobile} {
    width: 140px;
  }
`;

export const ArtistName = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.neutralW40};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;

  @media ${device.mobile} {
    width: 140px;
  }
`;

export const ReleaseSongItemWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 4px;
  cursor: ${({ $alreadyTrackedSongs }) =>
    $alreadyTrackedSongs ? '' : 'pointer'};

  background: ${({ $isActive, $isSelected, $hasError, $alreadyTrackedSongs }) =>
    $hasError
      ? theme.colors.error100
      : $alreadyTrackedSongs
      ? ''
      : $isActive || $isSelected
      ? theme.colors.mtr100
      : ''};

  &:hover {
    background: ${({ $alreadyTrackedSongs }) =>
      $alreadyTrackedSongs ? '' : theme.colors.neutralW100};
  }

  &:active {
    background: ${({ $alreadyTrackedSongs }) =>
      $alreadyTrackedSongs ? '' : theme.colors.mtr100};
  }

  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const ReleaseSongTitle = styled.div<Props>`
  font-size: 14px;
  font-weight: 700;
  color: ${({ $alreadyTrackedSongs }) =>
    $alreadyTrackedSongs ? theme.colors.neutralW80 : theme.colors.neutralW40};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;

  @media ${device.mobile} {
    width: 140px;
  }
`;

export const ReleaseArtistName = styled.div<Props>`
  font-size: 12px;
  font-weight: 400;
  color: ${({ $alreadyTrackedSongs }) =>
    $alreadyTrackedSongs ? theme.colors.neutralW80 : theme.colors.neutralW40};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;

  @media ${device.mobile} {
    width: 140px;
  }
`;

export const AlreadyTrackedIconWrapper = styled.div`
  svg {
    width: 18px;
    height: 18px;
    z-index: 999;
  }
`;
