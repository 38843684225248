import styled from 'styled-components';

export const DropdownFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  position: relative;
`;

export const FilterByWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-self: center;
  svg {
    width: 18px;
    height: 18px;
  }
`;
