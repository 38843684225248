export const heatmapDataTemplate = [
  {
    x: '00:00',
    y: 0,
  },
  {
    x: '01:00',
    y: 0,
  },
  {
    x: '02:00',
    y: 0,
  },
  {
    x: '03:00',
    y: 0,
  },
  {
    x: '04:00',
    y: 0,
  },
  {
    x: '05:00',
    y: 0,
  },
  {
    x: '06:00',
    y: 0,
  },
  {
    x: '07:00',
    y: 0,
  },
  {
    x: '08:00',
    y: 0,
  },
  {
    x: '09:00',
    y: 0,
  },
  {
    x: '10:00',
    y: 0,
  },
  {
    x: '11:00',
    y: 0,
  },
  {
    x: '12:00',
    y: 0,
  },
  {
    x: '13:00',
    y: 0,
  },
  {
    x: '14:00',
    y: 0,
  },
  {
    x: '15:00',
    y: 0,
  },
  {
    x: '16:00',
    y: 0,
  },
  {
    x: '17:00',
    y: 0,
  },
  {
    x: '18:00',
    y: 0,
  },
  {
    x: '19:00',
    y: 0,
  },
  {
    x: '20:00',
    y: 0,
  },
  {
    x: '21:00',
    y: 0,
  },
  {
    x: '22:00',
    y: 0,
  },
  {
    x: '23:00',
    y: 0,
  },
];
