/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PackageObj } from 'src/features/Purchase/SelectPackage';
import { formatAmountWithoutRoundUp } from 'src/utils/Formatter';
import {
  DiscountWrapper,
  MarkdownPrice,
  MostPopularTag,
  PackageBox,
  PackageContainer,
  PackageWrapper,
  PerSongWrapper,
  PlanWrapper,
  PriceWrapper,
  SaveTag,
} from '../PricingModal.styles';

interface Props {
  packages: PackageObj[];
}

const RegularPricing: React.FC<Props> = ({ packages }) => {
  const { t } = useTranslation();

  return (
    <>
      <PackageWrapper>
        {packages.map((p, i) => {
          return (
            <PackageContainer key={p.plan.id}>
              <PackageBox $isMostPopularPackage={i === 1}>
                {i === 1 && (
                  <MostPopularTag>
                    {t('PRICING_MODAL_MOST_POPULAR')}
                  </MostPopularTag>
                )}
                <PlanWrapper>{p.plan.description}</PlanWrapper>
                <PriceWrapper>
                  ${formatAmountWithoutRoundUp(p.summary.unit_price)}
                  <PerSongWrapper>
                    &nbsp;{t('PRICING_MODAL_PER_SONG')}
                  </PerSongWrapper>
                </PriceWrapper>

                {p.plan.discount > 0 ? (
                  <DiscountWrapper>
                    <MarkdownPrice>
                      $
                      {formatAmountWithoutRoundUp(
                        Number(p?.summary?.unit_price) / p.plan.duration
                      )}
                      &nbsp;
                      {t('MULTI_ASSET_SONG_PRICE')}
                    </MarkdownPrice>
                    <SaveTag>
                      {' '}
                      {t('PRICING_MODAL_SAVE')}&nbsp;
                      {Number(p.plan.discount) * 100}%
                    </SaveTag>
                  </DiscountWrapper>
                ) : (
                  <DiscountWrapper>&nbsp;</DiscountWrapper>
                )}
              </PackageBox>
            </PackageContainer>
          );
        })}
      </PackageWrapper>
    </>
  );
};

export default RegularPricing;
