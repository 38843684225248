import { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAuthenticated } from 'src/features/Auth';

interface URLInterceptorProps {
  children: ReactNode;
}

const URLInterceptor: React.FC<URLInterceptorProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isUserAuthenticated = isAuthenticated();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (
      !isUserAuthenticated &&
      token &&
      location.pathname === `/payment/multiasset`
    ) {
      // Redirect and keep the token in the URL
      navigate(`/auth/signin?token=${token}`, { replace: true });
    }

    // Add other URL handling logic as needed
  }, [location, navigate]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <>{children}</>; // Correctly return a React element (JSX)
};

export default URLInterceptor;
