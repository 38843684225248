import { Text } from '@dsny/dsny-component-library';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import theme from 'src/styles/theme';
import { CUSTOMER_ZOHO_FORMS } from 'src/utils/Urls';
import { StationLink } from '../RadioStationModal.styles';
import { getStationList } from '../RadioStationModal.thunks';
import RadioStationContent from './RadioStationContent';
import RadioStationHeader from './RadioStationHeader';
import {
  FlexRowNoStationFound,
  FlexTableContent,
  TableContainer,
} from './RadioStationList.styles';

const RadioStationList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { radioStationTable, searchParams } = useSelector(
    (state: RootState) => ({
      radioStationTable:
        state.radioStationModal.radioStationList.radioStationTable,
      searchParams: state.radioStationModal.searchParams,
    })
  );

  useEffect(() => {
    if (searchParams.page === 1) {
      dispatch(getStationList(searchParams));
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.page > 1) {
      dispatch(getStationList(searchParams));
    }
  }, [searchParams.page]);

  return (
    <>
      {/*  Radio Station List */}
      <TableContainer>
        {/* Table Header */}
        <RadioStationHeader />

        {radioStationTable ? (
          /* Table Content */
          <RadioStationContent tableData={radioStationTable} />
        ) : (
          /* No Stations Found */
          <FlexTableContent>
            <FlexRowNoStationFound>
              <Text
                fontSize="14px"
                color={theme.colors.neutralW40}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {t('STATIONS_NOT_FOUND_MESSAGE')}
              </Text>
              <Text
                fontSize="14px"
                color={theme.colors.neutralW40}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <StationLink
                  href={CUSTOMER_ZOHO_FORMS}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('STATIONS_NOT_FOUND_LINK')}
                </StationLink>
                &nbsp;{t('STATIONS_NOT_FOUND_AFTER_LINK')}
              </Text>
            </FlexRowNoStationFound>
          </FlexTableContent>
        )}
      </TableContainer>
    </>
  );
};

export default RadioStationList;
