/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { formatTextForSentryLog } from 'src/utils/Formatter';
import {
  getCountries,
  getGenres,
  getStationList,
} from './RadioStationModal.thunks';

export interface Genres {
  name: string;
  code: string;
  isChecked: string;
}

export interface Genre {
  items?: Genres[];
  isFetchingGenresOverview: boolean;
  hasError: boolean;
}

export interface Countries {
  id: number;
  description: string;
}

export interface Country {
  items?: Countries[];
  isFetchingContriesOverview: boolean;
  hasError: boolean;
}

export interface RadioStationList {
  radioStationTable?: RadioStationTable;
  isFetchingRadioStationListOverview: boolean;
  hasError: boolean;
}

export interface RadioStationTable {
  page: number;
  page_size: number;
  items_count: number;
  total_stations: number;
  items: RadioStation[];
  showAllGenres: boolean;
}

export interface RadioStation {
  id: string;
  name: string;
  city: string;
  state: string;
  country: string;
  website: string;
  logo_url: string;
  genres: string[];
  showGenres: boolean;
}
export interface SearchParams {
  search: string;
  country?: string;
  genre: string;
  page: number;
  page_size: number;
  sort: string;
  hasNext: boolean;
}

export interface RadioStationModalObj {
  country: Country;
  genre: Genre;
  radioStationList: RadioStationList;
  searchParams: SearchParams;
  clearAllFilters: boolean;
}

const RadioStationModalSlice = createSlice({
  name: 'RadioStationModal',
  initialState: {
    country: {
      items: undefined,
      isFetchingContriesOverview: true,
      hasError: false,
    },
    genre: {
      items: undefined,
      isFetchingGenresOverview: true,
      hasError: false,
    },
    radioStationList: {
      radioStationTable: undefined,
      isFetchingRadioStationListOverview: true,
      hasError: false,
    },
    searchParams: {
      search: '',
      country: '',
      genre: '',
      page: 1,
      page_size: 10,
      sort: '',
      hasNext: true,
    },
    clearAllFilters: false,
  } as RadioStationModalObj,
  extraReducers: (builder) =>
    builder
      .addCase(getCountries.pending, (state) => {
        state.country.isFetchingContriesOverview = true;
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        state.country.hasError = false;
        state.country.isFetchingContriesOverview = false;

        state.country.items = payload.data.countries.map(
          (value: string, index: number) => {
            return { id: index + 1, description: value };
          }
        );
      })
      .addCase(getCountries.rejected, (state) => {
        state.country.hasError = true;
        state.country.isFetchingContriesOverview = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get countries')
        );
      })
      .addCase(getGenres.pending, (state) => {
        state.genre.isFetchingGenresOverview = true;
      })
      .addCase(getGenres.fulfilled, (state, { payload }) => {
        state.genre.hasError = false;
        state.genre.isFetchingGenresOverview = false;

        state.genre.items = payload.data.genres.map((value: string) => {
          return { name: value, code: value, isChecked: '' };
        });
      })
      .addCase(getGenres.rejected, (state) => {
        state.genre.hasError = true;
        state.genre.isFetchingGenresOverview = false;
        Sentry.captureException(formatTextForSentryLog('failed to get genres'));
      })
      .addCase(getStationList.pending, (state) => {
        state.genre.isFetchingGenresOverview = true;
      })
      .addCase(getStationList.fulfilled, (state, { payload }) => {
        state.radioStationList.hasError = false;
        state.radioStationList.isFetchingRadioStationListOverview = false;

        if (
          state.searchParams.page > 1 &&
          state.radioStationList.radioStationTable &&
          payload.data.items
        ) {
          state.radioStationList.radioStationTable.items =
            state.radioStationList.radioStationTable.items.concat(
              payload.data.items
            );
        } else if (payload.data.items) {
          state.radioStationList.radioStationTable = payload.data;
        } else if (state.searchParams.page === 1) {
          state.radioStationList = {
            radioStationTable: undefined,
            isFetchingRadioStationListOverview: true,
            hasError: false,
          };
        }

        if (!payload.data?.items && state.searchParams.page > 1) {
          state.searchParams.hasNext = false;
        }
      })
      .addCase(getStationList.rejected, (state) => {
        state.searchParams.hasNext = false;
        state.genre.hasError = true;
        state.genre.isFetchingGenresOverview = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get station list')
        );
      }),
  reducers: {
    hideOrShowAllGenres: (state) => {
      if (state.radioStationList.radioStationTable) {
        state.radioStationList.radioStationTable.showAllGenres =
          !state.radioStationList.radioStationTable?.showAllGenres;
      }
    },
    addSearchParams: (state, action) => {
      state.searchParams = action.payload;
    },
    setClearAllFilters: (state) => {
      state.clearAllFilters = !state.clearAllFilters;
    },
    resetFilters: (state) => {
      state.searchParams = {
        search: '',
        country: '',
        genre: '',
        page: 1,
        page_size: 10,
        sort: '',
        hasNext: true,
      };
    },
  },
});

export const {
  hideOrShowAllGenres,
  addSearchParams,
  setClearAllFilters,
  resetFilters,
} = RadioStationModalSlice.actions;

export default RadioStationModalSlice.reducer;
