import styled from 'styled-components';
import { Button } from '@dsny/dsny-component-library';

export const FlexButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

export const ModalCloseButton = styled(Button)`
  width: 104px;
`;

export const ModalContinueButton = styled(Button)`
  width: 140px;
`;
