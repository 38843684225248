import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Icon,
  MTRFullLogo,
  MaterialIcon,
  SpinningIcon,
  TextField,
} from '@dsny/dsny-component-library';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { AuthForm, forgotPassword } from 'src/features/Auth/Auth.thunks';
import theme from 'src/styles/theme';
import {
  FormBox,
  Title,
  Container,
  SigninLink,
  ButtonWrapper,
  SigninLinkWrapper,
  MessageWrapper,
  ContactLink,
  FinalMarkWrapper,
  ButtonSection,
  LogoMTRWrapper,
  TextFieldWrapper,
  MessageBox,
  MessageTitle,
  GoBackWrapper,
  ClickableGoBack,
  ReturnToSigninWrapper,
} from './ForgotPassword.styles';
import { AuthWrapper } from '../Auth.styles';
import { resetForgotPasswordResponse } from '../Auth.slice';

const schema = yup.object().shape({
  email: yup.string().required(),
});

const EMAIL_FIELD = 'email' as never;

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const { isFetching, forgotPasswordResponse } = useSelector(
    (state: RootState) => state.auth
  );
  const [emailAddress, setEmailAddress] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();

  // Form validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  // Handling onSubmit
  const onSubmit = ({ email }: AuthForm) => {
    if (isFetching) return;

    dispatch(forgotPassword(email));
    setEmailAddress(email);
  };

  // Handling back button
  const handleBackButton = () => {
    dispatch(resetForgotPasswordResponse());
    setEmailAddress('');
  };

  useEffect(() => {
    dispatch(resetForgotPasswordResponse());
  }, []);

  return (
    <AuthWrapper>
      <LogoMTRWrapper>
        <MTRFullLogo />
      </LogoMTRWrapper>

      <Container>
        {forgotPasswordResponse ? (
          <MessageBox>
            <GoBackWrapper>
              <ClickableGoBack
                onClick={() => {
                  handleBackButton();
                }}
              >
                <Icon
                  name="ArrowLeft"
                  style={{
                    width: '14px',
                    height: '14px',
                    marginRight: '4px',
                    color: theme.colors.neutralW30,
                  }}
                />
                {t('FORGOT_PASSWORD_GO_BACK_LINK')}
              </ClickableGoBack>
            </GoBackWrapper>
            <MessageTitle>
              {t('FORGOT_PASSWORD_SUCESSFULLY_MESSAGE')}
            </MessageTitle>
            <MessageWrapper>
              <p>
                <Trans
                  i18nKey="FORGOT_PASSWORD_INSTRUCTIONS_WITH_EMAIL"
                  values={{ email: emailAddress }}
                  components={{ bold: <strong /> }}
                />
              </p>
              <p>
                {t('FORGOT_PASSWORD_CONTACT_MESSAGE')}{' '}
                <ContactLink
                  href="mailto:support@plaympe.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('FORGOT_PASSWORD_CONTACT_US_LINK')}
                  <FinalMarkWrapper>.</FinalMarkWrapper>
                </ContactLink>
              </p>
              {/* TODO: will be implemented with BE integration */}
              {/* <p>
                {t('FORGOT_PASSWORD_RESEND_EMAIL')}{' '}
                <ResendEmailLink to="/auth/forgot_password">
                  {t('FORGOT_PASSWORD_RESEND_EMAIL_LINK')}
                </ResendEmailLink>
              </p> */}
              <ReturnToSigninWrapper>
                {t('FORGOT_PASSWORD_RETURN_TO')}{' '}
                <SigninLink to="/auth/signin">
                  {t('FORGOT_PASSWORD_SIGNIN_LINK')}
                </SigninLink>{' '}
                {t('FORGOT_PASSWORD_PAGE')}
              </ReturnToSigninWrapper>
            </MessageWrapper>
          </MessageBox>
        ) : (
          <FormBox
            onSubmit={handleSubmit((values: AuthForm) => onSubmit(values))}
          >
            <Title>{t('FORGOT_PASSWORD_TITLE')}</Title>
            <TextFieldWrapper>
              <TextField
                {...register(EMAIL_FIELD)}
                id={EMAIL_FIELD}
                ref={null}
                inputRef={register(EMAIL_FIELD).ref}
                setting="mtr"
                labelText={t('FORGOT_PASSWORD_USERNAME')}
                type="text"
                required
                error={errors.email && t('FORGOT_PASSWORD_ERROR_USERNAME')}
                wrapperStyles={{
                  paddingRight: '0px',
                  marginBottom: '0px',
                }}
                inputStyle={{
                  height: '44px',
                  borderRadius: '5px',
                  borderColor: errors?.email && theme.colors.error50,
                }}
                labelStyle={{
                  fontSize: '14px',
                  color: errors?.email && theme.colors.error50,
                }}
                errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
              />
            </TextFieldWrapper>
            <ButtonSection>
              <ButtonWrapper
                setting="mtr"
                fontSize="14px"
                type="submit"
                style={{
                  fontFamily: theme.fonts.primary,
                  height: '36px',
                  borderRadius: '5px',
                  paddingTop: isFetching ? '14px' : '',
                }}
              >
                {isFetching ? (
                  <SpinningIcon>
                    <MaterialIcon name="Rotate" />
                  </SpinningIcon>
                ) : (
                  t('FORGOT_PASSWORD_CTA_BUTTON')
                )}
              </ButtonWrapper>
            </ButtonSection>

            <SigninLinkWrapper>
              {t('FORGOT_PASSWORD_RETURN_TO')}&nbsp;
              <SigninLink to="/auth/signin">
                {t('FORGOT_PASSWORD_SIGNIN_LINK')}
              </SigninLink>
              &nbsp;{t('FORGOT_PASSWORD_PAGE')}
            </SigninLinkWrapper>
          </FormBox>
        )}
      </Container>
    </AuthWrapper>
  );
};

export default ForgotPassword;
