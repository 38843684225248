import styled from 'styled-components';
import { device, mtrStyle } from 'src/styles/global';
import { Button } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';

export interface Props {
  $isClickable?: boolean;
}

export const PurchaseWrapper = styled.div`
  min-height: calc(100vh - 62px);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 910px;
  min-height: 618px;
  padding-top: 176px;
  padding-bottom: 120px;

  @media ${device.mobile} {
    max-width: 288px;
    padding-top: 120px;
    padding-bottom: 72px;
  }
  @media ${device.tablet} {
    width: 90%;
  }
`;

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  position: inherit;
  padding: 64px 64px;
  border-radius: ${mtrStyle.borderRadius};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: ${theme.colors.white0};

  @media ${device.mobile} {
    padding: 48px 24px;
  }
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 32px;
  font-size: 14px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    font-size: 12px;
    padding-bottom: 24px;
  }
`;

export const ClickableStep = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : '')};
`;

export const FlexButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

export const FormButton = styled(Button)`
  width: 72px;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const DeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  font-family: ${theme.fonts.primary};

  &:hover {
    border-radius: 50px;
    background: ${theme.colors.neutralW100};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ModalCancelButton = styled(Button)`
  width: 104px;
  height: 36px;
  border-radius: 5px;
`;

export const ModalContinueButton = styled(Button)`
  width: 88px;
`;

export const ModalGoToDashboardButton = styled(Button)`
  width: fit-content;
`;

export const ModalDeleteSongButton = styled(Button)`
  width: 110px;
  height: 36px;
  border-radius: 5px;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 32px;

  @media ${device.mobile} {
    margin-bottom: 24px;
  }
`;

export const HeadingDeleteSongModal = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: ${theme.colors.neutralW20};
  margin-bottom: 8px;
`;

export const TextDeleteSongModal = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW20};
  margin-bottom: 40px;
`;
