/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Banner,
  Icon,
  MTRFullLogo,
  MaterialIcon,
  SpinningIcon,
  TextField,
} from '@dsny/dsny-component-library';
import { useParams } from 'react-router-dom';
import i18n from 'src/services/i18n';
import {
  passwordRegex,
  hasNumbers,
  hasMinimunSize,
  hasSpecialCharacters,
} from 'src/utils/RegexPatterns';

import { parseUserFromToken, isExpired } from 'src/utils/Authenticate';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import theme from 'src/styles/theme';
import { inputHandler } from 'src/utils/InputHandler';
import {
  FormBox,
  Title,
  Container,
  RequirementWrapper,
  Text,
  MessageBox,
  SigninLink,
  MessageTitle,
  LogoMTRWrapper,
  IconWrapper,
  MessageWrapper,
  ResetButton,
  NewLinkButton,
  ResetButtonWrapper,
  NewLinkButtonWrapper,
  ErrorWrapper,
  ContactLink,
  FinalMarkWrapper,
  ResetMessageWrapper,
  ReturnToSigninWrapper,
} from './ResetPassword.styles';
import { AuthWrapper } from '../Auth.styles';
import { resetPassword, forgotPassword } from '../Auth.thunks';

interface passwordVisibilityList {
  newPassword: passwordVisibilityObj;
  confirmPassword: passwordVisibilityObj;
}

interface passwordVisibilityObj {
  isVisible: boolean;
  visibilityData: passwordVisibilityData;
}

interface passwordVisibilityData {
  iconName: string;
  type: string;
}

const schema = yup.object().shape({
  email: yup.string().required(),
  newpassword: yup.string().matches(passwordRegex).required(),
  confirmpassword: yup.string().required(),
});

const EMAIL_FIELD = 'email';
const NEW_PASSWORD_FIELD = 'newpassword';
const CONFIRM_PASSWORD_FIELD = 'confirmpassword';

const getVisibilityData = (isVisible: boolean) => {
  return isVisible
    ? { iconName: 'Visibility', type: 'text' }
    : { iconName: 'VisibilityOff', type: 'password' };
};

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const [rerender, setRerender] = useState(false);
  const [isResetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);
  const [passwordVisibleList, setPasswordVisibleList] = useState({
    newPassword: {
      isVisible: false,
      visibilityData: getVisibilityData(false),
    },
    confirmPassword: {
      isVisible: false,
      visibilityData: getVisibilityData(false),
    },
  } as passwordVisibilityList);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [displayTips, setDisplayTips] = useState(false);
  const [passwordMatchErrorMessage, setPasswordMatchErrorMessage] =
    useState('');
  const { key } = useParams();
  const { username, exp } = parseUserFromToken(key!);
  const dispatch = useDispatch<AppDispatch>();
  const isLinkExpired = useMemo(() => isExpired(exp), [exp]);

  const { isFetching, resetPasswordResponse } = useSelector(
    (state: RootState) => state.auth
  );

  // Form validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: 'all',
    resolver: yupResolver<any>(schema),
    shouldUnregister: true,
  });

  // Handling onSubmit
  const onSubmit = ({ email, newpassword }: any) => {
    if (isFetching || buttonDisabled) return;

    dispatch(
      resetPassword({
        username: email,
        password: newpassword,
        authcode: '0',
        token: key!,
      })
    );
  };

  // Handling new password regex pattern
  const validatePassword = (pattern: RegExp) => {
    return pattern.test(getValues(NEW_PASSWORD_FIELD));
  };

  // Handling reset button
  const handleResetButton = () => {
    const disableButton =
      !getValues(NEW_PASSWORD_FIELD) ||
      !getValues(CONFIRM_PASSWORD_FIELD) ||
      !getValues(EMAIL_FIELD) ||
      getValues(NEW_PASSWORD_FIELD) !== getValues(CONFIRM_PASSWORD_FIELD) ||
      errors.newpassword;

    setButtonDisabled(!!disableButton);
    setRerender(!rerender);
  };

  // Handling password tips
  const showTips = () => {
    if (
      validatePassword(hasMinimunSize) &&
      validatePassword(hasNumbers) &&
      validatePassword(hasSpecialCharacters)
    ) {
      setDisplayTips(false);
    }
  };

  // Handling password error messages
  const setConfirmPasswordMessage = (passwordDontMatch: boolean) => {
    if (errors?.confirmpassword) {
      return t('RESET_PASSWORD_ERROR_CONFIRM_PASSWORD');
    }
    if (passwordDontMatch) {
      return t('RESET_PASSWORD_ERROR_NOT_MATCH');
    }
    return '';
  };

  useEffect(() => {
    setValue(EMAIL_FIELD, username);
    setPasswordMatchErrorMessage(
      setConfirmPasswordMessage(
        getValues(CONFIRM_PASSWORD_FIELD) &&
          getValues(CONFIRM_PASSWORD_FIELD).length ===
            getValues(NEW_PASSWORD_FIELD).length
      )
    );
    setRerender(!rerender);
  }, [i18n.language, username]);

  // Handling confirm password visibility
  const handlePasswordVisibility = (field: string) => {
    const passwordVisibleListTemp = passwordVisibleList;
    const isVisible =
      !passwordVisibleListTemp[field as keyof passwordVisibilityList].isVisible;

    passwordVisibleListTemp[field as keyof passwordVisibilityList].isVisible =
      isVisible;

    passwordVisibleListTemp[
      field as keyof passwordVisibilityList
    ].visibilityData = getVisibilityData(isVisible);

    setPasswordVisibleList({ ...passwordVisibleListTemp });
  };

  // Handling email me a new link button
  const sendResetPasswordEmail = () => {
    if (isFetching) return;
    dispatch(forgotPassword(username)).then(() =>
      setResetPasswordEmailSent(true)
    );
  };

  const expiredLinkMessage = {
    title: t('RESET_PASSWORD_ERROR_LINK'),
    description: t('RESET_PASSWORD_ERROR_EXPIRED_LINK'),
  };

  const tokenMessage = {
    title: t('RESET_PASSWORD_INVALID_TOKEN'),
    description: '',
  };

  return (
    <AuthWrapper>
      <LogoMTRWrapper>
        <MTRFullLogo />
      </LogoMTRWrapper>

      <Container>
        {isResetPasswordEmailSent && (
          <MessageBox>
            <MessageTitle>
              {t('FORGOT_PASSWORD_SUCESSFULLY_MESSAGE')}
            </MessageTitle>
            <ResetMessageWrapper>
              <p>{t('FORGOT_PASSWORD_INSTRUCTIONS')}</p>
              <p>
                {t('FORGOT_PASSWORD_CONTACT_MESSAGE')}{' '}
                <ContactLink
                  href="mailto:support@plaympe.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('FORGOT_PASSWORD_CONTACT_US_LINK')}
                  <FinalMarkWrapper>.</FinalMarkWrapper>
                </ContactLink>
              </p>
              <ReturnToSigninWrapper>
                {t('FORGOT_PASSWORD_RETURN_TO')}{' '}
                <SigninLink to="/auth/signin">
                  {t('FORGOT_PASSWORD_SIGNIN_LINK')}
                </SigninLink>{' '}
                {t('FORGOT_PASSWORD_PAGE')}
              </ReturnToSigninWrapper>
            </ResetMessageWrapper>
          </MessageBox>
        )}

        {isLinkExpired && !isResetPasswordEmailSent && (
          <MessageBox>
            <ErrorWrapper>
              <Banner bannerContent={expiredLinkMessage} variant="error" />
            </ErrorWrapper>
            <MessageTitle>{t('RESET_PASSWORD_TITLE')} </MessageTitle>
            <MessageWrapper>
              <p>{t('RESET_PASSWORD_ERROR_EXPIRED_LINK_DESC')}</p>
              <NewLinkButtonWrapper>
                <NewLinkButton
                  setting="mtr"
                  fontSize="14px"
                  type="button"
                  onClick={sendResetPasswordEmail}
                  style={{
                    fontFamily: theme.fonts.primary,
                    height: '36px',
                    borderRadius: '5px',
                    paddingTop: isFetching ? '14px' : '',
                  }}
                >
                  {isFetching ? (
                    <SpinningIcon>
                      <MaterialIcon name="Rotate" />
                    </SpinningIcon>
                  ) : (
                    t('RESET_PASSWORD_CTA_NEW_LINK')
                  )}
                </NewLinkButton>
              </NewLinkButtonWrapper>
              <ReturnToSigninWrapper>
                {t('RESET_PASSWORD_RETURN_TO')}{' '}
                <SigninLink to="/auth/signin">
                  {t('RESET_PASSWORD_SIGNIN_LINK')}
                </SigninLink>{' '}
                {t('RESET_PASSWORD_PAGE')}
              </ReturnToSigninWrapper>
            </MessageWrapper>
          </MessageBox>
        )}

        {!isLinkExpired && resetPasswordResponse === 'ERROR' && (
          <MessageBox>
            <ErrorWrapper>
              <Banner bannerContent={tokenMessage} variant="error" />
            </ErrorWrapper>
            <MessageWrapper>
              <ReturnToSigninWrapper>
                {t('RESET_PASSWORD_RETURN_TO')}{' '}
                <SigninLink to="/auth/signin">
                  {t('RESET_PASSWORD_SIGNIN_LINK')}
                </SigninLink>{' '}
                {t('RESET_PASSWORD_PAGE')}
              </ReturnToSigninWrapper>
            </MessageWrapper>
          </MessageBox>
        )}

        {!isLinkExpired && resetPasswordResponse === 'SUCCESS' && (
          <MessageBox>
            <MessageTitle>
              {t('RESET_PASSWORD_SUCCESSFULLY_MESSAGE')}
            </MessageTitle>
            <MessageWrapper>
              <p>{t('RESET_PASSWORD_SUCCESSFULLY_DESC')}</p>
              <ReturnToSigninWrapper>
                {t('RESET_PASSWORD_RETURN_TO')}{' '}
                <SigninLink to="/auth/signin">
                  {t('RESET_PASSWORD_SIGNIN_LINK')}
                </SigninLink>{' '}
                {t('RESET_PASSWORD_PAGE')}
              </ReturnToSigninWrapper>
            </MessageWrapper>
          </MessageBox>
        )}

        {!isLinkExpired && !resetPasswordResponse && (
          <FormBox onSubmit={handleSubmit(onSubmit)}>
            <Title>{t('RESET_PASSWORD_TITLE')}</Title>
            <TextField
              id="email"
              setting="mtr"
              labelText={t('RESET_PASSWORD_USERNAME')}
              type="text"
              {...register(EMAIL_FIELD)}
              value={getValues(EMAIL_FIELD) || ''}
              readOnly
              required
              wrapperStyles={{
                paddingRight: '0px',
                paddingBottom: '32px',
                marginBottom: '0px',
              }}
              inputStyle={{
                height: '44px',
                borderRadius: '5px',
                color: theme.colors.neutralW70,
              }}
              labelStyle={{ fontSize: '14px' }}
              ref={null}
            />

            <TextField
              id="newpassword"
              setting="mtr"
              labelText={t('RESET_PASSWORD_NEW_PASSWORD')}
              type={passwordVisibleList.newPassword.visibilityData.type}
              required
              {...register(NEW_PASSWORD_FIELD)}
              error={
                errors.newpassword && !getValues('newpassword')?.length
                  ? t('RESET_PASSWORD_ERROR_NEW_PASSWORD')
                  : ''
              }
              onBlur={(e: Event) => {
                inputHandler(setValue, trigger, e);
                handleResetButton();
                showTips();
                setPasswordMatchErrorMessage(
                  setConfirmPasswordMessage(
                    getValues('confirmpassword') &&
                      getValues('newpassword') !== getValues('confirmpassword')
                  )
                );
              }}
              onChange={(e: ChangeEvent) => {
                inputHandler(setValue, trigger, e);
                handleResetButton();
                setDisplayTips(getValues('newpassword'));
                setPasswordMatchErrorMessage(
                  setConfirmPasswordMessage(
                    getValues('confirmpassword') &&
                      getValues('confirmpassword').length >=
                        getValues('newpassword').length &&
                      getValues('newpassword') !== getValues('confirmpassword')
                  )
                );
              }}
              onFocus={() => setDisplayTips(!!getValues('newpassword'))}
              onClick={() => handlePasswordVisibility('newPassword')}
              wrapperStyles={{
                paddingRight: '0px',
                paddingBottom: '0px',
                marginBottom: displayTips ? '0px' : '32px',
              }}
              inputStyle={{
                height: '44px',
                borderRadius: '5px',
                borderColor: errors?.newpassword && theme.colors.error50,
              }}
              labelStyle={{
                fontSize: '14px',
                color: errors?.newpassword && theme.colors.error50,
              }}
              errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
              tailingIcon={
                passwordVisibleList.newPassword.visibilityData.iconName
              }
              isTailingClickable
              tailingStyle={{
                paddingTop: displayTips
                  ? '33px'
                  : errors?.newpassword
                  ? '7px'
                  : '33px',
                paddingRight: '10px',
              }}
              ref={null}
            />

            {displayTips && (
              <RequirementWrapper>
                <p>{t('RESET_PASSWORD_TIPS')}</p>
                <Text $isInvalid={!validatePassword(hasMinimunSize)}>
                  <IconWrapper $isInvalid={!validatePassword(hasMinimunSize)}>
                    <Icon
                      name={!validatePassword(hasMinimunSize) ? 'X' : 'Check'}
                      style={{
                        width: '12px',
                        height: 'auto',
                        marginRight: '8px',
                      }}
                    />
                  </IconWrapper>
                  {t('RESET_PASSWORD_AT_LEAST_SEVEN_CHARACTERS')}
                </Text>
                <Text $isInvalid={!validatePassword(hasNumbers)}>
                  <IconWrapper $isInvalid={!validatePassword(hasNumbers)}>
                    <Icon
                      name={!validatePassword(hasNumbers) ? 'X' : 'Check'}
                      style={{
                        width: '12px',
                        height: 'auto',
                        marginRight: '8px',
                      }}
                    />
                  </IconWrapper>
                  {t('RESET_PASSWORD_AT_LEATS_ONE_NUMBER')}
                </Text>
                <Text $isInvalid={!validatePassword(hasSpecialCharacters)}>
                  <IconWrapper
                    $isInvalid={!validatePassword(hasSpecialCharacters)}
                  >
                    <Icon
                      name={
                        !validatePassword(hasSpecialCharacters) ? 'X' : 'Check'
                      }
                      style={{
                        width: '12px',
                        height: 'auto',
                        marginRight: '8px',
                      }}
                    />
                  </IconWrapper>
                  {t('RESET_PASSWORD_AT_LEAST_ONE_SPECIAL_CHARACTER')}
                </Text>
              </RequirementWrapper>
            )}

            <TextField
              id="confirmpassword"
              setting="mtr"
              labelText={t('RESET_PASSWORD_CONFIRM_PASSWORD')}
              type={passwordVisibleList.confirmPassword.visibilityData.type}
              required
              {...register(CONFIRM_PASSWORD_FIELD)}
              ref={null}
              error={passwordMatchErrorMessage}
              onBlur={(e: Event) => {
                inputHandler(setValue, trigger, e);
                handleResetButton();
                setPasswordMatchErrorMessage(
                  setConfirmPasswordMessage(
                    getValues('confirmpassword') &&
                      getValues('newpassword') !== getValues('confirmpassword')
                  )
                );
              }}
              onChange={(e: ChangeEvent) => {
                inputHandler(setValue, trigger, e);
                handleResetButton();
                setPasswordMatchErrorMessage(
                  setConfirmPasswordMessage(
                    getValues('confirmpassword') &&
                      getValues('confirmpassword').length >=
                        getValues('newpassword').length &&
                      getValues('newpassword') !== getValues('confirmpassword')
                  )
                );
              }}
              onClick={() => handlePasswordVisibility('confirmPassword')}
              wrapperStyles={{
                paddingRight: '0px',
                paddingBottom: '0px',
                marginBottom: '32px',
              }}
              inputStyle={{
                height: '44px',
                borderRadius: '5px',
                borderColor:
                  errors?.confirmpassword ||
                  (passwordMatchErrorMessage && theme.colors.error50),
              }}
              labelStyle={{
                fontSize: '14px',
                color: passwordMatchErrorMessage && theme.colors.error50,
              }}
              errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
              tailingIcon={
                passwordVisibleList.confirmPassword.visibilityData.iconName
              }
              isTailingClickable
              tailingStyle={{
                paddingTop:
                  errors?.confirmpassword || passwordMatchErrorMessage
                    ? '7px'
                    : '33px',
                paddingRight: '10px',
              }}
            />

            <ResetButtonWrapper>
              <ResetButton
                setting="mtr"
                fontSize="14px"
                type="submit"
                style={{
                  fontFamily: theme.fonts.primary,
                  height: '36px',
                  borderRadius: '5px',
                  paddingTop: isFetching ? '14px' : '',
                }}
              >
                {isFetching ? (
                  <SpinningIcon>
                    <MaterialIcon name="Rotate" />
                  </SpinningIcon>
                ) : (
                  t('RESET_PASSWORD_CTA_BUTTON')
                )}
              </ResetButton>
            </ResetButtonWrapper>
          </FormBox>
        )}
      </Container>
    </AuthWrapper>
  );
};

export default ResetPassword;
