import styled, { css } from 'styled-components';
import { device } from 'src/styles/global';
import theme from 'src/styles/theme';

const sharedStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: ${theme.colors.neutralW40};
  padding-top: 8px;
  padding-bottom: 8px;

  @media ${device.mobile} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.ipad} {
    width: 100%;
  }
`;

export const PeriodWrapper = styled.div`
  ${sharedStyles}
  width: 262px;
`;

export const SampleSongWrapper = styled.div`
  ${sharedStyles}
  width: 200px;
  margin-bottom: 20px;
`;

export const ScheduledPlanWrapper = styled.div`
  ${sharedStyles}
  width: fit-content;
  margin-bottom: 20px;
`;

export const ActivePlanWrapper = styled.div`
  ${sharedStyles}
  width: 288px;
  margin-bottom: 20px;
`;

export const WarningPlanWrapper = styled.div`
  ${sharedStyles}
  width: 272px;
  margin-bottom: 20px;
  color: ${theme.colors.alert40};
`;

export const ExpiredPlanWrapper = styled.div`
  ${sharedStyles}
  width: 216px;
  margin-bottom: 20px;
  color: ${theme.colors.error50};
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-right: 6px;

  svg {
    width: 20px;
    height: 20px;
  }

  @media ${device.mobile} {
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const PlanButton = styled.button`
  display: flex;
  font-size: 16px;
  font-weight: 700;
  color: ${theme.colors.mtr60};
  border: none;
  background: none;
  cursor: pointer;

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const HiphenUnicode = styled.div`
  color: ${theme.colors.neutralW40};
`;
