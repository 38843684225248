/* eslint-disable @typescript-eslint/no-explicit-any */
export const downloadCsvFile = (response: any) => {
  const disposition = response.headers['content-disposition'];
  const fileName = disposition?.match(/filename="?(.+)"?/)?.[1] ?? 'file.csv';
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: 'text/csv',
    })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
