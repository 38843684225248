/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Banner,
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxPopover,
  MaterialIcon,
  Pill,
  Toggle,
} from '@dsny/dsny-component-library';
import React, { useEffect, useState } from 'react';
import theme from 'src/styles/theme';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { format } from 'date-fns-tz';
import { convertDateToUTC } from 'src/utils/Formatter';
import {
  ComboboxOptionWrapper,
  ComboboxWrapper,
  ItemWrapper,
  FilterInputWrapper,
  PillStyles,
  DateRangeWrapper,
  ComboboxPopoverStyles,
  ComboboxStyles,
  ComboboxInputStyles,
  ComboboxListStyles,
  ComboboxOptionStyles,
  ComparisonModeWrapper,
  ComparisonTitle,
  ComparisonDesc,
  FilterChipWrapper,
  IconWrapper,
  TitleWrapper,
  BannerWrapper,
  BannerStyles,
} from './UniversalFilter.styles';
import {
  getCities,
  getCitiesCompare,
  getSongDaily,
  getSongDailyCompare,
  getSongHeatmap,
  getSongHeatmapCompare,
  getSongOverview,
  getSongOverviewCompare,
  getStations,
  getStationsCompare,
} from '../Dashboard.thunks';
import { setFilterSelection } from '../Dashboard.slice';

export interface ComboValueProps {
  id: number;
  description?: string;
  code: string;
}

interface DateRange {
  pastDate: Date;
  presentDate?: Date;
}

interface UniversalFilterProps {
  hasComparisonOn: boolean;
  setComparisonOn: (comparisonOn: boolean) => void;
}

const UniversalFilter: React.FC<UniversalFilterProps> = ({
  hasComparisonOn,
  setComparisonOn,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const [showBannerWarning, setShowBannerWarning] = useState(false);
  const [disableComparison, setDisableComparison] = useState(false);

  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const { songCities, songDaily, songHeatmap, songOverview, songStations } =
    useSelector((state: RootState) => state.dashboard);

  let startDate: string | undefined;

  const setComparison = (value: boolean) => {
    setComparisonOn(value);
  };

  // Get date range
  const getDateRange = (days: number): DateRange => {
    const trackinPeriodIndex = selectedSong?.selected_tracking_period?.index;

    // Return tracking period date range when all time filter is selected
    if (Number(days) === 0 && Number(trackinPeriodIndex) !== -1) {
      const startPeriodDate =
        new Date(
          selectedSong?.selected_tracking_period?.tracking_period
            ?.start_tracking_on || ''
        ) || new Date();

      const endPeriodDate =
        new Date(
          selectedSong?.selected_tracking_period?.tracking_period
            ?.end_tracking_on || ''
        ) || new Date();

      // Return current date if tracking period is active
      const currentPeriodDate = new Date();

      return {
        pastDate: startPeriodDate,
        presentDate:
          endPeriodDate < currentPeriodDate ? endPeriodDate : currentPeriodDate,
      } as DateRange;
    }

    const start_tracking_on =
      selectedSong?.selected_tracking_period?.allTimeStartDate ||
      selectedSong?.selected_tracking_period?.tracking_period
        ?.start_tracking_on;

    const newTrackingDate = start_tracking_on
      ? new Date(start_tracking_on)
      : new Date();

    const today = new Date();

    const pastDate =
      days > 0
        ? new Date(today.getTime() - days * 24 * 60 * 60 * 1000)
        : newTrackingDate;

    // Subtracting a day at the end of the date ranges for last 7 days and last 30 days
    if (Number(days) === 7 || Number(days) === 30) {
      today.setDate(today.getDate() - 1);
      pastDate.setDate(pastDate.getDate() - 1);
    }

    return { pastDate, presentDate: today } as DateRange;
  };

  // Get current data range
  const getDateRangeCurrent = (days: number) => {
    const { pastDate, presentDate } = getDateRange(days);

    if (days === 0) {
      return <span>{`${pastDate}`}</span>;
    }
    // Formatting the date as MMM dd, yyyy
    const formattedPastDate: string = format(pastDate, 'MMM dd, yyyy');
    const formattedTodayDate: string = presentDate
      ? format(presentDate, 'MMM dd, yyyy')
      : '';
    return `${formattedPastDate} - ${formattedTodayDate}`;
  };

  // Get previous data range
  const getDateRangeCompare = (days: number) => {
    const { pastDate } = getDateRange(days);

    const newDays = Number(days) + 1;

    const newPastDate = new Date(
      pastDate.getTime() - newDays * 24 * 60 * 60 * 1000
    );

    const newPresentDate = new Date(
      pastDate.getTime() - 1 * 24 * 60 * 60 * 1000
    );

    // Formatting the date as MMM dd, yyyy
    const formattedPastDate: string = format(
      convertDateToUTC(newPastDate),
      'MMM dd, yyyy'
    );
    const formattedTodayDate: string = newPresentDate
      ? format(convertDateToUTC(newPresentDate), 'MMM dd, yyyy')
      : '';
    return `${formattedPastDate} - ${formattedTodayDate}`;
  };

  // Get filter input description
  const getFilterDescription = (desc: string, days: number) => {
    return (
      <FilterInputWrapper>
        <Pill label={desc} type="leading" style={{ ...PillStyles }} />
        <DateRangeWrapper>{getDateRangeCurrent(days)}</DateRangeWrapper>
      </FilterInputWrapper>
    );
  };

  // Setting last 7 days as default time filter
  const [value, setValue] = useState({
    id: 3,
    description: t('DASHBOARD_FILTER_LAST_SEVEN_DAYS'),
    code: '7',
  } as any);

  // Universal filter description
  const filter = [
    { id: 1, description: t('DASHBOARD_FILTER_ALL_TIME'), code: '0' },
    { id: 2, description: t('DASHBOARD_FILTER_RECENT'), code: '1' },
    { id: 3, description: t('DASHBOARD_FILTER_LAST_SEVEN_DAYS'), code: '7' },
    { id: 4, description: t('DASHBOARD_FILTER_LAST_THIRTY_DAYS'), code: '30' },
  ];

  // Handling combobox onChange
  const onChange = (option: ComboValueProps) => {
    // Setting comparison OFF for All time and Today filters
    if (option.id === 1 || option.id === 2) {
      setComparison(false);
    } else {
      setComparison(true);
    }

    // Setting comparison disabled for All time and Today filters
    setDisableComparison(option.id === 1 || option.id === 2);
    setValue({ ...option });
    dispatch(setFilterSelection(option.code));
  };

  const openCombobox = () => {
    setOpen(true);
  };

  const closeCombobox = () => {
    setOpen(false);
  };

  // Handling filter for comparison ON
  const compareFilter = (code: number) => {
    dispatch(
      getSongOverviewCompare({
        songId: selectedSong?.song_id,
        period: code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
    dispatch(
      getStationsCompare({
        songId: selectedSong?.song_id,
        period: code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
    dispatch(
      getCitiesCompare({
        songId: selectedSong?.song_id,
        period: code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
    dispatch(
      getSongDailyCompare({
        songId: selectedSong?.song_id,
        period: code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
    dispatch(
      getSongHeatmapCompare({
        songId: selectedSong?.song_id,
        period: code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
  };

  // Handling filter for comparison OFF
  const regularFilter = (code: number) => {
    dispatch(
      getSongOverview({
        songId: selectedSong?.song_id,
        period: code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
    dispatch(
      getStations({
        songId: selectedSong?.song_id,
        period: code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
    dispatch(
      getCities({
        songId: selectedSong?.song_id,
        period: code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
    dispatch(
      getSongDaily({
        songId: selectedSong?.song_id,
        period: code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
    dispatch(
      getSongHeatmap({
        songId: selectedSong?.song_id,
        period: code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
  };

  // Trigger filter for all the dashboard widgets
  useEffect(() => {
    if (selectedSong?.song_id) {
      const { code } = value;
      if (
        startDate !== undefined &&
        startDate !== selectedSong.selected_tracking_period.allTimeStartDate
      ) {
        setValue({ ...value });
      }
      if (hasComparisonOn) {
        compareFilter(code);
      } else {
        regularFilter(code);
      }
    }
  }, [selectedSong, value, hasComparisonOn]);

  // Get compare filter chip
  const getFilterChip = () => {
    if (!hasComparisonOn) {
      return null;
    }

    return (
      <FilterChipWrapper>
        <TitleWrapper>{t('UNIVERSAL_FILTER_CHIP_TEXT')}</TitleWrapper>
        {getDateRangeCompare(value.code)}
        <IconWrapper onClick={() => setComparison(false)}>
          <MaterialIcon name="Cancel" color={theme.colors.neutralW50} />
        </IconWrapper>
      </FilterChipWrapper>
    );
  };

  // Not enough data for comparison mode banner message
  const comparisonErrorMessage = {
    title: t('UNIVERSAL_FILTER_ERROR_BANNER_TITLE'),
    description: t('UNIVERSAL_FILTER_ERROR_BANNER_DESC'),
  };

  useEffect(() => {
    const displayMsg =
      (songCities.hasBannerWarning ||
        songDaily.hasBannerWarning ||
        songHeatmap.hasBannerWarning ||
        songOverview.hasBannerWarning ||
        songStations.hasBannerWarning) &&
      Number(value.code) > 1;
    setShowBannerWarning(displayMsg);
  }, [
    songCities.hasBannerWarning,
    songDaily.hasBannerWarning,
    songHeatmap.hasBannerWarning,
    songOverview.hasBannerWarning,
    songStations.hasBannerWarning,
    value,
  ]);

  useEffect(() => {
    if (showBannerWarning) {
      setComparison(false);
      setDisableComparison(true);
    } else if (Number(value.code) > 1) {
      setComparison(true);
      setDisableComparison(false);
    }
  }, [showBannerWarning, value]);

  // Waiting for tracking periods before rendering universal filter
  if (!selectedSong?.selected_tracking_period) {
    return <></>;
  }

  return (
    <>
      {/* Universal filter - the default state is last 7 days with comparison mode ON */}
      <ComboboxWrapper hasComparisonOn={hasComparisonOn}>
        <Combobox
          isOpen={open}
          onClick={openCombobox}
          onClose={closeCombobox}
          onChange={onChange}
          style={{ ...ComboboxStyles }}
          value={
            {
              id: value.id,
              description: getFilterDescription(value.description, value.code),
            } as any
          }
        >
          <ComboboxInput
            placeholder="Placeholder"
            highlightcolor={theme.colors.mtr60}
            style={{ ...ComboboxInputStyles }}
          />
          <ComboboxPopover
            style={{
              ...ComboboxPopoverStyles(showBannerWarning),
            }}
          >
            <ComboboxList style={{ ...ComboboxListStyles }}>
              {filter.map((f) => (
                <ComboboxOptionWrapper
                  key={f.id}
                  selectedItemIndex={value.id}
                  style={{ ...ComboboxOptionStyles }}
                  value={f}
                >
                  <ItemWrapper selectedItemBoolean={value.id === f.id}>
                    {f.description}
                    {value.id === f.id && (
                      <MaterialIcon
                        name="Check"
                        color={theme.colors.neutralW40}
                      />
                    )}
                  </ItemWrapper>
                </ComboboxOptionWrapper>
              ))}

              {/* Comparison Toggle */}
              <ComparisonModeWrapper
                disableComparison={disableComparison}
                onClick={() =>
                  !disableComparison && setComparison(!hasComparisonOn)
                }
              >
                <ComparisonTitle disableComparison={disableComparison}>
                  {t('UNIVERSAL_FILTER_COMPARISON_TITLE')}
                  <Toggle
                    player={false}
                    disabled={disableComparison}
                    id="compare"
                    checked={hasComparisonOn}
                    onChange={() => setComparison(hasComparisonOn)}
                  />
                </ComparisonTitle>

                {/* Comparison Description */}
                <ComparisonDesc disableComparison={disableComparison}>
                  {disableComparison || showBannerWarning ? (
                    t('UNIVERSAL_FILTER_COMPARISON_DESC_WITH_NO_DATE_RANGE')
                  ) : (
                    <Trans
                      i18nKey="UNIVERSAL_FILTER_COMPARISON_DESC_WITH_DATE_RANGE"
                      values={{
                        dateRange: getDateRangeCompare(value.code),
                      }}
                      components={{ bold: <strong /> }}
                    />
                  )}
                </ComparisonDesc>

                {/* Comparison Banner */}
                {showBannerWarning && (
                  <BannerWrapper>
                    <Banner
                      bannerContent={comparisonErrorMessage}
                      variant="warning"
                      customSmallFit
                      style={{ ...BannerStyles }}
                    />
                  </BannerWrapper>
                )}
              </ComparisonModeWrapper>
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>

        {/* Comparison Filter Chip */}
        {getFilterChip()}
      </ComboboxWrapper>
    </>
  );
};

export default UniversalFilter;
