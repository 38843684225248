import styled from 'styled-components';
import { Button } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { mtrStyle } from 'src/styles/global';

export interface Props {
  $isCardExpired?: boolean;
}

export const CardDetailsWrapper = styled.div`
  padding-bottom: 32px;
`;

export const CardIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const CardInfo = styled.div<Props>`
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  color: ${theme.colors.neutralW20};

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding-top: 8px;
    color: ${({ $isCardExpired }) =>
      $isCardExpired ? theme.colors.error50 : theme.colors.neutralW40};
  }
`;

export const EditCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${theme.colors.neutralW80};
  border-radius: ${mtrStyle.borderRadius};
  padding: 16px;
  gap: 16px;
  margin-bottom: 8px;
`;

export const HeadingModal = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: ${theme.colors.neutralW20};
  margin-bottom: 8px;
`;

export const TextModal = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW20};
  margin-bottom: 32px;
`;

export const FlexButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

export const ModalDeleteForeverButton = styled(Button)`
  width: fit-content;
`;

export const ModalKeepCardButton = styled(Button)`
  width: fit-content;
`;
