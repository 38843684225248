import React from 'react';
import { Navigate } from 'react-router-dom';
import NavLanguage from 'src/components/NavLanguage/NavLanguage';
import { isAuthenticated } from 'src/features/Auth';
import FooterPublic from 'src/components/Footer/FooterPublic';

export interface Props {
  component: React.FC;
}

const PublicRoute = ({ component: Component }: Props) => {
  return isAuthenticated() ? (
    <Navigate to="/" />
  ) : (
    <>
      <NavLanguage />
      <Component />
      <FooterPublic />
    </>
  );
};

export default PublicRoute;
