export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\""))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^;:"+=\\-])[A-Za-z\d@$!%*#?&^;:"+=\\-]{7,}$/;

export const hasMinimunSize = /^.{7,}$/;

export const hasNumbers = /^.*[0-9].*$/;

export const hasSpecialCharacters = /[@$!%*#?&^;:"+=\\-]+/;

export const supportedFileFormats =
  /(\.wav|\.aif|\.aiff|\.mp2|\.mp3|\.m4a|\.wma|\.ogg)$/i;

export const promoPercentagOff = /(\d+)% off/;
