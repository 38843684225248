import styled from 'styled-components';

export const HomeWrapper = styled.div`
  min-height: calc(100vh - 62px);
`;

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  padding-top: 80px;
`;

export const Message = styled.div`
  padding: 2rem 2rem;
  font-size: 16px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.svgStroke};
`;
