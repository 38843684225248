import React from 'react';
import theme from 'src/styles/theme';
import { useTranslation } from 'react-i18next';
import { MaterialIcon, Pill, Tooltip } from '@dsny/dsny-component-library';
import {
  IconContainerStyles,
  PillStyles,
  TooltipWrapper,
} from './ExportData.styles';

interface ExportDataProps {
  handleDownload: () => void;
}

const ExportData: React.FC<ExportDataProps> = ({ handleDownload }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Export widget data */}
      <TooltipWrapper>
        <Tooltip
          text={t('DASHBOARD_EXPORT_WIDGET_DATA')}
          placement="top"
          color={theme.colors.white}
          backgroundColor={theme.colors.neutralW50}
          hasArrow={false}
          containerStyles={{
            display: 'block',
            width: '149px',
            height: '14px',
            whiteSpace: 'normal',
            fontFamily: theme.fonts.primary,
            fontSize: '12px',
            fontWeight: '700',
            lineHeight: '16px',
            borderRadius: '5px',
            textAlign: 'left',
            marginTop: '-8px',
          }}
        >
          <Pill
            label={t('DASHBOARD_EXPORT_FILE')}
            onClick={handleDownload}
            type="leading"
            iconPosition="tailing"
            icon={
              <MaterialIcon
                name="FileDownload"
                color={theme.colors.neutralW20}
              />
            }
            iconContainerStyles={{ ...IconContainerStyles }}
            style={{ ...PillStyles }}
          />
        </Tooltip>
      </TooltipWrapper>
    </>
  );
};

export default ExportData;
