import styled from 'styled-components';
import theme from 'src/styles/theme';
import { device } from 'src/styles/global';
import { DayPicker } from 'react-day-picker';

export const ComboboxWrapper = styled.div`
  width: 100%;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const ComboboxStyles = {
  width: '100%',
  paddingBottom: '16px',
};

export const ComboboxInputStyles = {
  color: theme.colors.neutralW40,
  height: '44px',
};

export const InputLabelStyles = {
  marginLeft: '0px',
};

export const ComboboxPopoverStyles = {
  width: 'fit-content',
  maxHeight: 'fit-content',
  borderRadius: '5px',
  marginTop: '8px',
  borderColor: theme.colors.white,
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
  overflow: 'hidden',
  padding: '8px',
};

export const StyledDayPicker = styled(DayPicker)`
  .rdp {
    /* Main container styles */
    background-color: ${theme.colors.white0};
  }

  .rdp-header {
    /* Header container styles */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .rdp-caption {
    /* Month caption styles */
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${theme.colors.neutralW10};
    margin-top: 8px;
    margin-right: 8px;
    margin-left: 10px;

    @media ${device.mobile} {
      font-size: 14px;
    }
  }

  .rdp-nav {
    /* Navigation container styles */
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .rdp-nav_button {
    /* Navigation button styles */
    color: ${theme.colors.neutralW40};
    background-color: ${theme.colors.white0};
    border: none;
    cursor: pointer;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .rdp-head_cell {
    /* Week cell styles */
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${theme.colors.neutralW40};
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .rdp-day {
    /* Day cell styles */
    width: 32px;
    height: 32px;
    border: 0px solid;
    border-radius: 5px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${theme.colors.neutralW10};
    background-color: ${theme.colors.neutralW90};
    transition: background-color 0.3s ease;
    cursor: pointer;
    padding: 6px 8px;
    margin: 1px;

    &:hover:not(.rdp-day_disabled) {
      background-color: ${theme.colors.mtr90};
    }

    @media ${device.mobile} {
      width: 30px;
      height: 30px;
      margin: 0px;
    }
  }

  .rdp-day_selected,
  .rdp-day_selected:hover {
    /* Selected day styles */
    background-color: ${theme.colors.mtr60};
    color: ${theme.colors.white0};
  }

  .rdp-day_disabled {
    /* Disabled days styles */
    color: ${theme.colors.neutralW60};
    background-color: ${theme.colors.neutralW100};
    pointer-events: none;
  }
`;

export const InlineNote = styled.p`
  color: ${theme.colors.neutralW40};
  font-size: 12px;
  margin-top: 8px;
`;
