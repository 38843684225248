import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioStationModal from 'src/features/RadioStationModal';
import {
  BASE_SITE_URL,
  BASE_SITE_PRIVACY_SECTION,
  CUSTOMER_SUPPORT_EMAIL,
} from 'src/utils/Urls';
import {
  Container,
  SupportLink,
  FooterWraper,
  StationList,
} from './Footer.styles';

const FooterPrivate: React.FC = () => {
  const { t } = useTranslation();
  const [showStationModal, setShowStationModal] = useState(false);

  return (
    <Container>
      <FooterWraper>
        {/* Support link */}
        <SupportLink
          href={`mailto:${CUSTOMER_SUPPORT_EMAIL}`}
          target="_blank"
          rel="noreferrer"
        >
          {t('FOOTER_LINK_SUPPORT')}
        </SupportLink>

        {/* Privacy link */}
        <SupportLink
          href={BASE_SITE_PRIVACY_SECTION}
          target="_blank"
          rel="noreferrer"
        >
          {t('FOOTER_LINK_PRIVACY')}
        </SupportLink>

        {/* Company link */}
        <SupportLink href={BASE_SITE_URL} target="_blank" rel="noreferrer">
          {t('FOOTER_LINK_COMPANY')}
        </SupportLink>

        {/* Station list button -  it will be implemented along with API integration */}
        <div>
          <StationList onClick={() => setShowStationModal(true)}>
            {t('FOOTER_STATION_LIST_MODAL')}
          </StationList>
          <RadioStationModal
            showStationModal={showStationModal}
            setShowStationModal={setShowStationModal}
          />
        </div>
      </FooterWraper>
    </Container>
  );
};

export default FooterPrivate;
