import theme from 'src/styles/theme';

export const getComparisonBadgeStyle = (value?: number) => {
  if (value && value > 0)
    return {
      color: theme.colors.success40,
      backgroundColor: theme.colors.success90,
    };
  if (value && value < 0)
    return {
      color: theme.colors.error50,
      backgroundColor: theme.colors.error90,
    };

  return {
    color: theme.colors.neutralW50,
    backgroundColor: theme.colors.neutralW90,
  };
};
