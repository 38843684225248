import React from 'react';
import { Navigate } from 'react-router-dom';
import FooterPrivate from 'src/components/Footer/FooterPrivate';
import { isAuthenticated } from 'src/features/Auth';
import Navbar from '../components/Navbar/Navbar';

export interface Props {
  component: React.FC;
}

const PrivateRoute = ({ component: Component }: Props) => {
  return isAuthenticated() ? (
    <>
      <Navbar />
      <Component />
      <FooterPrivate />
    </>
  ) : (
    <Navigate to="/auth/signin" />
  );
};

export default PrivateRoute;
