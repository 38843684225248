/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Banner,
  Checkbox,
  Icon,
  MaterialIcon,
  MTRFullLogo,
  SpinningIcon,
  TextField,
} from '@dsny/dsny-component-library';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import {
  getSessionUser,
  registerUser,
  signIn,
} from 'src/features/Auth/Auth.thunks';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import theme from 'src/styles/theme';
import { inputHandler } from 'src/utils/InputHandler';
import {
  Province,
  provincesData,
  ProvincesData,
} from 'src/utils/ProvincesData';
import {
  FormBox,
  Container,
  LogoMTRWrapper,
  StepsWrapper,
  ClickableStep,
  CheckboxWrapper,
  LinkStyle,
  HaveAccountSection,
  SecondaryTitle,
  FlexEndButtonWrapper,
  StepThreeTitleDesc,
  TextFieldWrapper,
  TextFieldSection,
  SubmitButton,
  ErrorWrapper,
} from './Signup.styles';
import { AuthWrapper } from '../Auth.styles';
import SignupSelectionCountry, {
  CountryData,
} from './SignupSelection/SignupSelectionCountry';
import SignupSelectionProvince from './SignupSelection/SignupSelectionProvince';
import {
  identifyUser,
  initializeEventWrapper,
  trackEvent,
} from '../../../utils/segmentService';

const schema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  company: yup.string().when('hasAffiliation', {
    is: false,
    then: yup
      .string()
      .required('Company is required when you have an affiliation'),
    otherwise: yup.string(),
  }),
  country: yup.object().required(),
  province: yup.object().when('country', {
    is: (country: CountryData) =>
      !!provincesData[country?.id as keyof ProvincesData], // Make 'province' required if 'country' is selected
    then: yup
      .object()
      .required('Province is required when country is selected'),
    otherwise: yup.object(), // No validation if 'country' is not selected
  }),
});

const FIRST_NAME_FIELD = 'firstname';
const LAST_NAME_FIELD = 'lastname';
const COMPANY_FIELD = 'company';
const POSITION_FIELD = 'position';
const COUNTRY_FIELD = 'country';
const PROVINCE_FIELD = 'province';
const HAS_AFFILIATION = 'hasAffiliation';

const SignupStepThree: React.FC = () => {
  const { t } = useTranslation();
  const [rerender, setRerender] = useState(false);
  const [hasAffiliation, setHasAffiliation] = useState(false);
  const [newsCheckbox, setNewsCheckbox] = useState<string>('');
  const [companyPreviusState, setCompanyPreviusState] = useState<string>('');
  const [provinces, setProvinces] = useState<Province[]>();
  const { isFetching, signUp } = useSelector((state: RootState) => state.auth);
  const { isUserRegistered, hasResponseError } = signUp.stepThree;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Form validation step three
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver<any>(schema),
    defaultValues: {
      hasAffiliation: false,
    },
    shouldUnregister: true,
  });

  // Handling step three validation
  const handleValidation = () => {
    setRerender(!rerender);
  };

  // Handling terms checkbox
  const handleNewsCheckbox = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const checked = target.value;
    setNewsCheckbox(checked ? '' : 'newsCheckValue');
  };

  // Set provinces data to provinces combobox
  const displayProvinceDropDown = (item: CountryData) => {
    setProvinces(provincesData[item.id as keyof ProvincesData]);
  };

  // Handling affiliation checkbox
  const handleHasAffiliation = (affiliation: boolean) => {
    setHasAffiliation(affiliation);
    setValue(HAS_AFFILIATION, affiliation);
    if (affiliation) {
      setCompanyPreviusState(getValues(COMPANY_FIELD));
      setValue(COMPANY_FIELD, '');
    } else {
      setValue(COMPANY_FIELD, companyPreviusState);
    }
    trigger(COMPANY_FIELD);
  };

  // Set Country and validate it
  const selectCountry = (item: CountryData) => {
    setValue(COUNTRY_FIELD, item);
    displayProvinceDropDown(item);
    trigger(COUNTRY_FIELD);
  };

  // Set Province and validate it
  const selectProvince = (item: Province) => {
    setValue(PROVINCE_FIELD, item);
    trigger(PROVINCE_FIELD);
  };

  // Handling onSubmit step three
  const onSubmit = ({
    firstname,
    lastname,
    company,
    position,
    country,
    province,
  }: any) => {
    const utmSource = searchParams.get('utm_source') || '';
    const utmMedium = searchParams.get('utm_medium') || '';
    const utmCampaign = searchParams.get('campaign') || '';
    const gclId = searchParams.get('gclid') || '';

    const m_params = {
      source: utmSource,
      campaign_medium: utmMedium,
      campaign_name: utmCampaign,
      g_visitor_id: gclId,
    };

    dispatch(
      registerUser({
        email: signUp.stepOne.email,
        first_name: firstname,
        last_name: lastname,
        company_name: company,
        company_position: position,
        password: signUp.stepOne.password,
        identifier: uuidv4(),
        country: country.id,
        state: province?.id,
        marketing_params: m_params,
      })
    ).then(() => {
      trackEvent('Signup', {});
    });
  };

  // Step three error banner
  const errorRegistration = {
    title: t('SIGNUP_REGISTRATION_ERROR'),
    description: t('SIGNUP_REGISTRATION_ERROR_DESC'),
  };

  useEffect(() => {
    if (!signUp.stepTwo.code) navigate('/auth/signupsteptwo');
    if (!signUp.stepOne.email) navigate('/auth/signup');
  }, [signUp]);

  useEffect(() => {
    if (isUserRegistered) {
      dispatch(
        signIn({
          email: signUp.stepOne.email,
          password: signUp.stepOne.password,
        })
      ).then(() => {
        const user = getSessionUser();
        identifyUser(user.staffid, {
          email: user.email,
          name: user.fullname,
          username: user.username,
          company_id: user.company_id,
        });
        initializeEventWrapper(user.company_id);
        trackEvent('Login', {}, () => {
          navigate('/dashboard');
        });
      });
    }
  }, [isUserRegistered]);

  return (
    <AuthWrapper>
      <LogoMTRWrapper>
        <MTRFullLogo />
      </LogoMTRWrapper>

      {/* Step 3 */}
      <Container>
        <FormBox onSubmit={handleSubmit(onSubmit)}>
          {/* Banner message */}
          {hasResponseError && (
            <ErrorWrapper>
              <Banner bannerContent={errorRegistration} variant="error" />
            </ErrorWrapper>
          )}
          <StepsWrapper>
            <ClickableStep
              $isClickable
              onClick={() => navigate('/auth/signupsteptwo')}
            >
              <Icon
                name="ArrowLeft"
                style={{
                  width: '14px',
                  height: '14px',
                  marginRight: '4px',
                  color: theme.colors.neutralW30,
                }}
              />
              {t('SIGNUP_STEP_THREE')}
            </ClickableStep>
            &nbsp;{t('SIGNUP_OF_3_STEP')}
          </StepsWrapper>
          <SecondaryTitle>{t('SIGNUP_STEP_THREE_TITLE')}</SecondaryTitle>
          <StepThreeTitleDesc>
            <p>{t('SIGNUP_STEP_THREE_FIRST_DESC')}</p>
          </StepThreeTitleDesc>
          <TextFieldSection>
            {/* First name */}
            <TextFieldWrapper
              id="firstname"
              setting="mtr"
              labelText={t('SIGNUP_FIRST_NAME')}
              type="text"
              required
              {...register(FIRST_NAME_FIELD)}
              ref={null}
              error={errors.firstname && t('SIGNUP_ERROR_FIRST_NAME')}
              onBlur={(e: Event) => {
                inputHandler(setValue, trigger, e);
                handleValidation();
              }}
              onChange={(e: ChangeEvent) => {
                inputHandler(setValue, trigger, e);
                handleValidation();
              }}
              onFocus={() => setRerender(!rerender)}
              width="226"
              wrapperStyles={{
                paddingRight: '0px',
                paddingBottom: '32px',
                marginBottom: '0px',
              }}
              inputStyle={{
                height: '44px',
                borderRadius: '5px',
                borderColor: errors.firstname && theme.colors.error50,
              }}
              labelStyle={{
                fontSize: '14px',
                color: errors.firstname && theme.colors.error50,
              }}
              errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
            />

            {/* Last name */}
            <TextFieldWrapper
              id="lastname"
              setting="mtr"
              labelText={t('SIGNUP_LAST_NAME')}
              type="text"
              required
              {...register(LAST_NAME_FIELD)}
              ref={null}
              error={errors.lastname && t('SIGNUP_ERROR_LAST_NAME')}
              onBlur={(e: Event) => {
                inputHandler(setValue, trigger, e);
                handleValidation();
              }}
              onChange={(e: ChangeEvent) => {
                inputHandler(setValue, trigger, e);
                handleValidation();
              }}
              onFocus={() => setRerender(!rerender)}
              width="226"
              wrapperStyles={{
                paddingRight: '0px',
                paddingBottom: '32px',
                marginBottom: '0px',
              }}
              inputStyle={{
                height: '44px',
                borderRadius: '5px',
                borderColor: errors.lastname && theme.colors.error50,
              }}
              labelStyle={{
                fontSize: '14px',
                color: errors.lastname && theme.colors.error50,
              }}
              errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
            />
          </TextFieldSection>

          {/* Company */}
          <TextFieldWrapper
            id="company"
            setting="mtr"
            labelText={t('SIGNUP_COMPANY')}
            type="text"
            required
            value={getValues(COMPANY_FIELD)}
            readOnly={hasAffiliation}
            {...register(COMPANY_FIELD)}
            ref={null}
            error={errors.company && t('SIGNUP_ERROR_AFFILIATION')}
            onBlur={(e: Event) => {
              handleHasAffiliation(hasAffiliation);
              inputHandler(setValue, trigger, e);
              handleValidation();
            }}
            onChange={(e: ChangeEvent) => {
              inputHandler(setValue, trigger, e);
              handleValidation();
            }}
            onFocus={() => setRerender(!rerender)}
            wrapperStyles={{
              paddingRight: '0px',
              paddingBottom: '16px',
              marginBottom: '0px',
            }}
            inputStyle={{
              height: '44px',
              borderRadius: '5px',
              borderColor: errors.company && theme.colors.error50,
            }}
            labelStyle={{
              fontSize: '14px',
              color: errors.company && theme.colors.error50,
            }}
            errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
          />

          {/* Affiliation */}
          <CheckboxWrapper $hasError={!!errors.company}>
            <Checkbox
              id="affiliationValue"
              color={theme.colors.green}
              value={hasAffiliation ? 'checked' : ''}
              checked={hasAffiliation}
              {...register(HAS_AFFILIATION)}
              onChange={() => handleHasAffiliation(!hasAffiliation)}
              style={{
                borderColor: errors.company
                  ? theme.colors.error50
                  : theme.colors.neutralW70,
                marginRight: '8px',
                marginBottom: '4px',
              }}
            />
            <span>{t('SIGNUP_AFILIATION')}</span>
          </CheckboxWrapper>

          {/* Position */}
          <TextField
            id="position"
            setting="mtr"
            labelText={t('SIGNUP_POSITION')}
            type="text"
            {...register(POSITION_FIELD)}
            ref={null}
            onBlur={(e: Event) => {
              inputHandler(setValue, trigger, e);
              handleValidation();
            }}
            onChange={(e: ChangeEvent) => {
              inputHandler(setValue, trigger, e);
              handleValidation();
            }}
            onFocus={() => setRerender(!rerender)}
            wrapperStyles={{
              paddingRight: '0px',
              paddingBottom: '32px',
              marginBottom: '0px',
            }}
            inputStyle={{
              height: '44px',
              borderRadius: '5px',
            }}
            labelStyle={{ fontSize: '14px' }}
          />
          {/* Country selection */}
          <SignupSelectionCountry
            {...register(COUNTRY_FIELD)}
            selectCountry={selectCountry}
            ref={null}
            error={!!errors.country}
          />

          {/* Province / State selection */}
          {provinces?.length && (
            <SignupSelectionProvince
              {...register(PROVINCE_FIELD)}
              selectProvince={selectProvince}
              ref={null}
              provinces={provinces || []}
              error={!!errors.province}
            />
          )}
          {/* TODO: We will implement with MTR news subscription later.  */}
          {false && (
            <CheckboxWrapper>
              <Checkbox
                id="newsCheckValue"
                name="newsCheckValue"
                color={theme.colors.green}
                onChange={handleNewsCheckbox}
                value={newsCheckbox}
                checked={!!newsCheckbox}
                style={{
                  borderColor: theme.colors.neutralW70,
                  marginRight: '8px',
                  marginBottom: '4px',
                }}
              />
              <span>{t('SIGNUP_MTR_NEWS')}</span>
            </CheckboxWrapper>
          )}
          {/* Submit button */}
          <FlexEndButtonWrapper>
            <SubmitButton
              setting="mtr"
              fontSize="14px"
              type="submit"
              style={{
                fontFamily: theme.fonts.primary,
                height: '36px',
                borderRadius: '5px',
                paddingTop: isFetching ? '14px' : '',
              }}
            >
              {isFetching ? (
                <SpinningIcon>
                  <MaterialIcon name="Rotate" />
                </SpinningIcon>
              ) : (
                t('SIGNUP_CTA_SUBMIT_BUTTON')
              )}
            </SubmitButton>
          </FlexEndButtonWrapper>
          <HaveAccountSection>
            {t('SIGNUP_HAVE_ACCOUNT')}&nbsp;
            <LinkStyle to="/auth/signin">
              {t('SIGNUP_HAVE_ACCOUNT_LINK')}
            </LinkStyle>
          </HaveAccountSection>
        </FormBox>
      </Container>
    </AuthWrapper>
  );
};

export default SignupStepThree;
