/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { Text } from '@dsny/dsny-component-library';
import React from 'react';
import theme from 'src/styles/theme';
import { useTranslation } from 'react-i18next';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import {
  DashboardBox,
  DashboardChartDesc,
  DashboardExportDataWrapper,
  DashboardTitle,
  NoDataAvailableWrapper,
} from '../Dashboard.styles';
import {
  LineChart,
  LineChartBox,
  TimelineContainer,
} from './TimelineTracking.styles';
import { getSongDailyFile } from '../Dashboard.thunks';
import ExportData from '../ExportData/ExportData';

const TimelineTracking: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { songSelection, songDaily, filterSelection } = useSelector(
    (state: RootState) => state.dashboard
  );

  const { selectedSong } = songSelection;

  const { songDailyData } = songDaily;

  // Handling export data report
  const handleSongDailyFile = () => {
    dispatch(
      getSongDailyFile({
        songId: selectedSong?.song_id,
        period: filterSelection.code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
  };

  if (!songDailyData || !songDailyData?.data) {
    return <></>;
  }

  // Handling a single point value for Today filter
  const listSize = songDailyData?.data?.data?.length;

  return (
    <>
      {/* Timeline Tracking */}
      <TimelineContainer>
        <DashboardBox>
          <DashboardTitle>
            {t('DASHBOARD_TIMELINE_TRACKING_TITLE')}
          </DashboardTitle>
          <DashboardChartDesc>
            <Text
              fontSize="14px"
              fontWeight={400}
              color={theme.colors.neutralW40}
            >
              {t('DASHBOARD_TIMELINE_TRACKING_DESC')}
            </Text>
            <DashboardExportDataWrapper>
              {/* Export data */}
              <ExportData handleDownload={handleSongDailyFile} />
            </DashboardExportDataWrapper>
          </DashboardChartDesc>

          {!songDailyData?.data.data.length ? (
            // Null state message
            <NoDataAvailableWrapper>
              <Text
                fontSize="16px"
                fontWeight={400}
                color={theme.colors.neutralW60}
              >
                {t('DASHBOARD_NO_DATA_AVAILABLE')}
              </Text>
            </NoDataAvailableWrapper>
          ) : (
            // Line chart
            <LineChartBox>
              <LineChart size={songDailyData?.data?.data?.length}>
                <ResponsiveLine
                  data={[songDailyData?.data] as any}
                  margin={{ top: 70, right: 50, bottom: 45, left: 60 }}
                  xScale={{ type: 'point' }}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: 'auto',
                    stacked: true,
                    reverse: false,
                  }}
                  yFormat=" <-.3~s"
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 0,
                    tickPadding: 20,
                    tickRotation: 0,
                    legendOffset: 36,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 30,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: 'middle',
                    format: (e) => Math.floor(e) === e && e,
                  }}
                  animate={false}
                  tooltip={
                    function (feature: any) {
                      return (
                        <div
                          style={{
                            fontSize: '14px',
                            color: theme.colors.white0,
                            background: theme.colors.neutralW50,
                            borderRadius: '5px',
                            padding: 10,
                          }}
                        >
                          <span>
                            {feature.point.data.y.toLocaleString()}{' '}
                            {feature.point.data.y > 1
                              ? t('DASHBOARD_PLAYS')
                              : t('DASHBOARD_PLAY')}
                          </span>
                        </div>
                      );
                    } as any
                  }
                  enableGridX={listSize === 1}
                  enableGridY={false}
                  pointSize={listSize === 1 ? 12 : 6}
                  colors={theme.colors.mtr60}
                  enablePoints
                  enableArea
                  areaBlendMode="darken"
                  areaBaselineValue={0}
                  areaOpacity={1}
                  useMesh
                  defs={[
                    linearGradientDef('gradientA', [
                      { offset: 0, color: theme.colors.mtr90 },
                      { offset: 100, color: theme.colors.mtr90, opacity: 0 },
                    ]),
                  ]}
                  fill={[{ match: '*', id: 'gradientA' }]}
                />
              </LineChart>
            </LineChartBox>
          )}
        </DashboardBox>
      </TimelineContainer>
    </>
  );
};

export default TimelineTracking;
