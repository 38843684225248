/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComboboxInput,
  ComboboxList,
  ComboboxPopover,
} from '@dsny/dsny-component-library';
import React, { useEffect, useState } from 'react';
import theme from 'src/styles/theme';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from 'src/app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  ComboboxOptionWrapper,
  ComboboxWrapper,
  ItemWrapper,
} from './CountrySelection.styles';
import { getCountries } from '../RadioStationModal.thunks';
import { addSearchParams, Countries } from '../RadioStationModal.slice';

const CountrySelection: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({ id: 0, description: '' } as any);
  const dispatch = useDispatch<AppDispatch>();

  const { country, searchParams, clearAllFilters } = useSelector(
    (state: RootState) => state.radioStationModal
  );

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    setValue({ id: 0, description: '' });
  }, [clearAllFilters]);

  // Handling country selection
  const onChange = (option: Countries) => {
    setValue({ ...option });

    dispatch(
      addSearchParams({
        ...searchParams,
        country: option.description,
        page: 1,
        hasNext: true,
      })
    );
  };

  const openCombobox = () => {
    setOpen(true);
  };

  const closeCombobox = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Country selection */}
      <ComboboxWrapper
        isOpen={open}
        onClick={openCombobox}
        onClose={closeCombobox}
        onChange={onChange}
        label={t('STATIONS_COUNTRY_LABEL')}
        style={{
          width: '170px',
        }}
        value={value as any}
      >
        <ComboboxInput
          placeholder={t('STATIONS_ALL_PLACEHOLDER')}
          highlightcolor={theme.colors.mtr60}
          style={{
            color: theme.colors.neutralW50,
            position: 'inherit',
            height: '44px',
          }}
        />

        <ComboboxPopover
          style={{
            width: '218px',
            height: '216px',
            borderRadius: '5px',
            marginTop: '8px',
            borderColor: theme.colors.white,
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
          }}
        >
          <ComboboxList style={{ paddingTop: '16px', paddingBottom: '16px' }}>
            {country?.items?.map((c) => (
              <ComboboxOptionWrapper
                key={c.id}
                selectedItemIndex={value.id}
                style={{ paddingLeft: '16px' }}
                value={c}
              >
                <ItemWrapper>{c.description}</ItemWrapper>
              </ComboboxOptionWrapper>
            ))}
          </ComboboxList>
        </ComboboxPopover>
      </ComboboxWrapper>
    </>
  );
};

export default CountrySelection;
