/* eslint-disable import/no-extraneous-dependencies */
// segmentService.ts
import Cookies from 'js-cookie';

type TrackEventProps = {
  [key: string]: string;
};

type PageViewProps = {
  [key: string]: string;
};

type IdentifyUserTraits = {
  [key: string]: string;
};

type IdentifyUserOptions = {
  [key: string]: string;
};

const UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

let company_id: string;

// Function to initialize the event wrapper with company_id getter
const initializeEventWrapper = (comp_id: string) => {
  company_id = comp_id;
};

const getCompanyID = (): object => {
  return company_id !== '' ? { company_id } : {};
};

const extractUrlParams = (): URLSearchParams => {
  // Extract the hash part of the URL
  const { hash } = window.location;

  // Check if there's a query string after the '#'
  const queryIndex = hash.indexOf('?');
  if (queryIndex !== -1) {
    // Get the query string from the hash
    const queryString = hash.substring(queryIndex + 1).trim();
    // Parse the query parameters using URLSearchParams
    return new URLSearchParams(queryString);
    // You can handle more parameters here
  }

  return new URLSearchParams();
};

const getUTMParams = (): Record<string, string | null> => {
  // Step 1: Check for UTM parameters in the URL
  const urlParams = extractUrlParams();
  const utmParams: Record<string, string | null> = {};
  let hasUTMFromURL = false;

  UTM_KEYS.forEach((key) => {
    const paramValue = urlParams.get(key);
    if (paramValue) {
      utmParams[key] = paramValue;
      hasUTMFromURL = true;
    }
  });

  // Step 2: If no UTM parameters in the URL, check the cookie
  if (!hasUTMFromURL) {
    const cookieValue = Cookies.get('mpe_utm'); // Assuming UTM params are stored as JSON in the 'mpe_utm' cookie
    if (cookieValue) {
      try {
        const cookieUTMParams = JSON.parse(cookieValue);
        UTM_KEYS.forEach((key) => {
          if (cookieUTMParams[key]) {
            utmParams[key] = cookieUTMParams[key];
          }
        });
      } catch (error) {
        // NO OP
      }
    }
  }

  return utmParams;
};

const trackEvent = (
  eventName: string,
  properties: TrackEventProps,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback: () => void = () => {}
): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.analytics) {
    const utm_params = getUTMParams();
    const propertiesWithUTM = {
      ...properties,
      ...utm_params,
      ...getCompanyID(),
      // debug_mode: false, // keeping it here for future debugging
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.analytics.track(eventName, propertiesWithUTM, callback);
  }
};

const identifyUser = (
  userId: string | null,
  traits: IdentifyUserTraits,
  options?: IdentifyUserOptions
): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.analytics) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.analytics.identify(userId, traits, options);
  }
};

const pageView = (
  pageName: string,
  properties: PageViewProps,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback: () => void = () => {}
): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.analytics) {
    const utm_params = getUTMParams();
    const propertiesWithUTM = {
      ...properties,
      ...utm_params,
      ...getCompanyID(),
      // debug_mode: false, // keeping it here for future debugging
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.analytics.page(pageName, propertiesWithUTM, callback);
  }
};

const resetIdentifyUser = (): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.analytics) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.analytics.reset();
  }
};

export {
  trackEvent,
  identifyUser,
  pageView,
  resetIdentifyUser,
  initializeEventWrapper,
};
