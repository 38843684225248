/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react';
import { FlexTableContent } from '../RadioStationModal/RadioStationList/RadioStationList.styles';

interface RadioStationContentProps {
  scrollRef: boolean;
  resetScrollPage: boolean;
  hasNext: boolean;
  isLoading: boolean;
  children: React.ReactNode;
  getTableData: () => void;
}

// This will trigger dispatch in the 10th element
const mobileListScrollPositionInitialValue = -580;
// This will trigger dispatch every 10 elements
const mobileListScrollPositionInitialIncrementalValue = -1200;

const InfiniteTableScroll: React.FC<RadioStationContentProps> = ({
  scrollRef,
  resetScrollPage,
  isLoading,
  hasNext,
  children,
  getTableData,
}: RadioStationContentProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [mobileListScrollPosition, setMobileListScrollPosition] = useState(
    mobileListScrollPositionInitialValue
  );

  // Restart mobileListScrollPosition when modal is open
  useEffect(() => {
    if (mobileListScrollPosition !== mobileListScrollPositionInitialValue)
      setMobileListScrollPosition(mobileListScrollPositionInitialValue);
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = 0;
      containerRef.current?.scrollIntoView({
        block: 'start',
      });
    }
    setMobileListScrollPosition(mobileListScrollPositionInitialValue);
  }, [resetScrollPage]);

  // Triggers dispatch when mobileListScrollPosition changes
  useEffect(() => {
    // Do not trigger when open modal
    if (mobileListScrollPosition !== mobileListScrollPositionInitialValue)
      getTableData();
  }, [mobileListScrollPosition]);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = (): void => {
      if (container && !isLoading && hasNext) {
        const scrollPosition = container.scrollTop + container.clientHeight;
        const totalHeight = container.scrollHeight;
        const threshold = 5;
        const rect = container.getBoundingClientRect();
        const isAtTop = rect.top <= mobileListScrollPosition;

        if (scrollPosition >= totalHeight - threshold && !isAtTop) {
          getTableData();
        }

        if (isAtTop) {
          setMobileListScrollPosition(
            rect.top + mobileListScrollPositionInitialIncrementalValue
          );
        }
      }
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
      container.addEventListener('touchmove', handleScroll); // Listen to touchmove for mobile devices
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
        container.removeEventListener('touchmove', handleScroll); // Remove the touchmove listener
      }
    };
  }, [scrollRef, hasNext, isLoading]);

  return (
    <>
      {/* Table Content */}
      <FlexTableContent ref={containerRef}>{children}</FlexTableContent>
    </>
  );
};

export default InfiniteTableScroll;
