import styled from 'styled-components';
import { device } from 'src/styles/global';

export const NavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: ${({ theme }) => theme.background.grey};
  padding-bottom: 40px;
`;

export const LanguageMenu = styled.div`
  padding-top: 40px;
  padding-right: 32px;

  @media ${device.mobile} {
    padding-right: 16px;
  }
`;
