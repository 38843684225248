/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComboboxList,
  ComboboxPopover,
  ComboboxSearchInput,
  Checkbox,
} from '@dsny/dsny-component-library';
import React, { useEffect, useState } from 'react';
import theme from 'src/styles/theme';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import {
  ComboboxOptionWrapper,
  ComboboxWrapper,
  ItemWrapper,
} from './GenreSelection.styles';
import { getGenres } from '../RadioStationModal.thunks';
import { addSearchParams, resetFilters } from '../RadioStationModal.slice';

export interface ComboValueProps {
  description: string;
  subdescription?: string;
  id: string;
}

const GenreSelection: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({ id: 0, description: '' } as any);
  const [genres, setGenres] = useState<any[]>([]);
  const [genresSearch, setGenresSearch] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>(
    t('STATIONS_ALL_PLACEHOLDER')
  );

  const dispatch = useDispatch<AppDispatch>();
  const results = genresSearch;

  const { genre, searchParams, clearAllFilters } = useSelector(
    (state: RootState) => state.radioStationModal
  );

  useEffect(() => {
    dispatch(getGenres());
  }, []);

  // Handling findGenres
  const findGenres = (searchValue: string) => {
    const genresSearchTemp = genres.filter((g) =>
      g.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setGenresSearch(genresSearchTemp);
  };

  // Handling sortListByChecked
  const sortListByChecked = (genresList: any) => {
    return genresList.sort((a: any, b: any) => {
      if (a.isChecked && !b.isChecked) {
        return -1; // a comes before b
      }
      if (!a.isChecked && b.isChecked) {
        return 1; // b comes before a
      }
      // If isChecked values are the same, sort alphabetically
      return a.name.localeCompare(b.name);
    });
  };

  // Handling genre selection
  const onChange = (text: ComboValueProps) => {
    const textValue = typeof text === 'string' ? text : text.description;
    setValue({ description: textValue });
    findGenres(textValue);
  };

  const openCombobox = () => {
    setOpen(true);
  };

  const closeCombobox = () => {
    setOpen(false);
  };

  // Handling selectedValuesText
  const selectedValuesText = (genreList: any) => {
    const filteredGenres = genreList.filter((g: any) => g.isChecked !== '');

    if (filteredGenres.length) {
      const selectedItemText =
        filteredGenres.length > 1
          ? `${filteredGenres[0].name}...+${filteredGenres.length - 1}`
          : filteredGenres[0].name;

      setSearchText(selectedItemText);
    } else {
      setSearchText(t('STATIONS_ALL_PLACEHOLDER'));
    }
  };

  // Handling checkbox
  const handleCheckbox = (valueId: string) => {
    const newGenres = genres.map((g) => {
      if (g.name === valueId) {
        return { ...g, isChecked: g.isChecked ? '' : 'checkValue' };
      }
      return g;
    });

    setGenres(newGenres);
    setGenresSearch(sortListByChecked(newGenres));
    setValue({ id: 0, description: '' } as any);
    selectedValuesText(newGenres);
  };

  // Handling clearCheckbox
  const clearCheckbox = () => {
    const newGenres = genres.map((g) => {
      return { ...g, isChecked: '' };
    });

    setGenres(newGenres);
    setGenresSearch(sortListByChecked(newGenres));
    selectedValuesText(newGenres);
  };

  // Handling onChangeSelection
  const onChangeSelection = (text: ComboValueProps) => {
    handleCheckbox(text.description);
  };

  useEffect(() => {
    clearCheckbox();
    dispatch(resetFilters());
  }, [clearAllFilters]);

  useEffect(() => {
    if (genre.items && !genres.length) {
      setGenres(genre.items);
      setGenresSearch(genre.items);
    }
  }, [genre]);

  useEffect(() => {
    openCombobox();
  }, [value]);

  useEffect(() => {
    closeCombobox();
  }, []);

  const getGenreSearchList = (strings: string[]) => {
    return strings.join(',');
  };

  useEffect(() => {
    const genresList = genresSearch
      .filter((g: any) => g.isChecked !== '')
      .map((g: any) => g.name);
    if (genresList.length) {
      const genresSearchList = getGenreSearchList(genresList);
      dispatch(
        addSearchParams({
          ...searchParams,
          genre: genresSearchList,
          page: 1,
          hasNext: true,
        })
      );
    } else {
      dispatch(
        addSearchParams({
          ...searchParams,
          genre: '',
          page: 1,
          hasNext: true,
        })
      );
    }
  }, [genresSearch]);

  return (
    <>
      {/* Genre selection with seacrh - users can select more than one genre, selected genres will automatically move to the top of the list. */}
      <ComboboxWrapper
        isOpen={open}
        onClick={openCombobox}
        onClose={closeCombobox}
        onChange={onChangeSelection}
        label={t('STATIONS_GENRES_LABEL')}
        style={{
          width: '170px',
        }}
        value={value as any}
      >
        <ComboboxSearchInput
          placeholder={searchText}
          onChange={onChange}
          value={value.description}
          highlightcolor={theme.colors.mtr60}
          style={{
            color: theme.colors.neutralW40,
            position: 'inherit',
            height: '44px',
          }}
        />

        {results && (
          <ComboboxPopover
            style={{
              width: '218px',
              height: '216px',
              borderRadius: '5px',
              marginTop: '8px',
              borderColor: theme.colors.white,
              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
            }}
          >
            {results.length > 0 ? (
              <ComboboxList
                style={{ paddingTop: '16px', paddingBottom: '16px' }}
              >
                {results.map((result) => (
                  <ComboboxOptionWrapper
                    key={result.code}
                    value={{ description: result.name }}
                    selectedItemIndex={value.id}
                    style={{ paddingLeft: '16px' }}
                  >
                    <Checkbox
                      id="checkValue"
                      name="rememberMe"
                      data-cy="remember_me"
                      color={theme.colors.green}
                      value={result.isChecked}
                      checked={!!result.isChecked}
                      style={{
                        borderColor: theme.colors.neutralW70,
                        marginRight: '8px',
                        marginBottom: '4px',
                      }}
                    />
                    <ItemWrapper>{result.name}</ItemWrapper>
                  </ComboboxOptionWrapper>
                ))}
              </ComboboxList>
            ) : (
              <span
                style={{
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  paddingLeft: '16px',
                  color: theme.colors.neutralW40,
                  fontSize: '14px',
                }}
              >
                {t('STATIONS_NO_RESULT_FOUND')}
              </span>
            )}
          </ComboboxPopover>
        )}
      </ComboboxWrapper>
    </>
  );
};

export default GenreSelection;
