import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/services/network';

export const getHomeItems = createAsyncThunk('GET_HOME_ITEMS', async () => {
  return axios
    .get(
      'authorapi/staff/?filter=&sort=-modified&pagesize=15&page=1&needcount=true'
    )
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {};
    });
});

export default getHomeItems;
