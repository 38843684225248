import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import theme from 'src/styles/theme';
import { formatAmountWithoutRoundUp } from 'src/utils/Formatter';
import { PackageObj } from '../SelectPackage.slice';
import {
  MarkdownPrice,
  PackageBox,
  PackageContainer,
  PackageWrapper,
  PlanWrapper,
  RegularPrice,
  SelectButton,
  DiscountWrapper,
  SaveBox,
  SectionSeparator,
  TotalPriceWrapper,
} from '../SelectPackage.styles';

interface Props {
  packages: (PackageObj | undefined)[];
  discountPercentage: (discount: number) => number;
  getQuantity: () => number;
  selectPlanSubmit: (selectedPackage: PackageObj | undefined) => void;
}

const RegularPackages: React.FC<Props> = ({
  packages,
  discountPercentage,
  getQuantity,
  selectPlanSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <PackageWrapper>
      {packages.slice(0, 3).map((p) => {
        return (
          <PackageContainer key={p?.plan?.id}>
            <PackageBox>
              <PlanWrapper> {p?.plan?.name} </PlanWrapper>
              {p?.plan?.discount ? (
                <>
                  {/* With discount */}
                  {/* Period price per song - 1, 3 or 6 months */}
                  <RegularPrice>
                    ${formatAmountWithoutRoundUp(p?.summary?.unit_price)}
                  </RegularPrice>

                  <DiscountWrapper>
                    {/* Monthly price per song */}
                    <MarkdownPrice>
                      $
                      {formatAmountWithoutRoundUp(
                        Number(p?.summary?.unit_price) / p.plan.duration
                      )}
                      &nbsp;
                      {t('MULTI_ASSET_SONG_PRICE')}
                    </MarkdownPrice>

                    <SaveBox>
                      {t('SELECT_PACKAGE_SAVE')}{' '}
                      {`${discountPercentage(p?.plan?.discount)}%`}
                    </SaveBox>
                  </DiscountWrapper>
                  <SectionSeparator />

                  {/* Total price to pay for all songs */}
                  <TotalPriceWrapper>
                    <Trans
                      i18nKey="MULTI_ASSET_TOTAL_TO_PAY"
                      values={{
                        totalToPay: formatAmountWithoutRoundUp(
                          p?.summary?.subtotal_after_promo
                        ),
                        quantity: getQuantity(),
                      }}
                      components={{ bold: <strong /> }}
                    />
                  </TotalPriceWrapper>
                </>
              ) : (
                <>
                  {/* No discount */}
                  {/* Period price per song - 1, 3 or 6 months */}
                  <RegularPrice>${p?.plan?.price}</RegularPrice>
                  <DiscountWrapper>&nbsp;</DiscountWrapper>
                  <SectionSeparator />

                  {/* Total price to pay for all songs */}
                  <TotalPriceWrapper>
                    <Trans
                      i18nKey="MULTI_ASSET_TOTAL_TO_PAY"
                      values={{
                        totalToPay: formatAmountWithoutRoundUp(
                          p?.summary?.subtotal_after_promo
                        ),
                        quantity: getQuantity(),
                      }}
                      components={{ bold: <strong /> }}
                    />
                  </TotalPriceWrapper>
                </>
              )}

              {/* Select Plan Button */}
              <SelectButton
                setting="mtr"
                fontSize="14px"
                type="submit"
                onClick={() => selectPlanSubmit(p)}
                style={{
                  fontFamily: theme.fonts.primary,
                  height: '36px',
                  borderRadius: '5px',
                }}
              >
                {t('SELECT_PACKAGE_SELECT_BUTTON')}
              </SelectButton>
            </PackageBox>
          </PackageContainer>
        );
      })}
    </PackageWrapper>
  );
};

export default RegularPackages;
