import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialIcon, Pill, Tooltip } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { IconContainerStyles, PillStyles } from '../RadioStationModal.styles';
import { getStationListFile } from '../RadioStationModal.thunks';

// Until we refactor the entire modal, we will not display the export button for mobile viewing
const ExportStationList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { searchParams, totalStations } = useSelector((state: RootState) => ({
    radioStationTable:
      state.radioStationModal.radioStationList.radioStationTable,
    searchParams: state.radioStationModal.searchParams,
    totalStations:
      state.radioStationModal.radioStationList.radioStationTable
        ?.total_stations,
  }));

  // Handling export to download button
  const handleDownload = () => {
    dispatch(
      getStationListFile({ searchParams, pageSize: totalStations || 0 })
    );
  };

  return (
    <>
      {/* Export button  */}
      {totalStations && (
        <Tooltip
          text={t('STATIONS_EXPORT_LIST_TOOLTIP')}
          placement="top"
          color={theme.colors.white}
          backgroundColor={theme.colors.neutralW50}
          hasArrow={false}
          containerStyles={{
            display: 'block',
            width: '228px',
            height: '14px',
            whiteSpace: 'normal',
            fontFamily: theme.fonts.primary,
            fontSize: '12px',
            fontWeight: '700',
            lineHeight: '16px',
            borderRadius: '5px',
            textAlign: 'left',
            marginTop: '-8px',
          }}
        >
          <Pill
            label={t('STATIONS_EXPORT_LIST')}
            onClick={handleDownload}
            type="leading"
            iconPosition="tailing"
            icon={
              <MaterialIcon
                name="FileDownload"
                color={theme.colors.neutralW20}
              />
            }
            iconContainerStyles={{ ...IconContainerStyles }}
            style={{ ...PillStyles }}
          />
        </Tooltip>
      )}
    </>
  );
};

export default ExportStationList;
