export type Province = {
  index: number;
  id: string;
  description: string;
};

export type ProvincesData = {
  CA: Province[]; // Canadian provinces
  US: Province[]; // US states
};

export const provincesData: ProvincesData = {
  CA: [
    { index: 1, id: 'AB', description: 'Alberta' },
    { index: 2, id: 'BC', description: 'British Columbia' },
    { index: 3, id: 'MB', description: 'Manitoba' },
    { index: 4, id: 'NB', description: 'New Brunswick' },
    { index: 5, id: 'NL', description: 'Newfoundland' },
    { index: 6, id: 'NT', description: 'Northwest Territories' },
    { index: 7, id: 'NS', description: 'Nova Scotia' },
    { index: 8, id: 'NU', description: 'Nunavut' },
    { index: 9, id: 'ON', description: 'Ontario' },
    { index: 10, id: 'PE', description: 'Prince Edward Island' },
    { index: 11, id: 'QC', description: 'Quebec' },
    { index: 12, id: 'SK', description: 'Saskatchewan' },
    { index: 13, id: 'YT', description: 'Yukon' },
  ],
  US: [
    { index: 1, id: 'AL', description: 'Alabama' },
    { index: 2, id: 'AK', description: 'Alaska' },
    { index: 3, id: 'AS', description: 'American Samoa' },
    { index: 4, id: 'AZ', description: 'Arizona' },
    { index: 5, id: 'AR', description: 'Arkansas' },
    { index: 6, id: 'AA', description: 'Armed Forces Americas' },
    { index: 7, id: 'AE', description: 'Armed Forces' },
    { index: 8, id: 'AP', description: 'Armed Forces Pacific' },
    { index: 9, id: 'CA', description: 'California' },
    { index: 10, id: 'CO', description: 'Colorado' },
    { index: 11, id: 'CT', description: 'Connecticut' },
    { index: 12, id: 'DE', description: 'Delaware' },
    { index: 13, id: 'DC', description: 'District of Columbia' },
    { index: 14, id: 'FM', description: 'Federated States of Micronesia' },
    { index: 15, id: 'FL', description: 'Florida' },
    { index: 16, id: 'GA', description: 'Georgia' },
    { index: 17, id: 'GU', description: 'Guam' },
    { index: 18, id: 'HI', description: 'Hawaii' },
    { index: 19, id: 'ID', description: 'Idaho' },
    { index: 20, id: 'IL', description: 'Illinois' },
    { index: 21, id: 'IN', description: 'Indiana' },
    { index: 22, id: 'IA', description: 'Iowa' },
    { index: 23, id: 'KS', description: 'Kansas' },
    { index: 24, id: 'KY', description: 'Kentucky' },
    { index: 25, id: 'LA', description: 'Louisiana' },
    { index: 26, id: 'ME', description: 'Maine' },
    { index: 27, id: 'MH', description: 'Marshall Islands' },
    { index: 28, id: 'MD', description: 'Maryland' },
    { index: 29, id: 'MA', description: 'Massachusetts' },
    { index: 30, id: 'MI', description: 'Michigan' },
    { index: 31, id: 'MN', description: 'Minnesota' },
    { index: 32, id: 'MS', description: 'Mississippi' },
    { index: 33, id: 'MO', description: 'Missouri' },
    { index: 34, id: 'MT', description: 'Montana' },
    { index: 35, id: 'NE', description: 'Nebraska' },
    { index: 36, id: 'NV', description: 'Nevada' },
    { index: 37, id: 'NH', description: 'New Hampshire' },
    { index: 38, id: 'NJ', description: 'New Jersey' },
    { index: 39, id: 'NM', description: 'New Mexico' },
    { index: 40, id: 'NY', description: 'New York' },
    { index: 41, id: 'NC', description: 'North Carolina' },
    { index: 42, id: 'ND', description: 'North Dakota' },
    { index: 43, id: 'MP', description: 'Northern Mariana Islands' },
    { index: 44, id: 'OH', description: 'Ohio' },
    { index: 45, id: 'OK', description: 'Oklahoma' },
    { index: 46, id: 'OR', description: 'Oregon' },
    { index: 47, id: 'PA', description: 'Pennsylvania' },
    { index: 48, id: 'PR', description: 'Puerto Rico' },
    { index: 49, id: 'PW', description: 'Palau' },
    { index: 50, id: 'RI', description: 'Rhode Island' },
    { index: 51, id: 'SC', description: 'South Carolina' },
    { index: 52, id: 'SD', description: 'South Dakota' },
    { index: 53, id: 'TN', description: 'Tennessee' },
    { index: 54, id: 'TX', description: 'Texas' },
    { index: 55, id: 'UT', description: 'Utah' },
    { index: 56, id: 'VA', description: 'Virginia' },
    { index: 57, id: 'VI', description: 'Virgin Islands' },
    { index: 58, id: 'VT', description: 'Vermont' },
    { index: 59, id: 'WA', description: 'Washington' },
    { index: 60, id: 'WI', description: 'Wisconsin' },
    { index: 61, id: 'WV', description: 'West Virginia' },
  ],
};
