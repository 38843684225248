import styled from 'styled-components';
import { device } from 'src/styles/global';

export const DesktopWrapper = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  @media ${device.tablet} {
    display: none;
  }

  @media ${device.ipad} {
    display: none;
  }

  @media ${device.laptop} {
    display: none;
  }

  @media ${device.desktop} {
    display: none;
  }
`;
