/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@dsny/dsny-component-library';
import { promoPercentagOff } from 'src/utils/RegexPatterns';
import {
  DescPromoOff,
  DescPromoWrapper,
  FooterWrapper,
  HeadingWrapper,
  NotePromoWrapper,
  PromoPriceStyleButton,
} from './PromoPriceModal.styles';
import { PromotionData } from '../Dashboard';
import { trackEvent } from '../../utils/segmentService';

interface PromoPriceModalProps {
  promotionData: PromotionData;
  showPromoPriceModal: boolean;
  setShowPromoPriceModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PromoPriceModal = ({
  promotionData,
  showPromoPriceModal,
  setShowPromoPriceModal,
}: PromoPriceModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (showPromoPriceModal) {
      trackEvent('Modal View', { modal_type: 'promo_price_modal' });
    }
  }, [showPromoPriceModal]);

  const handleCancelModal = () => {
    setShowPromoPriceModal(false);
  };

  useEffect(() => {
    document.body.style.overflow = showPromoPriceModal ? 'hidden' : 'auto';
  }, [showPromoPriceModal]);

  const getFormattedTitle = (title: string) => {
    const match = promoPercentagOff.exec(title);
    if (!match) {
      return <HeadingWrapper>{title}</HeadingWrapper>;
    }

    const promoText = match[0];
    const [promoPart, restOfText] = title.split(promoText);
    return (
      <HeadingWrapper>
        {promoPart}
        <DescPromoOff>{promoText}</DescPromoOff>
        {restOfText}
      </HeadingWrapper>
    );
  };

  return (
    <Modal
      isOpen={showPromoPriceModal}
      onClose={() => setShowPromoPriceModal(false)}
      containerStyles={{
        display: 'inline-flex',
        width: '608px',
        height: 'fit-content',
      }}
    >
      {/* Heading */}
      {getFormattedTitle(promotionData.title)}

      {/* Body */}
      <DescPromoWrapper>{promotionData.description}</DescPromoWrapper>

      {!!promotionData.disclaimer && (
        <NotePromoWrapper>{promotionData.disclaimer}</NotePromoWrapper>
      )}

      {/* Footer */}
      <FooterWrapper className="footer">
        {/* Close button */}
        <PromoPriceStyleButton
          setting="mtr"
          variant="tertiary"
          type="button"
          onClick={handleCancelModal}
        >
          {t('PROMO_PRICE_NO_THANKS_CTA_BUTTON')}
        </PromoPriceStyleButton>
        {/* Clain button */}
        <PromoPriceStyleButton
          setting="mtr"
          type="button"
          onClick={() => navigate('/payment/multiasset')}
        >
          {t('PROMO_PRICE_CLAIM_OFFER_CTA_BUTTON')}
        </PromoPriceStyleButton>
      </FooterWrapper>
    </Modal>
  );
};

export default PromoPriceModal;
