/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MaterialIcon, Text } from '@dsny/dsny-component-library';
import React, { useEffect, useState } from 'react';
import theme from 'src/styles/theme';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { formatNumberAboveTenThousand } from 'src/utils/Formatter';
import { IconNames } from '@dsny/dsny-component-library/dist/components/MaterialIcon/MaterialIcon';
import { getComparisonBadgeStyle } from 'src/utils/ComparisonBadge';
import {
  ComparisonPreviousPeriodDesktop,
  ComparisonPreviousPeriodMobile,
  DashboardExportDataWrapper,
  DashboardSortButton,
  DashboardTitle,
  NoDataAvailableWrapper,
} from '../Dashboard.styles';
import {
  ComparisonBadge,
  FlexColumnLeft,
  FlexColumnLeftMobile,
  FlexColumnMiddle,
  FlexColumnRight,
  FlexInfoWrapper,
  FlexRowHeader,
  FlexRowInfo,
  HashtagWrapper,
  MobileTitleButton,
  MobileTitleWrapper,
  PlaysTextWrapper,
  SpinsInfoWrapper,
  StationInfoWrapper,
  StationsBox,
  StationsContainer,
  StationswWrapper,
  TableBodyWrapper,
  TitleText,
  TitleWrapper,
} from './SpinTracking.styles';
import { Spins } from '../Dashboard.slice';
import {
  getCitiesFile,
  getCitiesFileCompare,
  getStationsFile,
  getStationsFileCompare,
} from '../Dashboard.thunks';
import ExportData from '../ExportData/ExportData';

interface SpinTrackingProps {
  hasComparisonOn: boolean;
  title: string;
  description: string;
  spinData?: Spins[];
  spinDataCompare?: Spins[];
}

interface SortObj {
  order: number;
  UnfoldAscending: (stations: Spins[]) => Spins[];
  UnfoldDescending: (stations: Spins[]) => Spins[];
}

const unfoldDescendingPlaysFunction = (stations: Spins[]): Spins[] => {
  return stations?.slice().sort((a, b) => {
    if (a.count === b.count) {
      return b.difference - a.difference;
    }
    return b.count - a.count;
  });
};

const STATIONS_SELECION_KEY = 'STATIONS';
const COUNTRY_SELECION_KEY = 'COUNTRY';

const sortKeyList = ['Unfold', 'UnfoldAscending', 'UnfoldDescending'];
const LOCATION_MAP_KEY = 'location';
const PLAY_MAP_KEY = 'plays';
const NAMES_MAP_KEY = 'names';
const COUNTRY_MAP_KEY = 'country';

const sortingMap = new Map(
  Object.entries({
    location: {
      order: 0,
      Unfold: unfoldDescendingPlaysFunction,
      UnfoldAscending: (stations: Spins[]): Spins[] => {
        return stations
          ?.slice()
          .sort((a, b) => (a.location.city < b.location.city ? -1 : 1));
      },
      UnfoldDescending: (stations: Spins[]): Spins[] => {
        return stations
          ?.slice()
          .sort((a, b) => (a.location.city > b.location.city ? -1 : 1));
      },
    } as SortObj,
    plays: {
      order: 0,
      Unfold: unfoldDescendingPlaysFunction,
      UnfoldAscending: (stations: Spins[]): Spins[] => {
        return stations?.slice().sort((a, b) => {
          if (a.count === b.count) {
            return a.difference - b.difference;
          }
          return a.count - b.count;
        });
      },
      UnfoldDescending: unfoldDescendingPlaysFunction,
    } as SortObj,
    names: {
      order: 0,
      Unfold: unfoldDescendingPlaysFunction,
      UnfoldAscending: (stations: Spins[]): Spins[] => {
        return stations
          ?.slice()
          .sort((a, b) => (a?.station_name < b?.station_name ? -1 : 1));
      },
      UnfoldDescending: (stations: Spins[]): Spins[] => {
        return stations
          ?.slice()
          .sort((a, b) => (a?.station_name > b?.station_name ? -1 : 1));
      },
    } as SortObj,
    country: {
      order: 0,
      Unfold: unfoldDescendingPlaysFunction,
      UnfoldAscending: (stations: Spins[]): Spins[] => {
        return stations
          ?.slice()
          .sort((a, b) =>
            a?.location?.country < b?.location.country ? -1 : 1
          );
      },
      UnfoldDescending: (stations: Spins[]): Spins[] => {
        return stations
          ?.slice()
          .sort((a, b) =>
            a?.location?.country > b?.location?.country ? -1 : 1
          );
      },
    } as SortObj,
  })
);

const SpinTracking: React.FC<SpinTrackingProps> = ({
  hasComparisonOn,
  title,
  description,
  spinData,
  spinDataCompare,
}) => {
  const { t } = useTranslation();
  const [stations, setStations] = useState<Spins[]>();
  const [mobileSortSelection, setMobileSortSelection] = useState(
    STATIONS_SELECION_KEY
  );

  const [sortStation, setSortStation] =
    useState<Map<string, SortObj>>(sortingMap);

  const dispatch = useDispatch<AppDispatch>();

  const { songSelection, filterSelection } = useSelector(
    (state: RootState) => state.dashboard
  );

  const { selectedSong } = songSelection;

  // Get comparison badge
  const getComparisonBadge = (stationDifference: number) => {
    const { color, backgroundColor } =
      getComparisonBadgeStyle(stationDifference);

    return (
      <ComparisonBadge color={color} backgroundColor={backgroundColor}>
        {formatNumberAboveTenThousand(stationDifference, true)}
      </ComparisonBadge>
    );
  };

  useEffect(() => {
    const tempStations = hasComparisonOn ? spinDataCompare : spinData;
    setStations(tempStations);
  }, [spinDataCompare, spinData]);

  const sortStationList = (sortSelected: string) => {
    // Clone the current sortStation Map
    const updatedSortStation = new Map<string, SortObj>(sortStation);
    // Iterate through the cloned Map and update values
    updatedSortStation.forEach((value: SortObj, key: string) => {
      const sortStationTemp = updatedSortStation.get(key);
      if (sortStationTemp) {
        if (key === sortSelected) {
          sortStationTemp.order =
            sortStationTemp.order === 2 ? 0 : sortStationTemp.order + 1;
          if (stations) {
            sortStationTemp.UnfoldAscending(stations);
            const sortFunction = (sortStationTemp as SortObj)[
              sortKeyList[sortStationTemp.order] as keyof SortObj
            ];
            if (typeof sortFunction === 'function') {
              setStations(sortFunction(stations));
            }
          }
        } else {
          sortStationTemp.order = 0;
        }
        // Update the value in the cloned Map
        updatedSortStation.set(key, sortStationTemp);
      }
    });

    // Update the state with the cloned and updated Map
    setSortStation(updatedSortStation);
  };

  // Chanding sorting for mobile
  const handleMobileSortSelection = (sortKey: string) => {
    const key = sortKey;
    setMobileSortSelection(key);
  };

  // // Chanding sorting for mobile
  const handleMobileSortIcon = (sortByName: boolean) => {
    if (COUNTRY_SELECION_KEY === mobileSortSelection) {
      sortStationList(COUNTRY_MAP_KEY);
      return;
    }

    if (sortByName) {
      sortStationList(NAMES_MAP_KEY);
      return;
    }

    sortStationList(LOCATION_MAP_KEY);
  };

  // Chanding sorting for mobile
  const getMobileSortIcon = (sortByName: boolean) => {
    if (COUNTRY_SELECION_KEY === mobileSortSelection) {
      return COUNTRY_MAP_KEY;
    }

    if (sortByName) {
      return NAMES_MAP_KEY;
    }

    return LOCATION_MAP_KEY;
  };

  // Handling export data report for Cities
  const handleCitiesFile = () => {
    if (hasComparisonOn) {
      dispatch(
        getCitiesFileCompare({
          songId: selectedSong?.song_id,
          period: filterSelection.code,
          trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
        })
      );
    } else {
      dispatch(
        getCitiesFile({
          songId: selectedSong?.song_id,
          period: filterSelection.code,
          trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
        })
      );
    }
  };

  // Handling export data report for Statins
  const handleStationsFile = () => {
    if (hasComparisonOn) {
      dispatch(
        getStationsFileCompare({
          songId: selectedSong?.song_id,
          period: filterSelection.code,
          trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
        })
      );
    } else {
      dispatch(
        getStationsFile({
          songId: selectedSong?.song_id,
          period: filterSelection.code,
          trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
        })
      );
    }
  };

  return (
    <>
      {/* Stations and Cities Tracking */}
      <StationsContainer>
        <StationsBox>
          <DashboardTitle>{title}</DashboardTitle>
          <StationswWrapper>
            <Text
              fontSize="14px"
              fontWeight={400}
              color={theme.colors.neutralW40}
            >
              {description}
            </Text>

            <DashboardExportDataWrapper>
              {/* Export data */}
              <ExportData
                handleDownload={
                  stations && stations[0]?.station_name
                    ? handleStationsFile
                    : handleCitiesFile
                }
              />
            </DashboardExportDataWrapper>
          </StationswWrapper>

          {/* Table Header */}
          {!stations || stations?.length === 0 ? (
            // Empty state message
            <NoDataAvailableWrapper>
              <Text
                fontSize="16px"
                fontWeight={400}
                color={theme.colors.neutralW60}
              >
                {t('DASHBOARD_NO_DATA_AVAILABLE')}
              </Text>
            </NoDataAvailableWrapper>
          ) : (
            <>
              <FlexRowHeader>
                {/* Station and Country - Mobile View */}
                <FlexColumnLeftMobile>
                  <TitleWrapper>
                    <HashtagWrapper>#&nbsp;&nbsp;</HashtagWrapper>
                    <MobileTitleWrapper>
                      <MobileTitleButton
                        isMobileSortActive={
                          STATIONS_SELECION_KEY === mobileSortSelection
                        }
                        onClick={() =>
                          handleMobileSortSelection(STATIONS_SELECION_KEY)
                        }
                      >
                        {t('DASHBOARD_STATIONS_TRACKING_STATION')}
                      </MobileTitleButton>
                      <TitleText>
                        &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                      </TitleText>
                      <MobileTitleButton
                        isMobileSortActive={
                          COUNTRY_SELECION_KEY === mobileSortSelection
                        }
                        onClick={() =>
                          handleMobileSortSelection(COUNTRY_SELECION_KEY)
                        }
                      >
                        {t('DASHBOARD_COUNTRY_TRACKING_TITLE')}
                      </MobileTitleButton>
                    </MobileTitleWrapper>
                    <DashboardSortButton
                      onClick={() =>
                        handleMobileSortIcon(!!stations[0]?.station_name)
                      }
                    >
                      <MaterialIcon
                        name={
                          sortKeyList[
                            sortStation.get(
                              getMobileSortIcon(!!stations[0]?.station_name)
                            )?.order || 0
                          ] as keyof typeof IconNames
                        }
                      />
                    </DashboardSortButton>
                  </TitleWrapper>
                </FlexColumnLeftMobile>

                {/* Station */}
                <FlexColumnLeft>
                  <TitleWrapper>
                    <HashtagWrapper>#&nbsp;&nbsp;</HashtagWrapper>
                    <TitleText>
                      {t('DASHBOARD_STATIONS_TRACKING_STATION')}
                    </TitleText>
                    <DashboardSortButton
                      onClick={() =>
                        stations[0]?.station_name
                          ? sortStationList(NAMES_MAP_KEY)
                          : sortStationList(LOCATION_MAP_KEY)
                      }
                    >
                      {stations[0]?.station_name ? (
                        <MaterialIcon
                          name={
                            sortKeyList[
                              sortStation.get(NAMES_MAP_KEY)?.order || 0
                            ] as keyof typeof IconNames
                          }
                        />
                      ) : (
                        <MaterialIcon
                          name={
                            sortKeyList[
                              sortStation.get(LOCATION_MAP_KEY)?.order || 0
                            ] as keyof typeof IconNames
                          }
                        />
                      )}
                    </DashboardSortButton>
                  </TitleWrapper>
                </FlexColumnLeft>

                {/* Country */}
                <FlexColumnMiddle>
                  <TitleWrapper>
                    <TitleText>
                      {t('DASHBOARD_COUNTRY_TRACKING_TITLE')}
                    </TitleText>
                    <DashboardSortButton
                      onClick={() => sortStationList(COUNTRY_MAP_KEY)}
                    >
                      <MaterialIcon
                        name={
                          sortKeyList[
                            sortStation.get(COUNTRY_MAP_KEY)?.order || 0
                          ] as keyof typeof IconNames
                        }
                      />
                    </DashboardSortButton>
                  </TitleWrapper>
                </FlexColumnMiddle>

                {/* Spins */}
                <FlexColumnRight>
                  <TitleWrapper>
                    <TitleText>
                      {t('DASHBOARD_STATIONS_TRACKING_SPINS')}
                    </TitleText>
                    <DashboardSortButton
                      onClick={() => sortStationList(PLAY_MAP_KEY)}
                    >
                      <MaterialIcon
                        name={
                          sortKeyList[
                            sortStation.get(PLAY_MAP_KEY)?.order || 0
                          ] as keyof typeof IconNames
                        }
                      />
                    </DashboardSortButton>
                  </TitleWrapper>
                </FlexColumnRight>
              </FlexRowHeader>

              {/* Table Body */}
              <TableBodyWrapper>
                {stations?.length &&
                  stations?.map((station, index) => (
                    <FlexRowInfo key={station.location.city + index}>
                      {/* Station and Country - Mobile View */}
                      <FlexColumnLeftMobile>
                        <FlexInfoWrapper>
                          <Text
                            fontSize="14px"
                            fontWeight={400}
                            color={theme.colors.neutralW40}
                          >
                            {index + 1}
                          </Text>
                          <StationInfoWrapper>
                            <Text
                              fontSize="14px"
                              fontWeight={700}
                              color={theme.colors.neutralW40}
                            >
                              {station?.station_name}
                            </Text>
                            <Text
                              fontSize="14px"
                              fontWeight={400}
                              color={theme.colors.neutralW40}
                            >
                              {station.location.city},{' '}
                              {station.location.state_code},{' '}
                              {station.location.country}
                            </Text>
                          </StationInfoWrapper>
                        </FlexInfoWrapper>
                      </FlexColumnLeftMobile>

                      {/* Station */}
                      <FlexColumnLeft>
                        <FlexInfoWrapper>
                          <Text
                            fontSize="14px"
                            fontWeight={400}
                            color={theme.colors.neutralW40}
                          >
                            {index + 1}
                          </Text>
                          <StationInfoWrapper>
                            <Text
                              fontSize="14px"
                              fontWeight={700}
                              color={theme.colors.neutralW40}
                            >
                              {station?.station_name}
                            </Text>
                            <Text
                              fontSize="14px"
                              fontWeight={400}
                              color={theme.colors.neutralW40}
                            >
                              {station.location.city},{' '}
                              {station.location.state_code}
                            </Text>
                          </StationInfoWrapper>
                        </FlexInfoWrapper>
                      </FlexColumnLeft>

                      {/* Country */}
                      <FlexColumnMiddle>
                        <Text
                          fontSize="14px"
                          fontWeight={400}
                          color={theme.colors.neutralW40}
                        >
                          {station.location.country}
                        </Text>
                      </FlexColumnMiddle>

                      {/* Spins */}
                      <FlexColumnRight>
                        <SpinsInfoWrapper>
                          <Text
                            fontSize="14px"
                            fontWeight={700}
                            color={theme.colors.neutralW40}
                          >
                            {formatNumberAboveTenThousand(station.count)}
                          </Text>
                          <PlaysTextWrapper>
                            {station.count > 1
                              ? t('DASHBOARD_PLAYS')
                              : t('DASHBOARD_PLAY')}
                          </PlaysTextWrapper>

                          {/* Comparison mode ON */}
                          {hasComparisonOn &&
                            getComparisonBadge(station.difference)}
                        </SpinsInfoWrapper>

                        {/* Comparison mode ON */}
                        {hasComparisonOn && (
                          <>
                            <ComparisonPreviousPeriodDesktop>
                              <Trans
                                i18nKey="DASHBOARD_COMPARISON_PREVIOUS_PERIOD"
                                values={{
                                  number: formatNumberAboveTenThousand(
                                    station.previous_count
                                  ),
                                }}
                                components={{ bold: <strong /> }}
                              />
                            </ComparisonPreviousPeriodDesktop>
                            <ComparisonPreviousPeriodMobile>
                              <Trans
                                i18nKey="DASHBOARD_COMPARISON_PREVIOUS_PERIOD_MOBILE"
                                values={{ number: 390 }}
                                components={{ bold: <strong /> }}
                              />
                            </ComparisonPreviousPeriodMobile>
                          </>
                        )}
                      </FlexColumnRight>
                    </FlexRowInfo>
                  ))}
              </TableBodyWrapper>
            </>
          )}
        </StationsBox>
      </StationsContainer>
    </>
  );
};

export default SpinTracking;
