import {
  Checkbox,
  MaterialIcon,
  SpinningIcon,
  Text,
  Tooltip,
} from '@dsny/dsny-component-library';
import React, { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import theme from 'src/styles/theme';
import { formatTitleAndArtistWithVersion } from 'src/utils/Formatter';
import {
  BatchSelectionWrapper,
  SongSelectionWrapper,
  SongInfoWrapper,
  BatchDescWrapper,
  ReleaseSongItemWrapper,
  ReleaseSongTitle,
  ReleaseArtistName,
  IconWrapper,
  AlreadyTrackedIconWrapper,
} from './MultiSongSelection.styles';
import { AddTrackObj } from '../MultiAsset.slice';
import { ImportReleaseEditDeleteModal } from '../ImportRelease/ImportReleaseEditDeleteModal';

type MultiSongSelectionProps = {
  tracks: AddTrackObj[];
  handleTrackDelete: (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => void;
  handleSelect: (event: React.ChangeEvent<Element>, index: number) => void;
  handleActiveSelection: (index: number) => void;
  isCasterReleaseSong: (track: AddTrackObj) => boolean;
  handleSelectAllCheckbox: () => void;
  batchCheckbox?: string;
  getToolTipText: (track: AddTrackObj) => string;
  displayOnlyTracks: AddTrackObj[];
  showDeleteSongModal: boolean;
  trackIndex?: number;
  deleteEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>;
  setShowDeleteSongModal: Dispatch<SetStateAction<boolean>>;
  handleDeleteButton: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    track: AddTrackObj
  ) => void;
};

const MultiSongSelectionDefault: React.FC<MultiSongSelectionProps> = ({
  tracks,
  handleTrackDelete,
  handleSelect,
  handleActiveSelection,
  isCasterReleaseSong,
  handleSelectAllCheckbox,
  batchCheckbox,
  getToolTipText,
  displayOnlyTracks,
  showDeleteSongModal,
  trackIndex,
  deleteEvent,
  setShowDeleteSongModal,
  handleDeleteButton,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/* Multi Song Selection - Batch (Import Release from Caster) */}
      <BatchSelectionWrapper>
        <Checkbox
          id="checkValue"
          name="tracksToBackCheckbox"
          color={theme.colors.green}
          onChange={handleSelectAllCheckbox}
          value={batchCheckbox}
          checked={!!batchCheckbox}
          disabled={tracks.length <= 1}
          style={{
            borderColor: theme.colors.neutralW30,
            marginRight: '16px',
          }}
        />
        <BatchDescWrapper>
          <Text
            fontSize="14px"
            fontWeight={700}
            color={theme.colors.neutralW20}
          >
            <Trans
              i18nKey="MULTI_UPLOADED_SONGS"
              values={{ number: tracks.length }}
            />
          </Text>
          <Text
            fontSize="12px"
            fontWeight={400}
            color={theme.colors.neutralW20}
          >
            {t('MULTI_SELECT_MULTI_TRACKS_TO_BATCH')}
          </Text>
        </BatchDescWrapper>
      </BatchSelectionWrapper>

      {/* Song List */}
      {tracks.map((track, index) => {
        return (
          <Tooltip
            text={getToolTipText(track)}
            placement="top"
            color={theme.colors.white}
            backgroundColor={theme.colors.neutralW50}
            hasArrow={false}
            containerStyles={{
              display: 'block',
              width: '272px',
              minHeight: '5px',
              whiteSpace: 'wrap',
              fontFamily: theme.fonts.primary,
              fontSize: '12px',
              fontWeight: '700',
              lineHeight: 'normal',
              borderRadius: '5px',
              textAlign: 'left',
              marginTop: '12px',
              padding: '4px 8px',
            }}
          >
            <ReleaseSongItemWrapper
              key={track.S3File?.key}
              onClick={() => handleActiveSelection(index)}
              $isActive={track.isActive}
              $isSelected={!!track.isSelected}
              $hasError={track.isFileUploadError}
              $alreadyTrackedSongs={false}
            >
              <SongSelectionWrapper>
                <Checkbox
                  id="itemCheckValue"
                  name="itemCheckValue"
                  color={theme.colors.green}
                  onChange={(event) => handleSelect(event, index)}
                  value={track.isSelected}
                  checked={!!track.isSelected}
                  disabled={tracks.length <= 1}
                  style={{
                    borderColor: theme.colors.neutralW30,
                    marginRight: '16px',
                  }}
                />
                <SongInfoWrapper>
                  <ReleaseSongTitle
                    $isActive={track.isActive}
                    $isSelected={!!track.isSelected}
                    $hasError={track.isFileUploadError}
                    $alreadyTrackedSongs={false}
                  >
                    {isCasterReleaseSong(track)
                      ? formatTitleAndArtistWithVersion(track)
                      : track?.title}
                  </ReleaseSongTitle>
                  {!track.isUpLoading ? (
                    <ReleaseArtistName
                      $isActive={track.isActive}
                      $isSelected={!!track.isSelected}
                      $hasError={track.isFileUploadError}
                      $alreadyTrackedSongs={false}
                    >
                      {track?.artist.trim() ||
                        t('MULTI_SELECT_PLEASE_ADD_ARTIST_NAME')}
                    </ReleaseArtistName>
                  ) : (
                    <ReleaseArtistName
                      $isActive={track.isActive}
                      $isSelected={!!track.isSelected}
                      $hasError={track.isFileUploadError}
                      $alreadyTrackedSongs={false}
                    >
                      {t('MULTI_SELECT_PROCESSING')}
                    </ReleaseArtistName>
                  )}
                </SongInfoWrapper>
              </SongSelectionWrapper>

              {track.isUpLoading ? (
                <SpinningIcon style={{ marginTop: '2px' }}>
                  <MaterialIcon
                    name="Loading"
                    color={theme.colors.neutralW40}
                  />
                </SpinningIcon>
              ) : (
                <IconWrapper
                  onClick={(event) => handleDeleteButton(event, index, track)}
                >
                  <MaterialIcon name="Trash" color={theme.colors.neutralW40} />
                </IconWrapper>
              )}
            </ReleaseSongItemWrapper>
          </Tooltip>
        );
      })}

      {/* Caster already monitored songs */}
      {displayOnlyTracks.map((track) => {
        return (
          <Tooltip
            text={t('MULTI_ASSET_CASTER_RELEASE_ALREADY_TRACKED', {
              artist: track.releaseTrack?.artist,
              title: track.releaseTrack?.title || '',
              version: track.releaseTrack?.version || '',
            })}
            placement="top"
            color={theme.colors.white}
            backgroundColor={theme.colors.neutralW50}
            hasArrow={false}
            containerStyles={{
              display: 'block',
              width: '272px',
              minHeight: '5px',
              whiteSpace: 'wrap',
              fontFamily: theme.fonts.primary,
              fontSize: '12px',
              fontWeight: '700',
              lineHeight: 'normal',
              borderRadius: '5px',
              textAlign: 'left',
              marginTop: '12px',
              padding: '4px 8px',
            }}
          >
            <ReleaseSongItemWrapper
              key={track.S3File?.key}
              $isActive={track.isActive}
              $isSelected={!!track.isSelected}
              $hasError={track.isFileUploadError}
              $alreadyTrackedSongs
            >
              <SongSelectionWrapper>
                <Checkbox
                  id="itemCheckValue"
                  name="itemCheckValue"
                  color={theme.colors.green}
                  value={track.isSelected}
                  checked={!!track.isSelected}
                  disabled
                  style={{
                    borderColor: theme.colors.neutralW30,
                    marginRight: '16px',
                  }}
                />

                <SongInfoWrapper>
                  <ReleaseSongTitle
                    $isActive={track.isActive}
                    $isSelected={!!track.isSelected}
                    $hasError={track.isFileUploadError}
                    $alreadyTrackedSongs
                  >
                    {`${track?.title} (${track.releaseTrack?.version})`}
                  </ReleaseSongTitle>
                  {!track.isUpLoading ? (
                    <ReleaseArtistName
                      $isActive={track.isActive}
                      $isSelected={!!track.isSelected}
                      $hasError={track.isFileUploadError}
                      $alreadyTrackedSongs
                    >
                      {track?.artist.trim() ||
                        t('MULTI_SELECT_PLEASE_ADD_ARTIST_NAME')}
                    </ReleaseArtistName>
                  ) : (
                    <ReleaseArtistName
                      $isActive={track.isActive}
                      $isSelected={!!track.isSelected}
                      $hasError={track.isFileUploadError}
                      $alreadyTrackedSongs
                    >
                      {t('MULTI_SELECT_PROCESSING')}
                    </ReleaseArtistName>
                  )}
                </SongInfoWrapper>
              </SongSelectionWrapper>

              {track.isUpLoading ? (
                <SpinningIcon style={{ marginTop: '2px' }}>
                  <MaterialIcon
                    name="Loading"
                    color={theme.colors.neutralW40}
                  />
                </SpinningIcon>
              ) : (
                <AlreadyTrackedIconWrapper>
                  <MaterialIcon name="Trash" color={theme.colors.neutralW80} />
                </AlreadyTrackedIconWrapper>
              )}
            </ReleaseSongItemWrapper>
          </Tooltip>
        );
      })}

      {/* Modal */}
      {/* 2. Caster release delete song modal */}
      <ImportReleaseEditDeleteModal
        show={showDeleteSongModal}
        trackIndex={trackIndex}
        deleteEvent={deleteEvent}
        setShowDeleteSongModal={setShowDeleteSongModal}
        handleTrackDelete={handleTrackDelete}
      />
    </>
  );
};

export default MultiSongSelectionDefault;
