export const countriesData = [
  {
    index: 1,
    id: 'AF',
    description: 'Afghanistan',
  },
  {
    index: 2,
    id: 'AL',
    description: 'Albania',
  },
  {
    index: 3,
    id: 'DZ',
    description: 'Algeria',
  },
  {
    index: 4,
    id: 'AD',
    description: 'Andorra',
  },
  {
    index: 5,
    id: 'AO',
    description: 'Angola',
  },
  {
    index: 6,
    id: 'AG',
    description: 'Antigua and Barbuda',
  },
  {
    index: 7,
    id: 'AR',
    description: 'Argentina',
  },
  {
    index: 8,
    id: 'AM',
    description: 'Armenia',
  },
  {
    index: 9,
    id: 'AU',
    description: 'Australia',
  },
  {
    index: 10,
    id: 'AT',
    description: 'Austria',
  },
  {
    index: 11,
    id: 'AZ',
    description: 'Azerbaijan',
  },
  {
    index: 12,
    id: 'BS',
    description: 'Bahamas',
  },
  {
    index: 13,
    id: 'BH',
    description: 'Bahrain',
  },
  {
    index: 14,
    id: 'BD',
    description: 'Bangladesh',
  },
  {
    index: 15,
    id: 'BB',
    description: 'Barbados',
  },
  {
    index: 16,
    id: 'BY',
    description: 'Belarus',
  },
  {
    index: 17,
    id: 'BE',
    description: 'Belgium',
  },
  {
    index: 18,
    id: 'BZ',
    description: 'Belize',
  },
  {
    index: 19,
    id: 'BJ',
    description: 'Benin',
  },
  {
    index: 20,
    id: 'BT',
    description: 'Bhutan',
  },
  {
    index: 21,
    id: 'BO',
    description: 'Bolivia',
  },
  {
    index: 22,
    id: 'BA',
    description: 'Bosnia and Herzegovina',
  },
  {
    index: 23,
    id: 'BW',
    description: 'Botswana',
  },
  {
    index: 24,
    id: 'BR',
    description: 'Brazil',
  },
  {
    index: 25,
    id: 'BN',
    description: 'Brunei Darussalam',
  },
  {
    index: 26,
    id: 'BG',
    description: 'Bulgaria',
  },
  {
    index: 27,
    id: 'BF',
    description: 'Burkina Faso',
  },
  {
    index: 28,
    id: 'BI',
    description: 'Burundi',
  },
  {
    index: 29,
    id: 'CV',
    description: 'Cape Verde',
  },
  {
    index: 30,
    id: 'KH',
    description: 'Cambodia',
  },
  {
    index: 31,
    id: 'CM',
    description: 'Cameroon',
  },
  {
    index: 32,
    id: 'CA',
    description: 'Canada',
  },
  {
    index: 33,
    id: 'CF',
    description: 'Central African Republic',
  },
  {
    index: 34,
    id: 'TD',
    description: 'Chad',
  },
  {
    index: 35,
    id: 'CL',
    description: 'Chile',
  },
  {
    index: 36,
    id: 'CN',
    description: 'China',
  },
  {
    index: 37,
    id: 'CO',
    description: 'Colombia',
  },
  {
    index: 38,
    id: 'KM',
    description: 'Comoros',
  },
  {
    index: 39,
    id: 'CG',
    description: 'Congo',
  },
  {
    index: 40,
    id: 'CR',
    description: 'Costa Rica',
  },
  {
    index: 41,
    id: 'CI',
    description: "Côte d'Ivoire",
  },
  {
    index: 42,
    id: 'HR',
    description: 'Croatia',
  },
  {
    index: 43,
    id: 'CU',
    description: 'Cuba',
  },
  {
    index: 44,
    id: 'CY',
    description: 'Cyprus',
  },
  {
    index: 45,
    id: 'CZ',
    description: 'Czechia',
  },
  {
    index: 46,
    id: 'KP',
    description: "Korea, Democratic People's Republic of",
  },
  {
    index: 47,
    id: 'CD',
    description: 'Congo, the Democratic Republic of the',
  },
  {
    index: 48,
    id: 'DK',
    description: 'Denmark',
  },
  {
    index: 49,
    id: 'DJ',
    description: 'Djibouti',
  },
  {
    index: 50,
    id: 'DM',
    description: 'Dominica',
  },
  {
    index: 51,
    id: 'DO',
    description: 'Dominican Republic',
  },
  {
    index: 52,
    id: 'EC',
    description: 'Ecuador',
  },
  {
    index: 53,
    id: 'EG',
    description: 'Egypt',
  },
  {
    index: 54,
    id: 'SV',
    description: 'El Salvador',
  },
  {
    index: 55,
    id: 'GQ',
    description: 'Equatorial Guinea',
  },
  {
    index: 56,
    id: 'ER',
    description: 'Eritrea',
  },
  {
    index: 57,
    id: 'EE',
    description: 'Estonia',
  },
  {
    index: 58,
    id: 'ET',
    description: 'Ethiopia',
  },
  {
    index: 59,
    id: 'FJ',
    description: 'Fiji',
  },
  {
    index: 60,
    id: 'FI',
    description: 'Finland',
  },
  {
    index: 61,
    id: 'FR',
    description: 'France',
  },
  {
    index: 62,
    id: 'GA',
    description: 'Gabon',
  },
  {
    index: 63,
    id: 'GM',
    description: 'Gambia',
  },
  {
    index: 64,
    id: 'GE',
    description: 'Georgia',
  },
  {
    index: 65,
    id: 'DE',
    description: 'Germany',
  },
  {
    index: 66,
    id: 'GH',
    description: 'Ghana',
  },
  {
    index: 67,
    id: 'GR',
    description: 'Greece',
  },
  {
    index: 68,
    id: 'GD',
    description: 'Grenada',
  },
  {
    index: 69,
    id: 'GT',
    description: 'Guatemala',
  },
  {
    index: 70,
    id: 'GN',
    description: 'Guinea',
  },
  {
    index: 71,
    id: 'GW',
    description: 'Guinea-Bissau',
  },
  {
    index: 72,
    id: 'GY',
    description: 'Guyana',
  },
  {
    index: 73,
    id: 'HT',
    description: 'Haiti',
  },
  {
    index: 74,
    id: 'HN',
    description: 'Honduras',
  },
  {
    index: 75,
    id: 'HU',
    description: 'Hungary',
  },
  {
    index: 76,
    id: 'IS',
    description: 'Iceland',
  },
  {
    index: 77,
    id: 'IN',
    description: 'India',
  },
  {
    index: 78,
    id: 'ID',
    description: 'Indonesia',
  },
  {
    index: 79,
    id: 'IR',
    description: 'Iran',
  },
  {
    index: 80,
    id: 'IQ',
    description: 'Iraq',
  },
  {
    index: 81,
    id: 'IE',
    description: 'Ireland',
  },
  {
    index: 82,
    id: 'IL',
    description: 'Israel',
  },
  {
    index: 83,
    id: 'IT',
    description: 'Italy',
  },
  {
    index: 84,
    id: 'JM',
    description: 'Jamaica',
  },
  {
    index: 85,
    id: 'JP',
    description: 'Japan',
  },
  {
    index: 86,
    id: 'JO',
    description: 'Jordan',
  },
  {
    index: 87,
    id: 'KZ',
    description: 'Kazakhstan',
  },
  {
    index: 88,
    id: 'KE',
    description: 'Kenya',
  },
  {
    index: 89,
    id: 'KI',
    description: 'Kiribati',
  },
  {
    index: 90,
    id: 'KW',
    description: 'Kuwait',
  },
  {
    index: 91,
    id: 'KG',
    description: 'Kyrgyzstan',
  },
  {
    index: 92,
    id: 'LA',
    description: "Lao People's Democratic Republic",
  },
  {
    index: 93,
    id: 'LV',
    description: 'Latvia',
  },
  {
    index: 94,
    id: 'LB',
    description: 'Lebanon',
  },
  {
    index: 95,
    id: 'LS',
    description: 'Lesotho',
  },
  {
    index: 96,
    id: 'LR',
    description: 'Liberia',
  },
  {
    index: 97,
    id: 'LY',
    description: 'Libya',
  },
  {
    index: 98,
    id: 'LI',
    description: 'Liechtenstein',
  },
  {
    index: 99,
    id: 'LT',
    description: 'Lithuania',
  },
  {
    index: 100,
    id: 'LU',
    description: 'Luxembourg',
  },
  {
    index: 101,
    id: 'MG',
    description: 'Madagascar',
  },
  {
    index: 102,
    id: 'MW',
    description: 'Malawi',
  },
  {
    index: 103,
    id: 'MY',
    description: 'Malaysia',
  },
  {
    index: 104,
    id: 'MV',
    description: 'Maldives',
  },
  {
    index: 105,
    id: 'ML',
    description: 'Mali',
  },
  {
    index: 106,
    id: 'MT',
    description: 'Malta',
  },
  {
    index: 107,
    id: 'MH',
    description: 'Marshall Islands',
  },
  {
    index: 108,
    id: 'MR',
    description: 'Mauritania',
  },
  {
    index: 109,
    id: 'MU',
    description: 'Mauritius',
  },
  {
    index: 110,
    id: 'MX',
    description: 'Mexico',
  },
  {
    index: 111,
    id: 'KR',
    description: 'Korea, Republic of',
  },
  {
    index: 112,
    id: 'PS',
    description: 'Palestine, State of',
  },
  {
    index: 113,
    id: 'RO',
    description: 'Romania',
  },
  {
    index: 114,
    id: 'RU',
    description: 'Russian Federation',
  },
  {
    index: 115,
    id: 'RW',
    description: 'Rwanda',
  },
  {
    index: 116,
    id: 'KN',
    description: 'Saint Kitts and Nevis',
  },
  {
    index: 117,
    id: 'LC',
    description: 'Saint Lucia',
  },
  {
    index: 118,
    id: 'VC',
    description: 'Saint Vincent and the Grenadines',
  },
  {
    index: 119,
    id: 'WS',
    description: 'Samoa',
  },
  {
    index: 120,
    id: 'SM',
    description: 'San Marino',
  },
  {
    index: 121,
    id: 'ST',
    description: 'Sao Tome and Principe',
  },
  {
    index: 122,
    id: 'SA',
    description: 'Saudi Arabia',
  },
  {
    index: 123,
    id: 'SN',
    description: 'Senegal',
  },
  {
    index: 124,
    id: 'RS',
    description: 'Serbia',
  },
  {
    index: 125,
    id: 'SC',
    description: 'Seychelles',
  },
  {
    index: 126,
    id: 'SL',
    description: 'Sierra Leone',
  },
  {
    index: 127,
    id: 'SG',
    description: 'Singapore',
  },
  {
    index: 128,
    id: 'SK',
    description: 'Slovakia',
  },
  {
    index: 129,
    id: 'SI',
    description: 'Slovenia',
  },
  {
    index: 130,
    id: 'SB',
    description: 'Solomon Islands',
  },
  {
    index: 131,
    id: 'SO',
    description: 'Somalia',
  },
  {
    index: 132,
    id: 'ZA',
    description: 'South Africa',
  },
  {
    index: 133,
    id: 'ES',
    description: 'Spain',
  },
  {
    index: 134,
    id: 'LK',
    description: 'Sri Lanka',
  },
  {
    index: 135,
    id: 'SD',
    description: 'Sudan',
  },
  {
    index: 136,
    id: 'SR',
    description: 'Suriname',
  },
  {
    index: 137,
    id: 'SE',
    description: 'Sweden',
  },
  {
    index: 138,
    id: 'CH',
    description: 'Switzerland',
  },
  {
    index: 139,
    id: 'SY',
    description: 'Syrian Arab Republic',
  },
  {
    index: 140,
    id: 'TJ',
    description: 'Tajikistan',
  },
  {
    index: 141,
    id: 'TZ',
    description: 'Tanzania, United Republic of',
  },
  {
    index: 142,
    id: 'TH',
    description: 'Thailand',
  },
  {
    index: 143,
    id: 'TL',
    description: 'Timor-Leste',
  },
  {
    index: 144,
    id: 'TG',
    description: 'Togo',
  },
  {
    index: 145,
    id: 'TO',
    description: 'Tonga',
  },
  {
    index: 146,
    id: 'TT',
    description: 'Trinidad and Tobago',
  },
  {
    index: 147,
    id: 'TN',
    description: 'Tunisia',
  },
  {
    index: 148,
    id: 'TR',
    description: 'Turkey',
  },
  {
    index: 149,
    id: 'TM',
    description: 'Turkmenistan',
  },
  {
    index: 150,
    id: 'TV',
    description: 'Tuvalu',
  },
  {
    index: 151,
    id: 'UG',
    description: 'Uganda',
  },
  {
    index: 152,
    id: 'UA',
    description: 'Ukraine',
  },
  {
    index: 153,
    id: 'AE',
    description: 'United Arab Emirates',
  },
  {
    index: 154,
    id: 'GB',
    description: 'United Kingdom',
  },
  {
    index: 155,
    id: 'US',
    description: 'United States',
  },
  {
    index: 156,
    id: 'UY',
    description: 'Uruguay',
  },
  {
    index: 157,
    id: 'UZ',
    description: 'Uzbekistan',
  },
  {
    index: 158,
    id: 'VU',
    description: 'Vanuatu',
  },
  {
    index: 159,
    id: 'VE',
    description: 'Venezuela, Bolivarian Republic of',
  },
  {
    index: 160,
    id: 'VN',
    description: 'Viet Nam',
  },
  {
    index: 161,
    id: 'YE',
    description: 'Yemen',
  },
  {
    index: 162,
    id: 'ZM',
    description: 'Zambia',
  },
  {
    index: 163,
    id: 'ZW',
    description: 'Zimbabwe',
  },
];
