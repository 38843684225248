import styled from 'styled-components';
import { device } from 'src/styles/global';

interface Props {
  size: number;
}

export const TimelineContainer = styled.div`
  width: 100%;
`;

export const LineChartBox = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  @media ${device.mobile} {
    overflow-x: scroll;
  }

  @media ${device.tablet} {
    overflow-x: scroll;
  }

  @media ${device.ipad} {
    overflow-x: scroll;
  }
`;

export const LineChart = styled.div<Props>`
  height: 420px;
  min-width: ${({ size }) => `${size * 120}px`};
  margin-bottom: 10px;
`;

export const ComparisonLineChart = styled.div<Props>`
  height: 540px;
  min-width: ${({ size }) => `${size * 120}px`};
  margin-bottom: 10px;
`;
