import styled from 'styled-components';
import theme from 'src/styles/theme';
import { ComboboxOption } from '@dsny/dsny-component-library';
import { device } from 'src/styles/global';

interface Props {
  selectedItemIndex?: number;
  $inlineMessage?: boolean;
}

export const ComboboxWrapper = styled.div`
  width: 100%;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const ComboboxStyles = {
  width: '100%',
  paddingBottom: '32px',
};

export const ComboboxOptionWrapper = styled(ComboboxOption)<Props>`
  &:hover {
    background: ${theme.colors.neutralW100};
  }

  &:nth-child(${({ selectedItemIndex }) => selectedItemIndex}) {
    background: ${theme.colors.mtr100};
  }
`;

export const ItemWrapper = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW40};
`;

export const InlineMessage = styled.p<Props>`
  color: ${theme.colors.error50};
  font-size: 12px;
  max-height: ${({ $inlineMessage }) => ($inlineMessage ? '20px' : '0')};
  transition: ${({ $inlineMessage }) =>
    $inlineMessage ? 'max-height 0.25s ease-in' : 'max-height 0.15s ease-out'};
  overflow: hidden;
  margin-top: 8px;
`;
