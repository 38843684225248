import styled from 'styled-components';
import theme from 'src/styles/theme';
import { Combobox, ComboboxOption } from '@dsny/dsny-component-library';
import { device } from 'src/styles/global';

interface Props {
  selectedItemIndex: number;
}

export const ComboboxWrapper = styled(Combobox)`
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const ComboboxOptionWrapper = styled(ComboboxOption)<Props>`
  &:hover {
    background: ${theme.colors.neutralW100};
  }

  &:nth-child(${({ selectedItemIndex }) => selectedItemIndex}) {
    background: ${theme.colors.mtr100};
  }
`;

export const ItemWrapper = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW40};
`;
