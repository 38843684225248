export const BASE_SITE_URL = 'https://www.plaympe.com';

export const BASE_SITE_PRIVACY_SECTION = 'https://www.plaympe.com/privacy/';

export const BASE_SITE_TERMS_OF_SERVICE =
  'https://musicmtr.com/terms-of-service/';

export const CUSTOMER_SUPPORT_EMAIL = 'info@musicmtr.com';

export const CUSTOMER_ZOHO_FORMS = 'https://zfrmz.com/h0ETsaw5WywsbwaUpBKG';
