import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubTitle, Title } from './StepOneTitle.styles';

const StepOneTitle: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Step-one title and subtitles */}
      <Title>{t('MULTI_ASSET_TITLE')}</Title>
      <SubTitle>{t('MULTI_ASSET_SUBTITLE')}</SubTitle>
    </>
  );
};

export default StepOneTitle;
