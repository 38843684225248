import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BASE_SITE_URL,
  BASE_SITE_PRIVACY_SECTION,
  CUSTOMER_SUPPORT_EMAIL,
} from 'src/utils/Urls';
import { Container, SupportLink, FooterWraper } from './Footer.styles';

const FooterPublic: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <FooterWraper>
        {/* Support link */}
        <SupportLink
          href={`mailto:${CUSTOMER_SUPPORT_EMAIL}`}
          target="_blank"
          rel="noreferrer"
        >
          {t('FOOTER_LINK_SUPPORT')}
        </SupportLink>

        {/* Privacy link */}
        <SupportLink
          href={BASE_SITE_PRIVACY_SECTION}
          target="_blank"
          rel="noreferrer"
        >
          {t('FOOTER_LINK_PRIVACY')}
        </SupportLink>

        {/* Company link */}
        <SupportLink href={BASE_SITE_URL} target="_blank" rel="noreferrer">
          {t('FOOTER_LINK_COMPANY')}
        </SupportLink>
      </FooterWraper>
    </Container>
  );
};

export default FooterPublic;
