import styled from 'styled-components';
import { Button } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { device } from 'src/styles/global';

export const HeadingImportReleaseModal = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: ${theme.colors.neutralW20};
  margin-bottom: 8px;
`;

export const TextImportReleaseModal = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW40};
  margin-bottom: 24px;
`;

export const SubTextImportReleaseModal = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW40};
  margin-bottom: 32px;

  @media ${device.mobile} {
    margin-bottom: 24px;
  }
`;

export const ReleaseImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 32px;

  @media ${device.mobile} {
    margin-bottom: 24px;
  }
`;

export const FlexButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

export const ButtonStyleWrapper = styled(Button)`
  width: 'fit-content';
  height: 36px;
  border-radius: 5px;
`;
