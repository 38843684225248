import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { formatTextForSentryLog } from 'src/utils/Formatter';
import StripeCheckoutForm from './StripeCheckoutForm';

const AddPaymentWrapper: React.FC = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!)
    .then((stripe) => {
      Sentry.captureMessage('[MTR] Stripe loaded successfully');
      return stripe;
    })
    .catch((error) => {
      Sentry.captureException(formatTextForSentryLog('Stripe fail to load'));
      return error;
    });

  return (
    <Elements stripe={stripePromise}>
      <StripeCheckoutForm />
    </Elements>
  );
};

export default AddPaymentWrapper;
