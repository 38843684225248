import { combineReducers } from '@reduxjs/toolkit';
import { reducer as home } from '../features/Home';
import { reducer as auth } from '../features/Auth';
import { reducer as multiAsset } from '../features/Purchase/MultiAsset';
import { reducer as selectPackage } from '../features/Purchase/SelectPackage';
import { reducer as addPayment } from '../features/Purchase/AddPayment';
import { reducer as dashboard } from '../features/Dashboard';
import { reducer as radioStationModal } from '../features/RadioStationModal';

const rootReducer = {
  home,
  auth,
  multiAsset,
  selectPackage,
  addPayment,
  dashboard,
  radioStationModal,
};

export default combineReducers(rootReducer);
