/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import AWS from 'aws-sdk';

AWS.config.region = process.env.REACT_APP_REGION;

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID!,
});

export const uploadFileToS3 = (
  file: File,
  key: string,
  fileId: string,
  onFinish: (err: any) => void
) => {
  const s3Bucket = new AWS.S3({
    params: {
      Bucket: process.env.REACT_APP_BUCKET,
    },
    httpOptions: { timeout: 0 },
  });

  const customHeaders = {
    originalfilename: btoa(encodeURIComponent(file.name)),
  };

  const params = {
    Bucket: process.env.REACT_APP_BUCKET!,
    Key: key,
    ContentType: file.type,
    Body: file,
    ACL: 'public-read',
    Metadata: customHeaders,
  };

  const options = {
    partSize: 5 * 1024 * 1024,
    queueSize: 1,
  };

  const uploadRequest = s3Bucket.upload(params, options);

  uploadRequest.send((err) => {
    onFinish(err);
  });
};

export const getArtworkuuid = (url: string) => {
  return url.split('coverart/')[1];
};
