import {
  MaterialIcon,
  UploadButton,
  Uploader,
} from '@dsny/dsny-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'src/styles/theme';
import { IconNames } from '@dsny/dsny-component-library/dist/components/MaterialIcon/MaterialIcon';
import {
  UploaderDescription,
  UploaderMainTitle,
  UploaderSubheading,
} from './AddSongFiles.styles';

type AddSongFilesProps = {
  onUpload: (uploadFiles: FileList) => void;
};

const AddSongFiles: React.FC<AddSongFilesProps> = ({ onUpload }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Add Song Files - Uploader */}
      <Uploader
        isActive
        onUpload={onUpload}
        accept="*"
        style={{
          backgroundColor: theme.colors.neutralC100,
          borderRadius: '5px',
          textAlign: 'center',
          padding: '16px',
          border: '1px dashed #C9C9C9',
          height: '224px',
        }}
      >
        <MaterialIcon name={'Upload' as keyof typeof IconNames} />
        <UploaderMainTitle>
          {t('MULTI_ASSET_ADD_MUSIC_FILES')}
        </UploaderMainTitle>

        <UploaderSubheading>{t('ADD_TRACK_DRAG_AND_DROP')}</UploaderSubheading>
        <UploadButton
          type="button"
          style={{
            fontSize: '14px',
            fontWeight: '700',
            paddingLeft: '4px',
            color: theme.colors.mtr60,
            textDecoration: 'underline',
          }}
        >
          {t('ADD_TRACK_UPLOAD_BUTTON')}
        </UploadButton>
        <UploaderDescription>
          {t('ADD_TRACK_SUPPORTED_FORMATS')}
        </UploaderDescription>
      </Uploader>
    </>
  );
};

export default AddSongFiles;
