import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { formatTextForSentryLog } from 'src/utils/Formatter';
import {
  deleteTrack,
  getReleaseTracks,
  uploadFilesToPlaylist,
} from './MultiAsset.thunks';

interface S3File {
  bucket: string;
  key: string;
  filename: string;
  fileId?: string;
}

export interface AddTrackObj {
  track_id: string;
  isFileUploaded: boolean;
  isFileUploadError: boolean;
  isFetching: boolean;
  file?: File;
  artist: string;
  title: string;
  uploadS3Key: string;
  isSelected: string;
  isActive: boolean;
  S3File: S3File;
  isUpLoading: boolean;
  startTrackingDate?: Date;
  releaseTrack?: ReleaseTrack;
}

export interface TrackId {
  track_id: string;
  artist: string;
  title: string;
}
interface ReleaseTrack {
  playlist_id: string;
  title: string;
  artist: string;
  album: string;
  version: string;
  language: string;
  genres: string[];
  trackid: number;
  status: string;
  explicit: boolean;
  is_monitored: boolean;
}

export interface Release {
  release_id: string;
  release_date: string;
  isEmptyRelease: boolean;
  displayOnlyTracks: AddTrackObj[];
}

export interface AddTrackList {
  tracks: AddTrackObj[];
  trackIds: TrackId[];
  releaseTrack: Release;
  hasError: boolean;
  hasUnauthorizedError: boolean;
  isFetching: boolean;
}

const MultiAssetSlice = createSlice({
  name: 'AddTrack',
  initialState: {
    trackIds: [],
    tracks: [],
    releaseTrack: {} as Release,
    hasError: false,
    hasUnauthorizedError: false,
    isFetching: false,
  } as AddTrackList,
  extraReducers: (builder) =>
    builder
      .addCase(uploadFilesToPlaylist.pending, (state, payload) => {
        state.isFetching = true;
        const uploadFiles = payload.meta.arg;

        state.tracks = uploadFiles.tracks.map((track) => {
          return {
            track_id: '',
            isFileUploaded: false,
            isFileUploadError: false,
            isFetching: false,
            file: undefined,
            artist: track.artist || '',
            title: track.title || '',
            uploadS3Key: '',
            isSelected: '',
            isActive: false,
            S3File: {
              bucket: track.upload_s3_bucket,
              key: track.upload_s3_key,
              filename: track.src_filename,
            },
            isUpLoading: true,
            startTrackingDate: undefined,
          };
        });
      })
      .addCase(uploadFilesToPlaylist.fulfilled, (state, { payload }) => {
        state.trackIds = payload.data;
        state.isFetching = false;
        return state;
      })
      .addCase(uploadFilesToPlaylist.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to upload file to play list')
        );
      })
      .addCase(deleteTrack.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteTrack.fulfilled, (state, payload) => {
        state.tracks = state.tracks.filter(
          (track) => track.track_id !== payload.meta.arg[0]
        );
        state.isFetching = false;
        return state;
      })
      .addCase(deleteTrack.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to delete track')
        );
      })
      .addCase(getReleaseTracks.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getReleaseTracks.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.releaseTrack.release_id = payload.data.release_id;
        state.releaseTrack.release_date = payload.data.release_date;

        state.tracks = payload.data.tracks
          .filter((track: ReleaseTrack) => !track.is_monitored)
          .map((track: ReleaseTrack) => {
            return {
              track_id: track.trackid,
              isFileUploaded: false,
              isFileUploadError: false,
              isFetching: false,
              file: undefined,
              artist: track.artist,
              title: track.title,
              uploadS3Key: '',
              isSelected: '',
              isActive: false,
              S3File: undefined,
              isUpLoading: false,
              startTrackingDate: undefined,
              releaseTrack: track,
            };
          });

        state.releaseTrack.isEmptyRelease = state.tracks.length === 0;

        state.releaseTrack.displayOnlyTracks = payload.data.tracks
          .filter((track: ReleaseTrack) => track.is_monitored)
          .map((track: ReleaseTrack) => {
            return {
              track_id: track.trackid,
              isFileUploaded: false,
              isFileUploadError: false,
              isFetching: false,
              file: undefined,
              artist: track.artist,
              title: track.title,
              uploadS3Key: '',
              isSelected: '',
              isActive: false,
              S3File: undefined,
              isUpLoading: false,
              startTrackingDate: undefined,
              releaseTrack: track,
            };
          });

        return state;
      })
      .addCase(getReleaseTracks.rejected, (state, action) => {
        state.hasError = true;
        state.isFetching = false;
        const statusCode = action.payload;
        if (statusCode === 403) {
          state.hasUnauthorizedError = true;
        }
        Sentry.captureException(
          formatTextForSentryLog('failed to get release tracks')
        );
      }),
  reducers: {
    resetStateAddTrack: () => {
      return {
        trackIds: [],
        tracks: [],
        releaseTrack: {} as Release,
        hasError: false,
        hasUnauthorizedError: false,
        isFetching: false,
      } as AddTrackList;
    },
    updateTracks: (state, action) => {
      const { trackIds, tracks } = action.payload;

      state.tracks = tracks.map((track: AddTrackObj, index: number) => {
        track.track_id = trackIds[index]?.track_id || '';
        return track;
      });

      state.trackIds = [];
      return state;
    },
  },
});

export const { resetStateAddTrack, updateTracks } = MultiAssetSlice.actions;

export default MultiAssetSlice.reducer;
