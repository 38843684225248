/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { Text } from '@dsny/dsny-component-library';
import React from 'react';
import theme from 'src/styles/theme';
import { Trans, useTranslation } from 'react-i18next';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import {
  ComparisonTooltipData,
  ComparisonTooltipPeriod,
  ComparisonTooltipWrapper,
  DashboardBox,
  DashboardChartDesc,
  DashboardExportDataWrapper,
  DashboardTitle,
  NoDataAvailableWrapper,
} from '../Dashboard.styles';
import {
  ComparisonLineChart,
  LineChartBox,
  TimelineContainer,
} from './TimelineTracking.styles';
import { getSongDailyFileCompare } from '../Dashboard.thunks';
import ExportData from '../ExportData/ExportData';

const ComparisonTimelineTracking: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { songSelection, songDaily, filterSelection } = useSelector(
    (state: RootState) => state.dashboard
  );

  const { selectedSong } = songSelection;
  const { songDailyDataList } = songDaily;

  const currentId = songDailyDataList?.data[0]?.id;
  const previousId = songDailyDataList?.data[1]?.id;

  // Handling export data report
  const handleSongDailyFile = () => {
    dispatch(
      getSongDailyFileCompare({
        songId: selectedSong?.song_id,
        period: filterSelection.code,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
  };

  if (!songDailyDataList || !songDailyDataList?.data) {
    return <></>;
  }

  // Get dashed and solid line style
  const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }: any) => {
    return series.map(({ id, data, color }: any, index: number) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d: any) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
        fill="none"
        stroke={color}
        style={
          index % 2 === 0
            ? {
                // simulate line with solid stroke
                strokeWidth: 2,
              }
            : {
                // simulate line will dash stroke when index is even
                strokeDasharray: '6, 6',
                strokeWidth: 2,
              }
        }
      />
    ));
  };

  return (
    <>
      {/* Comparison Timeline Tracking */}
      <TimelineContainer>
        <DashboardBox>
          <DashboardTitle>
            {t('DASHBOARD_TIMELINE_TRACKING_TITLE')}
          </DashboardTitle>
          <DashboardChartDesc>
            <Text
              fontSize="14px"
              fontWeight={400}
              color={theme.colors.neutralW40}
            >
              {t('DASHBOARD_TIMELINE_TRACKING_DESC')}
            </Text>
            <DashboardExportDataWrapper>
              {/* Export data */}
              <ExportData handleDownload={handleSongDailyFile} />
            </DashboardExportDataWrapper>
          </DashboardChartDesc>

          {!songDailyDataList?.data[0]?.data?.length ? (
            // Null state message
            <NoDataAvailableWrapper>
              <Text
                fontSize="16px"
                fontWeight={400}
                color={theme.colors.neutralW60}
              >
                {t('DASHBOARD_NO_DATA_AVAILABLE')}
              </Text>
            </NoDataAvailableWrapper>
          ) : (
            // Line chart
            <LineChartBox>
              <ComparisonLineChart
                size={songDailyDataList?.data[0]?.data?.length}
              >
                <ResponsiveLine
                  animate={false}
                  data={songDailyDataList.data}
                  margin={{ top: 110, right: 95, bottom: 130, left: 95 }}
                  xScale={{ type: 'point' }}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: 'auto',
                    stacked: false, // adds up lines/value together if true
                    reverse: false,
                  }}
                  yFormat=" <-.3~s"
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 0,
                    tickPadding: 40,
                    tickRotation: 0,
                    legendOffset: 36,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 60,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: 'middle',
                    format: (e) => Math.floor(e) === e && e,
                  }}
                  tooltip={
                    function (feature: any) {
                      if (feature.point.data.toolTipData === undefined) {
                        return '';
                      }
                      const { title, current, previous, change } =
                        feature.point.data.toolTipData;

                      return (
                        <ComparisonTooltipWrapper>
                          <ComparisonTooltipPeriod>
                            {title}
                          </ComparisonTooltipPeriod>
                          <ComparisonTooltipData>
                            <Trans
                              i18nKey="DASHBOARD_COMPARISON_CURRENT_PERIOD"
                              values={{ number: current }}
                              components={{ bold: <strong /> }}
                            />
                          </ComparisonTooltipData>
                          <ComparisonTooltipData>
                            <Trans
                              i18nKey="DASHBOARD_COMPARISON_PREVIOUS_PERIOD"
                              values={{ number: previous }}
                              components={{ bold: <strong /> }}
                            />
                          </ComparisonTooltipData>
                          <ComparisonTooltipData>
                            <Trans
                              i18nKey="DASHBOARD_COMPARISON_CHANGE_IN_SPINS"
                              values={{ number: change }}
                              components={{ bold: <strong /> }}
                            />
                          </ComparisonTooltipData>
                        </ComparisonTooltipWrapper>
                      );
                    } as any
                  }
                  enableGridX={false}
                  enableGridY={false}
                  colors={[theme.colors.mtr60, theme.colors.mtr80]} // passing color array for multi lines
                  enablePoints
                  areaBaselineValue={0}
                  areaOpacity={1}
                  layers={[
                    // includes all default layers
                    'grid',
                    'markers',
                    'axes',
                    'areas',
                    'crosshair',
                    'slices',
                    'points',
                    'mesh',
                    'legends',
                    DashedSolidLine,
                  ]}
                  useMesh
                  defs={[
                    linearGradientDef('gradientA', [
                      { offset: 0, color: 'white' },
                      { offset: 100, color: 'white', opacity: 0 },
                    ]),
                    linearGradientDef('gradientB', [
                      { offset: 0, color: theme.colors.mtr80 },
                      { offset: 100, color: theme.colors.mtr80, opacity: 0 },
                    ]),
                  ]}
                  fill={[
                    {
                      match: {
                        id: currentId,
                      },
                      id: 'gradientA',
                    },
                    {
                      match: {
                        id: previousId,
                      },
                      id: 'gradientB',
                    },
                  ]}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: -70,
                      translateY: 120,
                      itemsSpacing: 190,
                      itemDirection: 'left-to-right',
                      itemWidth: 80,
                      itemHeight: 20,
                      symbolSize: 14,
                      symbolShape: 'square',
                    },
                  ]}
                />
              </ComparisonLineChart>
            </LineChartBox>
          )}
        </DashboardBox>
      </TimelineContainer>
    </>
  );
};

export default ComparisonTimelineTracking;
