import styled from 'styled-components';
import theme from 'src/styles/theme';
import { device } from 'src/styles/global';

export const TableContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;

  @media ${device.mobile} {
    width: 400px;
  }

  @media ${device.ipad} {
    width: 768px;
  }
`;

export const FlexRowHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${theme.colors.neutralW90};
  background-color: ${theme.colors.neutralW100};
  padding: 24px 40px;
  width: 1040px;

  @media ${device.mobile} {
    padding: 24px;
    position: sticky;
    top: 0;
  }

  @media ${device.ipad} {
    position: sticky;
    top: 0;
  }
`;

export const FlexTableContent = styled.div`
  height: 300px;
  width: fit-content;
  min-width: fit-content;

  @media not ${device.mobile} {
    overflow-y: auto;
  }

  @media ${device.mobile} {
    height: 240px;
  }

  @media not ${device.ipad} {
    overflow-y: auto;
  }

  @media ${device.ipad} {
    height: 240px;
  }
`;

export const FlexRowContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 24px 40px;
  width: 1040px;

  &:hover {
    background: ${theme.colors.neutralW100};
  }

  :not(:last-child) {
    border-bottom: 1px solid ${theme.colors.neutralW90};
  }

  @media ${device.mobile} {
    padding: 24px;
  }
`;

export const FlexRowSpinner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 24px 40px;
  width: 1040px;

  @media ${device.mobile} {
    padding: 24px;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const LogoColumn = styled.div`
  display: flex;
  align-items: center;
  width: 14%;
`;

export const StationNameColumn = styled.div`
  display: flex;
  align-items: center;
  width: 28%;
`;

export const CityColumn = styled.div`
  display: flex;
  align-items: center;
  width: 18%;
`;

export const CountryColumn = styled.div`
  display: flex;
  align-items: center;
  width: 18%;
`;

export const GenresColumn = styled.div`
  display: flex;
  align-items: center;
  width: 22%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  color: ${theme.colors.neutralW40};
  gap: 4px;

  svg {
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
`;

export const SortButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  background: none;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid ${theme.colors.mtr60};
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const GenreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
`;

export const PillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ShowMinimizeButton = styled.button`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  color: ${theme.colors.mtr60};
  border: none;
  background: none;
  padding: 0px;
  cursor: pointer;
`;

export const StationNameLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.colors.neutralW40};
`;

export const LogoLink = styled.a`
  text-decoration: underline;
  cursor: pointer;

  svg {
    width: 60px;
    height: 60px;
  }
`;

export const FlexRowNoStationFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 140px;
  width: 1040px;

  @media ${device.mobile} {
    padding-top: 100px;
  }
`;
