import styled from 'styled-components';
import { device } from 'src/styles/global';
import theme from 'src/styles/theme';

export const UploaderMainTitle = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  color: ${theme.colors.neutralW40};
  margin-bottom: 24px;
`;

export const UploaderSubheading = styled.p`
  display: inline-block;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.neutralW40};
  margin-bottom: 8px;
`;

export const UploaderDescription = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    padding-left: 32px;
    padding-right: 32px;
  }
`;
