import styled from 'styled-components';
import { Button } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { device } from 'src/styles/global';

export const HeadingWrapper = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: ${theme.colors.neutralW20};
  padding: 40px 40px 8px 40px;

  @media ${device.mobile} {
    font-size: 20px;
    padding: 24px 24px 16px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 40px 24px 40px;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
  }
`;

export const ComboboxWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ClearFilterWrapper = styled.div`
  display: flex;

  @media ${device.mobile} {
    justify-content: flex-end;
  }
`;

export const ClearFilterButton = styled(Button)`
  font-size: 14px;
  width: 108px;
  align-self: center;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #f5f5f5;
  padding: 24px 40px 24px 32px;
  gap: 16px;

  @media ${device.mobile} {
    padding: 24px 16px 24px 16px;
  }
`;

export const TotalStationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.neutralW60};
`;

export const MissingStationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const StationLink = styled.a`
  font-weight: 700;
  text-decoration: none;
  color: ${theme.colors.mtr60};
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid;
  }
`;

export const CloseListButton = styled(Button)`
  font-size: 14px;
  min-width: 96px;
  align-self: center;
`;

export const ExportStationListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
`;

export const ExportDesktopWrapper = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

export const PillStyles = {
  width: '136px',
  height: '24px',
  fontSize: '12px',
  backgroundColor: theme.colors.neutralW100,
};

export const IconContainerStyles = {
  width: '16px',
  height: '16px',
  alignItems: 'center',
};
