/* eslint-disable no-nested-ternary */
import { MaterialIcon, Pill, Tooltip } from '@dsny/dsny-component-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import theme from 'src/styles/theme';
import { formatTitleWithVersion } from 'src/utils/Formatter';
import { selectPeriod, TrackingStatusEnum } from '../Dashboard.slice';
import { DashboardBox } from '../Dashboard.styles';
import { getAllDataFile } from '../Dashboard.thunks';
import { ExtendPlanModal } from '../ExtendPlanModal/ExtendPlanModal';
import TrackingCard from '../TrackingCard/TrackingCard';
import TrackingCardDateRangeList from '../TrackingCard/TrackingCardDateRangeList';
import TrackingCardDateRange from '../TrackingCard/TrackingCardDateRange';
import {
  TrackingContainer,
  TrackingTitle,
  TrackingSubtitle,
  PeriodTitle,
  TooltipWrapper,
  PillStyles,
  IconContainerStyles,
} from './SelectedSong.styles';

const SelectedSong: React.FC = () => {
  const { t } = useTranslation();
  const [showCancel, setShowCancel] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  // Adding extend tracking modal to local storage
  const addShowModalToLocalStorage = (songId: string) => {
    const showedExtendModalSet = new Set(
      JSON.parse(localStorage.getItem('SHOWED_EXTEND_MODAL') || '[]')
    );

    if (!showedExtendModalSet.has(songId)) {
      showedExtendModalSet.add(songId);
      localStorage.setItem(
        'SHOWED_EXTEND_MODAL',
        JSON.stringify(Array.from(showedExtendModalSet))
      );
      setShowCancel(true);
    }
  };

  useEffect(() => {
    // Set default period
    dispatch(selectPeriod(0));
  }, [
    selectedSong?.song_id,
    selectedSong?.tracking_periods[0]?.end_tracking_on,
  ]);

  useEffect(() => {
    if (
      selectedSong?.selected_tracking_period?.status ===
      TrackingStatusEnum.EXPIRED
    ) {
      addShowModalToLocalStorage(selectedSong.song_id);
    }
  }, [selectedSong?.selected_tracking_period?.status]);

  // Export all data
  const exportAllData = () => {
    dispatch(
      getAllDataFile({
        songId: selectedSong?.song_id,
        trackingPeriodId: selectedSong?.selected_tracking_period?.index || 0,
      })
    );
  };

  return (
    <>
      {/* Selected Song */}
      <TrackingContainer>
        <DashboardBox>
          <TrackingTitle>{formatTitleWithVersion(selectedSong)}</TrackingTitle>
          <TrackingSubtitle>
            {t('DASHBOARD_BY_AUTHOR')}&nbsp;{selectedSong?.artist}
          </TrackingSubtitle>
          <PeriodTitle>{t('DASHBOARD_TRACKING_PERIOD')}</PeriodTitle>

          {selectedSong?.tracking_periods &&
          selectedSong?.tracking_periods.length > 1 ? (
            /* Past 90 day */
            <>
              <TrackingCardDateRangeList />
              <TrackingCard />
            </>
          ) : (
            /* Within 90 day */
            <>
              <TrackingCardDateRange />
              <TrackingCard />
            </>
          )}

          {/* Extend modal - will be displayed only once per selected song that is expired. */}
          <ExtendPlanModal show={showCancel} setShowCancel={setShowCancel} />

          {/* Export all data */}
          <TooltipWrapper>
            <Tooltip
              text={t('DASHBOARD_EXPORT_ALL_DATA_TOOLTIP')}
              placement="bottom"
              color={theme.colors.white}
              backgroundColor={theme.colors.neutralW50}
              hasArrow={false}
              containerStyles={{
                display: 'block',
                width: '215px',
                height: '14px',
                whiteSpace: 'normal',
                fontFamily: theme.fonts.primary,
                fontSize: '12px',
                fontWeight: '700',
                lineHeight: '16px',
                borderRadius: '5px',
                textAlign: 'left',
                marginTop: '8px',
              }}
            >
              <Pill
                label={t('DASHBOARD_EXPORT_ALL_DATA')}
                onClick={exportAllData}
                type="leading"
                iconPosition="leading"
                icon={
                  <MaterialIcon
                    name="FileDownload"
                    color={theme.colors.neutralW20}
                  />
                }
                iconContainerStyles={{ ...IconContainerStyles }}
                style={{ ...PillStyles }}
              />
            </Tooltip>
          </TooltipWrapper>
        </DashboardBox>
      </TrackingContainer>
    </>
  );
};

export default SelectedSong;
