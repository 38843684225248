/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Banner,
  Icon,
  MTRFullLogo,
  MaterialIcon,
  SpinningIcon,
  TextField,
} from '@dsny/dsny-component-library';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendSignupEmail, validateCode } from 'src/features/Auth/Auth.thunks';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import theme from 'src/styles/theme';
import { clearSignupHandle } from 'src/features/Auth/Auth.slice';
import { inputHandler } from 'src/utils/InputHandler';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormBox,
  Container,
  LogoMTRWrapper,
  StepsWrapper,
  ClickableStep,
  LinkStyle,
  HaveAccountSection,
  SecondaryTitle,
  StepTwoTitleDesc,
  StepsButton,
  CenteredButtonWrapper,
  ResendEmailSection,
  LinkResendEmail,
} from './Signup.styles';
import { AuthWrapper } from '../Auth.styles';
import { ErrorWrapper } from '../Signin/Signin.styles';
import { trackEvent } from '../../../utils/segmentService';

const schema = yup.object().shape({
  code: yup.string().max(5).min(5).required(),
});

const CODE_FIELD = 'code';

const SignupStepTwo: React.FC = () => {
  const { t } = useTranslation();
  const [rerender, setRerender] = useState(false);
  const { isFetching, signUp } = useSelector((state: RootState) => state.auth);
  const { hasResponseError, isCodeValid } = signUp.stepTwo;
  const [emailCodeSent, setEmailCodeSent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  // Form validation step two
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    mode: 'all',
    resolver: yupResolver<any>(schema),
    shouldUnregister: true,
  });

  // Handling step two validation
  const handleValidation = () => {
    setRerender(!rerender);
  };

  // Handling onSubmit step two
  const onSubmit = ({ code }: any) => {
    setEmailCodeSent(false);
    dispatch(validateCode({ email: signUp.stepOne.email, code }));
  };

  // Handling resend email code step two
  const resendEmailCode = () => {
    dispatch(
      sendSignupEmail({
        email: signUp.stepOne.email,
        password: signUp.stepOne.password,
      })
    ).then(() => {
      trackEvent('Send Email Verification', { is_resend: 'true' });
      setEmailCodeSent(true);
    });
  };

  // Step two error banner
  const errorVerificationCodeExpired = {
    title:
      hasResponseError === 2
        ? t('SIGNUP_VERIFICATION_CODE_EXPIRED')
        : t('SIGNUP_VERIFICATION_CODE_INVALID'),
    description: t('SIGNUP_VERIFICATION_CODE_EXPIRED_DESC'),
  };

  // Step two info banner
  const infoVerificationCodeResent = {
    title: t('SIGNUP_VERIFICATION_CODE_RESENT'),
    description: t('SIGNUP_VERIFICATION_CODE_RESENT_DESC'),
  };

  useEffect(() => {
    if (!signUp.stepOne.email && !signUp.stepOne.isValidUser)
      navigate('/auth/signup');
    setValue(CODE_FIELD, signUp.stepTwo.code);
  }, [signUp]);

  useEffect(() => {
    if (isCodeValid) {
      dispatch(clearSignupHandle());
      const searchParams = new URLSearchParams(location.search);
      navigate(`/auth/signupstepthree?${searchParams.toString()}`);
    }
  }, [hasResponseError, isCodeValid]);

  return (
    <AuthWrapper>
      <LogoMTRWrapper>
        <MTRFullLogo />
      </LogoMTRWrapper>

      {/* Step 2 */}
      <Container>
        <FormBox onSubmit={handleSubmit(onSubmit)}>
          {/* Banner messages */}
          {hasResponseError > 0 && !emailCodeSent && (
            <ErrorWrapper>
              <Banner
                bannerContent={errorVerificationCodeExpired}
                variant="error"
              />
            </ErrorWrapper>
          )}
          {emailCodeSent && (
            <ErrorWrapper>
              <Banner
                bannerContent={infoVerificationCodeResent}
                variant="custom"
                customIconName="Warning"
                customIconColor={theme.colors.interact60}
                style={{
                  backgroundColor: theme.colors.white,
                  border: '1px solid #3285E0',
                  borderLeft: '8px solid #3285E0',
                }}
              />
            </ErrorWrapper>
          )}

          <StepsWrapper>
            <ClickableStep
              $isClickable
              onClick={() => navigate('/auth/signup')}
            >
              <Icon
                name="ArrowLeft"
                style={{
                  width: '14px',
                  height: '14px',
                  marginRight: '4px',
                  color: theme.colors.neutralW30,
                }}
              />
              {t('SIGNUP_STEP_TWO')}
            </ClickableStep>
            &nbsp;{t('SIGNUP_OF_3_STEP')}
          </StepsWrapper>

          <SecondaryTitle>{t('SIGNUP_STEP_TWO_TITLE')}</SecondaryTitle>
          <StepTwoTitleDesc>
            <p>
              <Trans
                i18nKey="SIGNUP_STEP_TWO_FIRST_DESC"
                values={{ email: signUp.stepOne.email }}
                components={{ bold: <strong /> }}
              />
            </p>
            <p>{t('SIGNUP_STEP_TWO_SECOND_DESC')}</p>
          </StepTwoTitleDesc>

          <TextField
            id="code"
            setting="mtr"
            labelText={t('SIGNUP_VERIFICATION_CODE')}
            type="text"
            required
            {...register(CODE_FIELD)}
            error={errors.code && t('SIGNUP_ERROR_VERIFICATION_CODE')}
            onBlur={(e: Event) => {
              inputHandler(setValue, trigger, e);
              handleValidation();
            }}
            onChange={(e: ChangeEvent) => {
              inputHandler(setValue, trigger, e);
              handleValidation();
            }}
            onFocus={() => setRerender(!rerender)}
            ref={null}
            wrapperStyles={{
              paddingRight: '0px',
              paddingBottom: '16px',
              marginBottom: '0px',
            }}
            inputStyle={{
              height: '44px',
              borderRadius: '5px',
              borderColor: errors.code && theme.colors.error50,
            }}
            labelStyle={{
              fontSize: '14px',
              color: errors.code && theme.colors.error50,
            }}
            errorStyle={{ marginTop: '8px', color: theme.colors.error50 }}
          />

          <ResendEmailSection>
            {t('SIGNUP_RESEND_EMAIL')}&nbsp;
            <LinkResendEmail onClick={() => resendEmailCode()}>
              {t('SIGNUP_RESEND_EMAIL_LINK')}
            </LinkResendEmail>
          </ResendEmailSection>

          <CenteredButtonWrapper>
            <StepsButton
              setting="mtr"
              fontSize="14px"
              type="submit"
              style={{
                fontFamily: theme.fonts.primary,
                height: '36px',
                borderRadius: '5px',
                paddingTop: isFetching ? '14px' : '',
              }}
            >
              {isFetching ? (
                <SpinningIcon>
                  <MaterialIcon name="Rotate" />
                </SpinningIcon>
              ) : (
                t('SIGNUP_CTA_NEXT_BUTTON')
              )}
            </StepsButton>
          </CenteredButtonWrapper>

          <HaveAccountSection>
            {t('SIGNUP_HAVE_ACCOUNT')}&nbsp;
            <LinkStyle to="/auth/signin">
              {t('SIGNUP_HAVE_ACCOUNT_LINK')}
            </LinkStyle>
          </HaveAccountSection>
        </FormBox>
      </Container>
    </AuthWrapper>
  );
};

export default SignupStepTwo;
