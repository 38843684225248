import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/services/network';

// Purchase flow
export const getPackageList = createAsyncThunk(
  'PACKAGE_LIST',
  async (quantity: number) => {
    return axios
      .get(`/subscriptions/mtr/pricing?quantity=${quantity}`)
      .then((response) => response.data)
      .then((data) => {
        return data;
      });
  }
);

// Pricing modal
export const getSingleSongPackageList = createAsyncThunk(
  'PACKAGE_SINGLE_SONG_LIST',
  async () => {
    return axios
      .get(`/subscriptions/mtr/pricing?quantity=1`)
      .then((response) => response.data)
      .then((data) => {
        return data;
      });
  }
);

export default getPackageList;
