import { ChangeEvent } from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const inputHandler = (
  setValue: any,
  trigger: any,
  e: ChangeEvent | Event
) => {
  if (e.target) {
    const inputElement = e.target as HTMLInputElement;
    setValue(inputElement.name, inputElement.value);
    trigger(inputElement.name);
  }
};
