import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from 'src/styles/global';
import { Button } from '@dsny/dsny-component-library';

export interface Props {
  $isInvalid?: boolean;
}

export const LogoMTRWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 24px;

  @media ${device.mobile} {
    padding-top: 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 676px;
  min-height: 433px;
  padding-bottom: 160px;

  @media ${device.mobile} {
    max-width: 288px;
    padding-bottom: 72px;
  }
  @media ${device.tablet} {
    width: 90%;
  }
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 32px;

  @media ${device.mobile} {
    margin-bottom: 24px;
  }
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 80px 104px;
  border-radius: 5px;
  border-top: 4px solid ${({ theme }) => theme.colors.mtr70};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.background.white};

  @media ${device.mobile} {
    padding: 48px 24px;
  }
`;

export const MessageTitle = styled.div`
  padding-bottom: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutralW40};

  @media ${device.mobile} {
    font-size: 14px;
  }

  p {
    margin-bottom: 32px;
    :last-of-type {
      margin-bottom: 0px;
      align-self: center;
      color: ${({ theme }) => theme.colors.neutralW10};
    }
  }
`;

export const ResetMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutralW40};

  @media ${device.mobile} {
    font-size: 14px;
  }

  p {
    margin-bottom: 16px;
    :last-of-type {
      margin-bottom: 0px;
      margin-top: 16px;
      align-self: center;
      color: ${({ theme }) => theme.colors.neutralW10};
    }
  }
`;

export const ContactLink = styled.a`
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.neutralW40};
  cursor: pointer;
  padding-left: 1px;
  padding-right: 1px;

  &:focus-visible {
    outline: 2px solid;
  }
`;

export const FinalMarkWrapper = styled.span`
  color: ${({ theme }) => theme.colors.blackTwo};
`;

export const ReturnToSigninWrapper = styled.p`
  font-size: 14px;
`;

export const SigninLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mtr50};
  cursor: pointer;
  padding-left: 1px;
  padding-right: 1px;

  &:focus-visible {
    outline: 2px solid;
  }
`;

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 80px 104px;
  border-radius: 5px;
  border-top: 4px solid ${({ theme }) => theme.colors.mtr70};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.background.white};

  @media ${device.mobile} {
    padding: 48px 24px;
  }
`;

export const Title = styled.div`
  padding-bottom: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
    padding-bottom: 32px;
  }
`;

export const ResetButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ResetButton = styled(Button)`
  width: 100%;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const NewLinkButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

export const NewLinkButton = styled(Button)`
  width: 100%;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const RequirementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 32px;
  font-weight: 400;
  font-size: 12px;

  p {
    :first-of-type {
      color: ${({ theme }) => theme.colors.neutralW40};
    }
  }
`;

export const Text = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ $isInvalid, theme }) =>
    $isInvalid ? theme.colors.neutralW40 : theme.colors.green};
`;

export const IconWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ $isInvalid, theme }) =>
    $isInvalid ? theme.colors.blackThree : theme.colors.green};
`;
