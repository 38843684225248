import { createGlobalStyle } from 'styled-components';
import '@fontsource/nunito-sans';

export const device = {
  mobile: `(max-width: 480px)`,
  tablet: `(min-width: 481px) and (max-width: 767px)`,
  ipad: `(min-width: 768px) and (max-width: 1024px)`,
  laptop: `(min-width: 1025px) and (max-width: 1280px)`,
  desktop: `(min-width: 1281px)`,
};

export const mtrStyle = {
  borderRadius: '5px',
  mobileFontSize: '20px',
};

export default createGlobalStyle`
    * {
        font-family: 'Nunito Sans';
        margin: 0;
        box-sizing: border-box;
    }

    #root {
        margin: 0 auto;
    }

    html {
        background: #F8F9FA;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus { -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
                                                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
    }
    
`;
