import {
  Checkbox,
  MaterialIcon,
  SpinningIcon,
  Text,
} from '@dsny/dsny-component-library';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import theme from 'src/styles/theme';
import {
  BatchSelectionWrapper,
  SongSelectionWrapper,
  SongInfoWrapper,
  BatchDescWrapper,
  IconWrapper,
  SongItemWrapper,
  SongTitle,
  ArtistName,
} from './MultiSongSelection.styles';
import { AddTrackObj } from '../MultiAsset.slice';

type MultiSongSelectionProps = {
  tracks: AddTrackObj[];
  handleTrackDelete: (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => void;
  handleSelect: (event: React.ChangeEvent<Element>, index: number) => void;
  handleActiveSelection: (index: number) => void;
  handleSelectAllCheckbox: () => void;
  batchCheckbox?: string;
};

const MultiSongSelectionDefault: React.FC<MultiSongSelectionProps> = ({
  tracks,
  handleTrackDelete,
  handleSelect,
  handleActiveSelection,
  handleSelectAllCheckbox,
  batchCheckbox,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/* Multi Song Selection - Batch (Default flow) */}
      <BatchSelectionWrapper>
        <Checkbox
          id="checkValue"
          name="tracksToBackCheckbox"
          color={theme.colors.green}
          onChange={handleSelectAllCheckbox}
          value={batchCheckbox}
          checked={!!batchCheckbox}
          disabled={tracks.length <= 1}
          style={{
            borderColor: theme.colors.neutralW30,
            marginRight: '16px',
          }}
        />
        <BatchDescWrapper>
          <Text
            fontSize="14px"
            fontWeight={700}
            color={theme.colors.neutralW20}
          >
            <Trans
              i18nKey="MULTI_UPLOADED_SONGS"
              values={{ number: tracks.length }}
            />
          </Text>
          <Text
            fontSize="12px"
            fontWeight={400}
            color={theme.colors.neutralW20}
          >
            {t('MULTI_SELECT_MULTI_TRACKS_TO_BATCH')}
          </Text>
        </BatchDescWrapper>
      </BatchSelectionWrapper>

      {/* Song List */}
      {tracks.map((track, index) => {
        return (
          <SongItemWrapper
            key={track.S3File?.key || track.releaseTrack?.trackid}
            onClick={() => handleActiveSelection(index)}
            $isActive={track.isActive}
            $isSelected={!!track.isSelected}
            $hasError={track.isFileUploadError}
            $alreadyTrackedSongs
          >
            <SongSelectionWrapper>
              <Checkbox
                id="itemCheckValue"
                name="itemCheckValue"
                color={theme.colors.green}
                onChange={(event) => handleSelect(event, index)}
                value={track.isSelected}
                checked={!!track.isSelected}
                disabled={tracks.length <= 1}
                style={{
                  borderColor: theme.colors.neutralW30,
                  marginRight: '16px',
                }}
              />
              <SongInfoWrapper>
                <SongTitle>{track?.title}</SongTitle>
                {!track.isUpLoading ? (
                  <ArtistName>
                    {track?.artist.trim() ||
                      t('MULTI_SELECT_PLEASE_ADD_ARTIST_NAME')}
                  </ArtistName>
                ) : (
                  <ArtistName>{t('MULTI_SELECT_PROCESSING')}</ArtistName>
                )}
              </SongInfoWrapper>
            </SongSelectionWrapper>
            {track.isUpLoading ? (
              <SpinningIcon style={{ marginTop: '2px' }}>
                <MaterialIcon name="Loading" color={theme.colors.neutralW40} />
              </SpinningIcon>
            ) : (
              <IconWrapper onClick={(event) => handleTrackDelete(event, index)}>
                <MaterialIcon name="Trash" color={theme.colors.neutralW40} />
              </IconWrapper>
            )}
          </SongItemWrapper>
        );
      })}
    </>
  );
};

export default MultiSongSelectionDefault;
