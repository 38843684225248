/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MaterialIcon,
  Pill,
  SpinningIcon,
  Text,
} from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import InfiniteTableScroll from 'src/features/Common/InfiniteTableScroll';
import {
  LogoWrapper,
  FlexRowContent,
  GenresColumn,
  CityColumn,
  LogoColumn,
  StationNameColumn,
  CountryColumn,
  PillWrapper,
  ShowMinimizeButton,
  StationNameLink,
  LogoLink,
  FlexRowSpinner,
} from './RadioStationList.styles';
import { addSearchParams, RadioStationTable } from '../RadioStationModal.slice';

interface ShowData {
  showAll: boolean;
  show: boolean[];
}

interface RadioStationContentProps {
  tableData: RadioStationTable | undefined;
}

// Minimum genre number to display
const showGenreCardLimit = 3;

const RadioStationContent: React.FC<RadioStationContentProps> = ({
  tableData,
}: RadioStationContentProps) => {
  const { t } = useTranslation();
  const [showAllGenres, setShowAllGenres] = useState({} as ShowData);
  const [scrollRef, setScrollRef] = useState(false);
  const [resetScrollPage, setResetScrollPage] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const { searchParams, radioStationList, clearAllFilters } = useSelector(
    (state: RootState) => state.radioStationModal
  );

  // Handling setShowGenreList
  const setShowGenreList = (value: boolean) => {
    return tableData?.items?.map(() => {
      return value;
    });
  };

  const getTableData = () => {
    dispatch(addSearchParams({ ...searchParams, page: searchParams.page + 1 }));
  };

  useEffect(() => {
    const show = setShowGenreList(false);
    if (show) {
      setShowAllGenres({ showAll: false, show });
    }
  }, [tableData]);

  useEffect(() => {
    const show = setShowGenreList(!showAllGenres.showAll);
    if (show) {
      setShowAllGenres({ showAll: !showAllGenres.showAll, show });
    }
  }, [tableData?.showAllGenres]);

  // Handling showMinimizeGenres
  const showMinimizeGenres = (index: number) => {
    const tempShowAllGenres = showAllGenres;
    tempShowAllGenres.show[index] = !tempShowAllGenres.show[index];
    setShowAllGenres({ ...tempShowAllGenres });
  };

  useEffect(() => {
    setScrollRef(!scrollRef);
    setHasNext(searchParams.hasNext);
    setLoading(radioStationList.isFetchingRadioStationListOverview);
  }, [tableData, searchParams]);

  useEffect(() => {
    setResetScrollPage(!resetScrollPage);
  }, [clearAllFilters]);

  if (!showAllGenres?.show?.length) return <></>;

  return (
    <>
      {/*  Table Content */}
      <InfiniteTableScroll
        scrollRef={scrollRef}
        resetScrollPage={resetScrollPage}
        hasNext={hasNext}
        isLoading={isLoading}
        getTableData={getTableData}
      >
        {tableData?.items?.map((item, index) => {
          return (
            <FlexRowContent key={item.id}>
              <LogoColumn>
                <LogoWrapper>
                  {/* Index */}
                  <Text fontSize="14px" color={theme.colors.neutralW40}>
                    {index + 1}
                  </Text>

                  {/* Logo URL */}
                  {item.logo_url ? (
                    <LogoLink
                      href={item.website}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div style={{ width: '64px', height: '73px' }}>
                        <img
                          src={item.logo_url}
                          alt="Description of the SVG"
                          width="64"
                          height="73"
                        />
                      </div>
                    </LogoLink>
                  ) : (
                    <div style={{ width: '64px', height: '73px' }} />
                  )}
                </LogoWrapper>
              </LogoColumn>

              {/* Station name */}
              <StationNameColumn>
                <Text
                  ellipsis
                  fontSize="14px"
                  color={theme.colors.neutralW40}
                  width="220px"
                >
                  {item.website ? (
                    <StationNameLink
                      href={item.website}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.name}
                    </StationNameLink>
                  ) : (
                    <>{item.name}</>
                  )}
                </Text>
              </StationNameColumn>

              {/* City */}
              <CityColumn>
                <Text
                  ellipsis
                  fontSize="14px"
                  color={theme.colors.neutralW40}
                  width="140px"
                >
                  {item.city}, {item.state}
                </Text>
              </CityColumn>

              {/* Country */}
              <CountryColumn>
                <Text
                  ellipsis
                  fontSize="14px"
                  color={theme.colors.neutralW40}
                  width="140px"
                >
                  {item.country}
                </Text>
              </CountryColumn>

              {/* Genre */}
              <GenresColumn>
                <PillWrapper>
                  {item?.genres
                    ?.slice(
                      0,
                      showAllGenres.show[index]
                        ? item.genres.length
                        : showGenreCardLimit
                    )
                    .map((genre) => {
                      return (
                        <Pill
                          key={genre}
                          label={genre}
                          type="outlined"
                          style={{
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: theme.colors.neutralW40,
                            borderRadius: '5px',
                            border: '1px solid #4F4E4E',
                            background: theme.colors.white0,
                            display: 'inline-flex',
                            width: 'fit-content',
                            minWidth: 'fit-content',
                          }}
                        />
                      );
                    })}
                  {/* Show X more genres - Minimize genres */}
                  {item?.genres?.length > showGenreCardLimit && (
                    <>
                      {showAllGenres.show[index] ? (
                        <ShowMinimizeButton
                          onClick={() => showMinimizeGenres(index)}
                        >
                          {t('STATIONS_MINIMIZE_GENRES')}
                        </ShowMinimizeButton>
                      ) : (
                        <ShowMinimizeButton
                          onClick={() => showMinimizeGenres(index)}
                        >
                          <Trans
                            i18nKey="STATIONS_SHOW_MORE_GENRES"
                            values={{
                              number: item.genres.length - showGenreCardLimit,
                            }}
                          />
                        </ShowMinimizeButton>
                      )}
                    </>
                  )}
                </PillWrapper>
              </GenresColumn>
            </FlexRowContent>
          );
        })}
        {radioStationList.isFetchingRadioStationListOverview && (
          // Spinner
          <FlexRowSpinner>
            <SpinningIcon style={{ transformOrigin: 'center 40%' }}>
              <MaterialIcon name="Rotate" color={theme.colors.mtr60} />
            </SpinningIcon>
          </FlexRowSpinner>
        )}
      </InfiniteTableScroll>
    </>
  );
};

export default RadioStationContent;
