import styled from 'styled-components';
import { device } from 'src/styles/global';
import theme from 'src/styles/theme';

export const EditSongDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.colors.neutralW80};
  padding: 24px 24px 16px 24px;

  @media ${device.mobile} {
    padding: 24px 16px 16px 16px;
  }
`;

export const SongInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 8px 24px;

  @media ${device.mobile} {
    padding: 24px 16px 8px 16px;
  }
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`;

export const UploadedFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
`;
