import { MaterialIcon, Text, Tooltip } from '@dsny/dsny-component-library';
import React from 'react';
import theme from 'src/styles/theme';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { getComparisonBadgeStyle } from 'src/utils/ComparisonBadge';
import { formatNumberAboveTenThousand } from 'src/utils/Formatter';
import { DashboardBox, DashboardTitle } from '../Dashboard.styles';

import {
  CardBox,
  IconWrapper,
  MetricWrapper,
  OverviewContainer,
  OverviewWrapper,
  StatCardWrapper,
  CardFlex,
  Total,
  ComparisonBadge,
  ComparisonMetricWrapper,
  PreviousPeriod,
} from './Overview.styles';

interface OverviewProps {
  hasComparisonOn: boolean;
}

const Overview: React.FC<OverviewProps> = ({ hasComparisonOn }) => {
  const { t } = useTranslation();
  const { overview, overviewCompare } = useSelector(
    (state: RootState) => state.dashboard.songOverview
  );

  // Rendering comparison metric
  const renderOverviewMetric = (total?: number) => {
    return (
      // Comparison mode OFF
      <>
        <MetricWrapper>
          <>
            <Total>
              {total === undefined
                ? '0'
                : formatNumberAboveTenThousand(total || 0)}
            </Total>
          </>
        </MetricWrapper>
      </>
    );
  };

  // Rendering comparison metric
  const renderOverviewComparisonMetric = (
    total?: number,
    totalChange?: number,
    previousCount?: number
  ) => {
    const change = totalChange || 0;
    const { color, backgroundColor } = getComparisonBadgeStyle(change);

    return (
      // Comparison mode ON
      <>
        <ComparisonMetricWrapper>
          <MetricWrapper>
            <Total>{formatNumberAboveTenThousand(total || 0)}</Total>
            <ComparisonBadge color={color} backgroundColor={backgroundColor}>
              {formatNumberAboveTenThousand(change, true)}
            </ComparisonBadge>
          </MetricWrapper>
          <PreviousPeriod>
            <Trans
              i18nKey="DASHBOARD_COMPARISON_PREVIOUS_PERIOD"
              values={{
                number: formatNumberAboveTenThousand(previousCount || 0),
              }}
              components={{ bold: <strong /> }}
            />
          </PreviousPeriod>
        </ComparisonMetricWrapper>
      </>
    );
  };

  // Tooltip text
  const totalSpinsTooltip = t('DASHBOARD_TOTAL_SPINS_TOOLTIP');
  const totalStationsTooltip = t('DASHBOARD_TOTAL_STATIONS_TOOLTIP');
  const totalCitiesTooltip = t('DASHBOARD_TOTAL_CITIES_TOOLTIP');

  return (
    <>
      {/* Overview */}
      <OverviewContainer>
        <DashboardBox>
          <DashboardTitle>{t('DASHBOARD_OVERVIEW_TITLE')}</DashboardTitle>
          <OverviewWrapper>
            <Text
              fontSize="14px"
              fontWeight={400}
              color={theme.colors.neutralW40}
            >
              {t('DASHBOARD_OVERVIEW_DESC')}
            </Text>
          </OverviewWrapper>

          {/* Stat Cards */}
          <StatCardWrapper>
            <CardBox>
              <CardFlex>
                <Text
                  ellipsis
                  fontSize="14px"
                  fontWeight={400}
                  color={theme.colors.neutralW20}
                  style={{ paddingBottom: hasComparisonOn ? '44px' : '69px' }}
                >
                  {t('DASHBOARD_TOTAL_RADIO_SPINS')}
                </Text>
                <Tooltip
                  text={totalSpinsTooltip}
                  placement="top"
                  color={theme.colors.white}
                  backgroundColor={theme.colors.neutralW50}
                  hasArrow={false}
                  containerStyles={{
                    display: 'block',
                    width: '240px',
                    whiteSpace: 'normal',
                    fontFamily: theme.fonts.primary,
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '16px',
                    borderRadius: '5px',
                    textAlign: 'left',
                  }}
                >
                  <IconWrapper>
                    <MaterialIcon name="Help" />
                  </IconWrapper>
                </Tooltip>
              </CardFlex>
              {!hasComparisonOn
                ? renderOverviewMetric(overview?.all_time_plays)
                : renderOverviewComparisonMetric(
                    overviewCompare?.all_time_plays?.count,
                    overviewCompare?.all_time_plays?.difference,
                    overviewCompare?.all_time_plays?.previous_count
                  )}
            </CardBox>

            <CardBox>
              <CardFlex>
                <Text
                  ellipsis
                  fontSize="14px"
                  fontWeight={400}
                  color={theme.colors.neutralW20}
                  style={{ paddingBottom: hasComparisonOn ? '44px' : '69px' }}
                >
                  {t('DASHBOARD_TOTAL_STATIONS')}
                </Text>
                <Tooltip
                  text={totalStationsTooltip}
                  placement="top"
                  color={theme.colors.white}
                  backgroundColor={theme.colors.neutralW50}
                  hasArrow={false}
                  containerStyles={{
                    display: 'block',
                    width: '240px',
                    whiteSpace: 'normal',
                    fontFamily: theme.fonts.primary,
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '16px',
                    borderRadius: '5px',
                    textAlign: 'left',
                  }}
                >
                  <IconWrapper>
                    <MaterialIcon name="Help" />
                  </IconWrapper>
                </Tooltip>
              </CardFlex>
              {!hasComparisonOn
                ? renderOverviewMetric(overview?.unique_stations)
                : renderOverviewComparisonMetric(
                    overviewCompare?.unique_stations?.count,
                    overviewCompare?.unique_stations?.difference,
                    overviewCompare?.unique_stations?.previous_count
                  )}
            </CardBox>

            <CardBox>
              <CardFlex>
                <Text
                  ellipsis
                  fontSize="14px"
                  fontWeight={400}
                  color={theme.colors.neutralW20}
                  style={{ paddingBottom: hasComparisonOn ? '44px' : '69px' }}
                >
                  {t('DASHBOARD_TOTAL_CITIES')}
                </Text>
                <Tooltip
                  text={totalCitiesTooltip}
                  placement="top"
                  color={theme.colors.white}
                  backgroundColor={theme.colors.neutralW50}
                  hasArrow={false}
                  containerStyles={{
                    display: 'block',
                    width: '240px',
                    whiteSpace: 'normal',
                    fontFamily: theme.fonts.primary,
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '16px',
                    borderRadius: '5px',
                    textAlign: 'left',
                  }}
                >
                  <IconWrapper>
                    <MaterialIcon name="Help" />
                  </IconWrapper>
                </Tooltip>
              </CardFlex>
              {!hasComparisonOn
                ? renderOverviewMetric(overview?.unique_cities)
                : renderOverviewComparisonMetric(
                    overviewCompare?.unique_cities?.count,
                    overviewCompare?.unique_cities?.difference,
                    overviewCompare?.unique_cities?.previous_count
                  )}
            </CardBox>
          </StatCardWrapper>
        </DashboardBox>
      </OverviewContainer>
    </>
  );
};

export default Overview;
