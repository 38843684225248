import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { ComboboxOption } from '@dsny/dsny-component-library';
import { device } from 'src/styles/global';

interface Props {
  selectedItemIndex?: number;
  isExpiredListSelected?: boolean;
  itemIndex?: number;
}

export const ComboboxWrapper = styled.div`
  width: 487px;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const ComboboxStyles = {
  width: '100%',
  backgroundColor: theme.colors.white0,
};

export const ComboboxInputStyles = {
  color: theme.colors.neutralW40,
  fontWeight: '700',
  height: '56px',
  paddingLeft: '16px',
};

export const ComboboxListStyles = {
  overflow: 'auto',
};

export const ComboboxOptionStyles = {
  paddingLeft: '16px',
};

export const ComboboxOptionWrapper = styled(ComboboxOption)<Props>`
  &:hover {
    background: ${theme.colors.neutralW100};
  }

  &:nth-child(${({ selectedItemIndex }) => selectedItemIndex}) {
    background: ${theme.colors.mtr100};
  }
`;

export const TrackingSongListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: ${theme.colors.neutralW40};

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${theme.colors.neutralW40};
  }
`;

export const ExpiredSongListWrapper = styled.div<Props>`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: ${({ selectedItemIndex, itemIndex }) =>
    selectedItemIndex === itemIndex
      ? theme.colors.neutralW40
      : theme.colors.neutralW80};

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    color: ${({ selectedItemIndex, itemIndex }) =>
      selectedItemIndex === itemIndex
        ? theme.colors.neutralW40
        : theme.colors.neutralW80};
  }

  &:hover {
    color: ${theme.colors.neutralW40};
    p {
      color: ${theme.colors.neutralW40};
    }
  }
`;

export const SongInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TrackingTitleWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  padding-right: 8px;

  @media ${device.mobile} {
    max-width: 168px;
  }
`;

export const ExpiredTitleWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 448px;
  padding-right: 8px;

  @media ${device.mobile} {
    max-width: 256px;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${theme.colors.white0};
  width: 487px;
  padding: 4px;
  gap: 4px;

  @media ${device.mobile} {
    width: 100%;
  }
`;

const sharedTabStyles = css`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
  font-size: 14px;
  width: 50%;
  padding: 8px;
  border-radius: 3px;
`;

export const TrackingStyleButton = styled.button<Props>`
  ${sharedTabStyles}
  color: ${({ isExpiredListSelected }) =>
    isExpiredListSelected ? theme.colors.neutralW80 : theme.colors.mtr60};

  background-color: ${({ isExpiredListSelected }) =>
    isExpiredListSelected ? theme.colors.white0 : theme.colors.mtr90};

  &:hover {
    color: ${theme.colors.mtr80};
    background: ${theme.colors.mtr100};
  }
`;

export const ExpiredStyleButton = styled.button<Props>`
  ${sharedTabStyles}
  color: ${({ isExpiredListSelected }) =>
    !isExpiredListSelected ? theme.colors.neutralW80 : theme.colors.neutralW40};

  background-color: ${({ isExpiredListSelected }) =>
    !isExpiredListSelected ? theme.colors.white0 : theme.colors.neutralW80};

  &:hover {
    color: ${theme.colors.neutralW60};
    background-color: ${theme.colors.neutralW100};
  }
`;

export const DropdownInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const SongWrapper = styled.div`
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360px;

  @media ${device.mobile} {
    max-width: 160px;
  }
`;

export const ActivePillStyles = {
  fontSize: '12px',
  lineHeight: '13px',
  height: '18px',
  color: theme.colors.mtr60,
  backgroundColor: theme.colors.mtr90,
};

export const ExpiredPillStyles = {
  fontSize: '12px',
  lineHeight: '13px',
  height: '18px',
  color: theme.colors.neutralW40,
  backgroundColor: theme.colors.neutralW80,
};

export const ScheduledPillStyles = {
  fontSize: '12px',
  lineHeight: '13px',
  height: '18px',
  color: theme.colors.neutralW40,
  backgroundColor: theme.colors.neutralW90,
};

export const EmptyStateMessageStyle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${theme.colors.neutralW40};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
`;
