import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from 'src/styles/global';
import { Button, TextField } from '@dsny/dsny-component-library';

export interface Props {
  $isInvalid?: boolean;
  $isClickable?: boolean;
  $isCountrySelected?: boolean;
  $hasError?: boolean;
}

export const LogoMTRWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 24px;

  @media ${device.mobile} {
    padding-top: 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 676px;
  min-height: 618px;
  padding-bottom: 160px;

  @media ${device.mobile} {
    max-width: 288px;
    padding-bottom: 72px;
  }
  @media ${device.tablet} {
    width: 90%;
  }
`;

export const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 80px 104px;
  border-radius: 5px;
  border-top: 4px solid ${({ theme }) => theme.colors.mtr60};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.background.white};

  @media ${device.mobile} {
    padding: 48px 24px;
  }
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutralW40};

  @media ${device.mobile} {
    font-size: 12px;
    padding-bottom: 24px;
  }
`;

export const ClickableStep = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : '')};
`;

export const Title = styled.div`
  padding-bottom: 40px;
  font-weight: 700;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
    padding-bottom: 32px;
  }
`;

export const RequirementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 32px;
  font-weight: 400;
  font-size: 12px;

  p {
    :first-of-type {
      color: ${({ theme }) => theme.colors.neutralW40};
    }
  }
`;

export const Text = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ $isInvalid, theme }) =>
    $isInvalid ? theme.colors.neutralW40 : theme.colors.green};
`;

export const IconWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ $isInvalid, theme }) =>
    $isInvalid ? theme.colors.blackThree : theme.colors.green};
`;

export const CheckboxWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding-bottom: 32px;
  color: ${({ $hasError, theme }) =>
    $hasError ? theme.colors.error50 : theme.colors.neutralW40};
`;

export const TermsLink = styled.a`
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mtr60};
  cursor: pointer;
  padding-left: 1px;
  padding-right: 1px;

  &:focus-visible {
    outline: 2px solid;
  }
`;

export const FlexEndButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

export const CenteredButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
`;

export const StepsButton = styled(Button)`
  width: 100%;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const HaveAccountSection = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutralW10};
`;

export const ResendEmailSection = styled.div`
  padding-bottom: 32px;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutralC10};

  @media ${device.mobile} {
    padding-bottom: 24px;
  }
`;

export const LinkStyle = styled(Link)`
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mtr60};
  cursor: pointer;
  padding-left: 1px;
  padding-right: 1px;

  &:focus-visible {
    outline: 2px solid;
  }
`;

export const LinkResendEmail = styled.p`
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mtr60};
  cursor: pointer;
  padding-left: 1px;
  padding-right: 1px;

  &:focus-visible {
    outline: 2px solid;
  }
`;

export const SecondaryTitle = styled.div`
  padding-bottom: 8px;
  font-weight: 700;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
    padding-bottom: 24px;
  }
`;

export const StepTwoTitleDesc = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutralW40};
  padding-bottom: 40px;

  @media ${device.mobile} {
    padding-bottom: 24px;
  }

  p {
    :first-of-type {
      padding-bottom: 24px;
    }
  }
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 32px;

  @media ${device.mobile} {
    margin-bottom: 24px;
  }
`;

export const StepThreeTitleDesc = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutralW40};
  padding-bottom: 40px;

  @media ${device.mobile} {
    padding-bottom: 24px;
  }
`;

export const TextFieldSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 0px;
  }
`;

export const TextFieldWrapper = styled(TextField)`
  @media ${device.mobile} {
    width: 240px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 676px;
  min-height: 315px;
  padding-bottom: 160px;

  @media ${device.mobile} {
    max-width: 288px;
    padding-bottom: 72px;
  }
  @media ${device.tablet} {
    width: 90%;
  }
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 80px 104px;
  border-radius: 5px;
  border-top: 4px solid ${({ theme }) => theme.colors.mtr60};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.background.white};

  @media ${device.mobile} {
    padding: 48px 24px;
  }
`;

export const MessageTitle = styled.div`
  padding-bottom: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
    padding-bottom: 24px;
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutralW40};

  p {
    margin-bottom: 32px;
  }
`;

export const SigninButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SigninButton = styled(Button)`
  width: 100%;

  svg {
    width: 18px;
    height: 18px;
  }
`;
