import styled from 'styled-components';
import theme from 'src/styles/theme';
import { ComboboxOption } from '@dsny/dsny-component-library';
import { device } from 'src/styles/global';

interface Props {
  selectedItemIndex?: number;
  selectedItemBoolean?: boolean;
  disableComparison?: boolean;
  hasComparisonOn?: boolean;
}

export const ComboboxWrapper = styled.div<Props>`
  width: 300px;
  align-self: center;
  margin-top: ${({ hasComparisonOn }) => (hasComparisonOn ? '10px' : '0px')};

  @media ${device.mobile} {
    width: 100%;
    margin-top: 0px;
  }
`;

export const ComboboxStyles = {
  width: '100%',
};

export const ComboboxInputStyles = {
  color: theme.colors.neutralW40,
  height: '34px',
  backgroundColor: theme.colors.white,
};

export const ComboboxPopoverStyles = (showBannerWarning: boolean) => {
  return {
    borderRadius: '5px',
    marginTop: '8px',
    borderColor: theme.colors.white,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
    justifyContent: 'flex-end',
    height: showBannerWarning ? '360px' : '278px',
  };
};

export const ComboboxListStyles = {
  paddingTop: '16px',
  paddingBottom: '16px',
};

export const ComboboxOptionStyles = {
  paddingLeft: '16px',
  paddingRight: '16px',
};

export const ComboboxOptionWrapper = styled(ComboboxOption)<Props>`
  &:hover {
    background: ${theme.colors.neutralW100};
  }

  &:nth-child(${({ selectedItemIndex }) => selectedItemIndex}) {
    background: ${theme.colors.mtr100};
  }
`;

export const ItemWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW40};
  font-weight: ${({ selectedItemBoolean }) =>
    selectedItemBoolean ? '700' : '400'};

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ComparisonModeWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border-top: 1px solid ${theme.colors.neutralW80};
  cursor: pointer;

  &:hover {
    background: ${({ disableComparison }) =>
      disableComparison ? '' : theme.colors.neutralW100};
  }
`;

export const ComparisonTitle = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  color: ${({ disableComparison }) =>
    disableComparison ? theme.colors.neutralW80 : theme.colors.neutralW40};
`;

export const ComparisonDesc = styled.div<Props>`
  font-size: 10px;
  line-height: 13px;
  color: ${({ disableComparison }) =>
    disableComparison ? theme.colors.neutralW80 : theme.colors.neutralW40};
  margin-top: 8px;
`;

export const BannerWrapper = styled.div`
  margin-top: 16px;
`;

export const BannerStyles = {
  padding: '8px',
};

export const FilterInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const PillStyles = {
  fontSize: '10px',
  lineHeight: '13px',
  height: '18px',
  color: theme.colors.neutralW40,
  backgroundColor: theme.colors.neutralW90,
  fontWeight: '500',
};

export const DateRangeWrapper = styled.div`
  font-size: 12px;
  align-self: center;
`;

export const FilterChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.neutralW20};
  padding: 4px 8px 4px 10px;
  margin-top: 10px;
  width: 288px;
  gap: 8px;

  svg {
    cursor: pointer;
    width: 18px;
    height: 18px;
  }

  &:hover {
    border-radius: 100px;
    background: ${theme.colors.neutralW90};
  }
`;

export const TitleWrapper = styled.div`
  color: ${theme.colors.neutralW50};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`;
