import React from 'react';
import { NavContainer } from './NavLanguage.styles';

// TODO will be implemented when we have more language options
// type DropListItem = {
//   description: string;
//   code: string;
// };

const NavLanguage: React.FC = () => {
  // TODO will be implemented when we have more language options
  // const language = languages.find((lang) => lang.code === i18n.language);
  // const [option, setOption] = useState(
  //   language?.description ? language.description : 'English'
  // );

  // TODO will be implemented when we have more language options
  // const changeLanguage = (item: DropListItem) => {
  //   setOption(item.description);
  //   i18n.changeLanguage(item.code);
  // };

  return (
    <NavContainer>
      {/* TODO will be implemented when we have more language options */}
      {/* <LanguageMenu>
        <Dropdown
          width="184"
          headerAlign="right"
          onSelect={changeLanguage}
          listItems={languages}
          item={option}
          CustomIcon={LanguageIcon}
        />
      </LanguageMenu> */}
    </NavContainer>
  );
};

export default NavLanguage;
