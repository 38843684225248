import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import theme from 'src/styles/theme';
import { addSearchParams } from '../RadioStationModal.slice';
import { TextFieldWrapper } from './SearchFilter.styles';

const SearchFilter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { searchParams, clearAllFilters } = useSelector(
    (state: RootState) => state.radioStationModal
  );

  useEffect(() => {
    const textField = document.getElementById('search') as HTMLInputElement;
    if (textField) {
      textField.value = '';
    }
  }, [clearAllFilters]);

  // Handling search filter
  const search = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const tempSearch = searchParams;
    dispatch(
      addSearchParams({
        ...tempSearch,
        search: target.value,
        page: 1,
        hasNext: true,
      })
    );
  };

  return (
    <>
      {/*  Search Filter - should offer predictive searching. As the user types, the list should rearrange itself to present the matching results. */}
      <TextFieldWrapper
        id="search"
        name="search"
        setting="mtr"
        labelText=""
        placeholder={t('STATIONS_SEARCH_PLACEHOLDER')}
        width="480"
        onChange={search as () => void}
        wrapperStyles={{
          paddingRight: '0px',
          marginBottom: '0px',
        }}
        inputStyle={{
          height: '44px',
          borderRadius: '5px',
          color: theme.colors.neutralW40,
        }}
        tailingIcon="Search"
        tailingStyle={{
          paddingTop: '20px',
          paddingRight: '10px',
        }}
      />
    </>
  );
};

export default SearchFilter;
