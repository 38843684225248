import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { formatTitleAndArtistWithVersion } from 'src/utils/Formatter';
import { AddTrackObj } from '../MultiAsset.slice';
import MultiSongSelectionRelease from './MultiSongSelectionRelease';
import MultiSongSelectionDefault from './MultiSongSelectionDefault';

type MultiSongSelectionProps = {
  tracks: AddTrackObj[];
  handleTrackDelete: (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => void;
  handleSelect: (event: React.ChangeEvent<Element>, index: number) => void;
  handleSelectAll: (isSelected: boolean) => void;
  handleActiveSelection: (index: number) => void;
  isCasterReleaseSong: (track: AddTrackObj) => boolean;
  isRelease: boolean;
};

const MultiSongSelection: React.FC<MultiSongSelectionProps> = ({
  tracks,
  handleTrackDelete,
  handleSelect,
  handleSelectAll,
  handleActiveSelection,
  isCasterReleaseSong,
  isRelease,
}) => {
  const [showDeleteSongModal, setShowDeleteSongModal] = useState(false);
  const [batchCheckbox, setBatchCheckbox] = useState<string>();
  const [trackIndex, setTrackIndex] = useState<number>();
  const [deleteEvent, setDeleteEvent] =
    useState<React.MouseEvent<HTMLDivElement, MouseEvent>>();

  const { displayOnlyTracks } = useSelector(
    (state: RootState) => state.multiAsset.releaseTrack
  );

  // Handling tracks to batch
  const handleSelectAllCheckbox = () => {
    setBatchCheckbox(batchCheckbox ? '' : 'batchCheckValue');
    handleSelectAll(!!batchCheckbox);
  };

  useEffect(() => {
    if (tracks && tracks.length === 1) {
      setBatchCheckbox('');
    }
  }, [tracks?.length]);

  useEffect(() => {
    const trackListSize = tracks.length || 0;
    const selectedTrackListSize =
      tracks.filter((track) => track.isSelected).length || 0;
    if (trackListSize > 0 && trackListSize === selectedTrackListSize) {
      setBatchCheckbox('batchCheckValue');
    }
  }, []);

  const getToolTipText = (track: AddTrackObj): string => {
    if (isCasterReleaseSong(track)) {
      return formatTitleAndArtistWithVersion(track, true);
    }

    return track?.artist ? `${track?.artist} - ${track?.title}` : track?.title;
  };

  const handleDeleteButton = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    track: AddTrackObj
  ) => {
    if (isCasterReleaseSong(track)) {
      setDeleteEvent(event);
      setTrackIndex(index);
      setShowDeleteSongModal(true);
    } else {
      handleTrackDelete(event, index);
    }
  };

  if (isRelease) {
    return (
      // 2. Caster release
      <MultiSongSelectionRelease
        tracks={tracks}
        handleTrackDelete={handleTrackDelete}
        handleSelect={handleSelect}
        handleActiveSelection={handleActiveSelection}
        isCasterReleaseSong={isCasterReleaseSong}
        handleSelectAllCheckbox={handleSelectAllCheckbox}
        batchCheckbox={batchCheckbox}
        getToolTipText={getToolTipText}
        displayOnlyTracks={displayOnlyTracks}
        showDeleteSongModal={showDeleteSongModal}
        trackIndex={trackIndex}
        deleteEvent={deleteEvent}
        setShowDeleteSongModal={setShowDeleteSongModal}
        handleDeleteButton={handleDeleteButton}
      />
    );
  }

  return (
    // 1. Default flow
    <MultiSongSelectionDefault
      tracks={tracks}
      handleTrackDelete={handleTrackDelete}
      handleSelect={handleSelect}
      handleActiveSelection={handleActiveSelection}
      handleSelectAllCheckbox={handleSelectAllCheckbox}
      batchCheckbox={batchCheckbox}
    />
  );
};

export default MultiSongSelection;
