import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxPopover,
} from '@dsny/dsny-component-library';
import React, { useState } from 'react';
import theme from 'src/styles/theme';
import { useTranslation } from 'react-i18next';
import { countriesData } from 'src/utils/CountriesData';
import {
  ComboboxOptionWrapper,
  ComboboxStyles,
  ComboboxWrapper,
  InlineMessage,
  ItemWrapper,
} from './SignupSelection.styles';

export type CountryData = {
  index: number | undefined;
  id: string;
  description: string;
};

interface Props {
  selectCountry: (item: CountryData) => void;
  ref?: null;
  error: boolean;
}

const SignupSelectionCountry: React.FC<Props> = ({ selectCountry, error }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<CountryData>({
    id: '',
    description: '',
    index: undefined,
  } as CountryData);

  const openCombobox = () => {
    setOpen(true);
  };

  const closeCombobox = () => {
    setOpen(false);
  };

  // Handling country selection
  const onChange = (option: CountryData) => {
    setValue({ ...option });
    selectCountry({ ...option });
  };

  return (
    <>
      {/* Signup selection - Country */}
      <ComboboxWrapper>
        <Combobox
          isOpen={open}
          onClick={openCombobox}
          onClose={closeCombobox}
          onChange={onChange}
          label={t('SIGNUP_COUNTRY_LABEL')}
          style={{ ...ComboboxStyles }}
          value={value as CountryData}
        >
          <ComboboxInput
            required={error}
            placeholder={t('SIGNUP_SELECT_PLACEHOLDER')}
            highlightcolor={theme.colors.mtr60}
            style={{
              borderColor: error && theme.colors.error50,
              color: theme.colors.neutralW40,
              position: 'inherit',
              height: '44px',
            }}
          />

          <ComboboxPopover
            style={{
              width: '100%',
              height: '216px',
              borderRadius: '5px',
              marginTop: '8px',
              borderColor: theme.colors.white,
              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
            }}
          >
            <ComboboxList style={{ paddingTop: '16px', paddingBottom: '16px' }}>
              {countriesData?.map((c) => (
                <ComboboxOptionWrapper
                  key={c.id}
                  selectedItemIndex={value.index}
                  style={{ paddingLeft: '16px' }}
                  value={c}
                >
                  <ItemWrapper>{c.description}</ItemWrapper>
                </ComboboxOptionWrapper>
              ))}
            </ComboboxList>
          </ComboboxPopover>

          {/* If not selected apply inline message  */}
          {error && (
            <InlineMessage $inlineMessage>
              {t('SIGNUP_COUNTRY_ERROR_MESSAGE')}
            </InlineMessage>
          )}
        </Combobox>
      </ComboboxWrapper>
    </>
  );
};

export default SignupSelectionCountry;
