import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { selectPeriod } from '../Dashboard.slice';
import DropdownDateRange from '../DropdownDateRange/DropdownDateRange';
import { filterInitialCode } from '../DropdownFilter/DropdownFilter';

const TrackingCardDateRangeList: React.FC = () => {
  const [filter, setFilter] = useState(filterInitialCode);
  const [key, setKey] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const setFilterPeriod = (periodIndex: number) => {
    dispatch(selectPeriod(periodIndex));
    setFilter(periodIndex);
  };

  useEffect(() => {
    dispatch(selectPeriod(filter));
  }, [filter]);

  // Refresh dropdown when new song is selected
  useEffect(() => {
    setKey(key + 1);
  }, [selectedSong?.song_id]);

  return (
    // Dropdown date range list - Past 90 day fingerprint period
    <DropdownDateRange key={key} setFilter={setFilterPeriod} />
  );
};

export default TrackingCardDateRangeList;
